import { State } from 'app/store';

import { CrowdsaleState } from './reducer';

const getCrowdsale = (state: State) => state.crowdsale;

const getProp =
  <T extends keyof CrowdsaleState>(propKey: T) =>
  (state: State) =>
    state.crowdsale[propKey];

export const selectors = {
  getCrowdsale,
  getProp,
};
