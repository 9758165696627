export const POINTS_CARDS_DATA = [
  { id: 1, cost: 250, points: 12 },
  { id: 2, cost: 500, points: 30 },
  { id: 3, cost: 1000, points: 65 },
  { id: 4, cost: 2000, points: 140 },
  { id: 5, cost: 5000, points: 360 },
  { id: 6, cost: 10000, points: 750 },
  { id: 7, cost: 20000, points: 1600 },
  { id: 8, cost: 50000, points: 4000 },
];
