import { Box, Typography } from '@mui/material';
import { FontWeights, PageParagraph, Section, VerticalBarChart } from 'shared';

export const Schedule = () => {
  return (
    <Section>
      <PageParagraph pProps={{ sx: { gridColumn: { xs: 'span 12', md: '5 / 12' } } }}>
        The schedule of the periodic issue of coins with the corresponding percentage amounts:{' '}
      </PageParagraph>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          mt: 6,
        }}
      >
        <Box sx={{ gridColumn: { xs: 'span 12', md: '5 / 13' } }}>
          <VerticalBarChart
            xAxis={{
              data: ['Cliff Period', 'Q1', 'Q2', 'Q3', 'Q4'],
              label: (
                <>
                  <Typography variant="body2" fontWeight={FontWeights.Regular}>
                    CRAT Coin Release Period
                  </Typography>
                  <Typography variant="subtitle2" className="secondary" mt={1.5}>
                    CRAT Coin Release Schedule for Pre-Seed Participants
                  </Typography>
                </>
              ),
            }}
            yAxis={{
              label: 'CRAT Coin Released (25% / Quarter)',
            }}
            data={[0, 25, 50, 75, 100]}
          />
        </Box>
      </Box>

      <PageParagraph sx={{ mt: 6 }} pProps={{ variant: 'h6', sx: { gridColumn: { xs: 'span 12', md: '5 / 11' } } }}>
        In summary, both the Cliff Period and Vesting Period of CratD2C Decentralized Autonomous Smart Chain play
        integral roles in fostering a stable, sustainable, and robust ecosystem. These mechanisms ensure controlled coin
        release, discourage speculative trading, and encourage participants to engage actively in the project&#39;s
        long-term growth and success.
      </PageParagraph>
    </Section>
  );
};
