import { Box, Typography } from '@mui/material';
import {
  formatUIValue,
  Frequency,
  Included,
  initialOptions,
  OptionsEnum,
  poolsConfig,
  transformDataForUI,
  uiTitles,
} from 'features/calculator';
import { Br, COLOR_PRIMARY, COLOR_SECONDARY, PageParagraph, Section, Table, TableRow, TdProps } from 'shared';

const ComparisonTableHeadTd = ({ value }: TdProps<OptionsEnum>) => {
  return (
    <Typography variant="h6" className="semi-bold accent">
      {value}
    </Typography>
  );
};

const ComparisonTableRowTd = ({ title = '', value }: TdProps<number | Included | Frequency>) => {
  return (
    <Typography variant="h6" color={value === 'NO' ? COLOR_SECONDARY : COLOR_PRIMARY}>
      {formatUIValue(title, value, true)}
    </Typography>
  );
};

export const ComparisonTable = () => {
  return (
    <Section>
      <PageParagraph
        mb={6}
        pProps={{ className: 'inherit', sx: { gridColumn: { xs: 'span 12', sm: '1 / 11', md: '1 / 8', lg: '1 / 7' } } }}
      >
        Comparison Table of Staking Pools among Key Players <Br display={{ xs: 'none', lg: 'inline' }} /> in the CratD2C
        Ecosystem
      </PageParagraph>

      <Box
        sx={{
          overflowX: 'scroll',
        }}
      >
        <Table headProps={{ thData: initialOptions, TdSlot: ComparisonTableHeadTd }}>
          {transformDataForUI(Object.values(poolsConfig)).map(({ title, values }) => (
            <TableRow
              key={title}
              TdSlot={ComparisonTableRowTd}
              trTitle={uiTitles[title]}
              trData={values}
              sx={{ mt: 2 }}
            />
          ))}
        </Table>
      </Box>
    </Section>
  );
};
