import { Typography } from '@mui/material';

export const rows = [
  {
    title: 'Understanding IP-Portion Royalties',
    description: `IP-Portion royalties represent a dynamic and equitable distribution model for the earnings generated from using
        or licensing intellectual property developed on the CratD2C platform. Unlike traditional royalty models that
        often benefit a limited group of stakeholders, IP-Portion royalties are designed to reward all contributing
        parties, from developers to investors, ensuring that the value generated is shared among those who contributed
        to the IP's creation.`,
  },
  {
    title: 'Key Features of IP-Portion Royalties',
    subitems: [
      {
        subTitle: 'Equitable Distribution',
        subDescription:
          'Royalties are distributed based on the proportion of IP-Portions held by each contributor, ensuring a fair share of profits corresponding to their contribution level.',
      },
      {
        subTitle: 'Automated Payments',
        subDescription:
          'Utilizing smart contracts, royalty payments are automatically calculated and distributed, ensuring transparency, accuracy, and timeliness without the need for intermediaries.',
      },
      {
        subTitle: 'Flexibility and Fluidity',
        subDescription:
          'Contributors can choose to hold onto their IP-Portions to continue receiving royalties or sell them in the open market, providing liquidity and the opportunity to capitalize on their contributions.',
      },
      {
        subTitle: 'Sustainability and Growth',
        subDescription:
          'This model encourages ongoing contributions to the CratD2C ecosystem, as stakeholders are continuously rewarded for the utilization and success of their innovations.',
      },
    ],
  },
  {
    title: 'Advantages of IP-Portion Royalties',
    subitems: [
      {
        subTitle: 'Continuous Incentive',
        subDescription: `By receiving a share of the revenues generated from their creations, contributors are motivated to produce high-quality, innovative work, driving the ecosystem's growth.`,
      },
      {
        subTitle: 'Decentralized Ownership',
        subDescription:
          'The decentralized nature of IP-Portion royalties aligns with the ethos of blockchain, promoting a distributed and democratic approach to IP rights and benefits.',
      },
      {
        subTitle: 'Transparency and Trust',
        subDescription:
          'Using blockchain technology ensures that royalty distributions are transparent and immutable, building trust among contributors and users.',
      },
      {
        subTitle: 'Enhanced Collaboration',
        subDescription: `IP-Portion royalties' equitable and transparent nature fosters a collaborative environment where contributors are more willing to share ideas and resources, knowing they will be fairly compensated.`,
      },
    ],
  },
  {
    title: 'The Impact of IP-Portion Royalties',
    description: (
      <Typography variant="body2" className="secondary">
        The introduction of IP-Portion royalties by the CratD2C Decentralized Autonomous Smart Chain marks a significant
        departure from traditional IP royalty models. By ensuring fair compensation and fostering a collaborative and
        innovative environment, CratD2C is not just redefining the monetization of intellectual property in the
        blockchain space but also setting a new standard for how creators are rewarded in the digital age.
        <br />
        <br />
        As the platform evolves and attracts more contributions, the IP-Portion royalty system will likely influence
        broader discussions and implementations of IP rights and compensation in the digital economy, potentially
        inspiring other platforms and industries to adopt similar models.
      </Typography>
    ),
  },
];

export const bottomParagraph =
  'The CratD2C initiative demonstrates a commitment to leveraging blockchain technology not only for advancing decentralized applications but also for establishing a more equitable and sustainable ecosystem for intellectual property creators and contributors.';
