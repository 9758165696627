import { Grid, Typography } from '@mui/material';
import { Advantages, FontWeights } from 'shared';

import { vestingAdvantages } from '../constants';

export const VestingPeriod = () => {
  return (
    <>
      <Grid container rowSpacing={{ xs: 3, md: 0 }}>
        <Grid item xs={12} md={4}>
          <Typography variant="h4" className="accent">
            Vesting Period
          </Typography>
        </Grid>

        <Grid item xs={12} md={8} rowSpacing={4.75}>
          <Typography variant="h5" className="secondary" fontWeight={FontWeights.Regular}>
            The CratD2C Vesting Rounds are essential for ensuring a steady and controlled release of coins acquired
            during the Pre-Pre Seed and Pre-Seed Rounds. These acquired coins are subject to a scheduled release, with
            25% of the total acquired coins becoming accessible each quarter after the official Cliff Period expiration.
          </Typography>
        </Grid>
      </Grid>
      <Advantages
        component="div"
        mt={6}
        headTitle=""
        list={vestingAdvantages}
        listTitle={
          <Typography variant="h4" className="summary">
            The Vesting Period provides several advantages:
          </Typography>
        }
      />
    </>
  );
};
