export const benefitsValidator = [
  {
    title: 'Block rewards and transaction fees',
    description:
      "As a validator, you'll receive a share of the block rewards and transaction fees for every block you produce and validate. This can generate a steady income stream, making it financially rewarding to participate in the network as many blocks are created in seconds, which will create a long-lasting REGULAR INCOME for the Validator.",
  },
  {
    title: 'Reputation and influence',
    description:
      'Being elected as a validator demonstrates that the community trusts you to secure the network and act in its best interest. This can boost your reputation within the ecosystem and provide opportunities to influence network governance and development.',
  },
  {
    title: 'Network influence',
    description:
      "Validators often have a say in the network's governance and decision-making processes, allowing them to help shape the network's future according to their vision and values.",
  },
  {
    title: 'Contribution to network growth',
    description:
      "Validators contribute to the blockchain network's growth, security, and stability. A well-maintained and secure network is more likely to attract new users, developers, and investors, increasing the value of the native coin and benefiting all stakeholders.",
  },
  {
    title: 'Community engagement',
    description:
      'As a validator, you have the opportunity to engage with the community by providing updates, answering questions, and addressing concerns. This can help you build relationships with coin holders, developers, and other network participants, fostering a sense of camaraderie and shared purpose.',
  },
];

export const benefitsDelegator = [
  {
    title: 'Passive income',
    description:
      'Delegators can earn rewards for delegating their coins to validators. Validators share a portion of the block rewards and transaction fees they receive with their delegators, providing them with a passive income stream.',
  },
  {
    title: 'Lower barriers to entry',
    description:
      'Becoming a validator in a DPoS network often requires significant technical knowledge, infrastructure investment, and a substantial amount of staked coins. Delegating allows participants with smaller coin holdings and limited technical expertise to still participate in the network and earn rewards.',
  },
  {
    title: 'Network influence',
    description:
      "By delegating their coins to validators, delegators can influence the network's governance and validator selection. Validators with more delegated coins are more likely to be selected as active validators, and delegators can vote for validators who align with their vision for the network's future.",
  },
  {
    title: 'Security',
    description:
      'Delegators help secure the network by delegating their coins to be reliable and trustworthy validators. By doing so, they contribute to the decentralization and overall security of the network.',
  },
  {
    title: 'Flexibility',
    description:
      'Delegators can switch between validators or adjust their delegated stake as needed. This allows them to optimize their rewards and ensure they delegate to the most effective and trustworthy validators.',
  },
];

export const benefitsLiteBacker = [
  {
    title: 'Attractive Annual Returns',
    description:
      'LiteBackers are rewarded for their support with an Annual Percentage Rate (APR) of 17% on their staked coins, providing them with significant earnings as a coin of appreciation for their investment in the ecosystem.',
  },
  {
    title: 'Compounded Earnings through Biweekly Re-Staking',
    description:
      'The unique opportunity to re-stake their holdings biweekly enables LiteBackers to compound their rewards, enhancing their overall earnings over time and maximizing the return on their investment.',
  },

  {
    title: 'Exclusive Access to Bonus IP Portions',
    description:
      'LiteBackers receive exclusive Bonus CratD2C IP portions, which add significant value to their investment and entitle them to potential biannual royalty returns. This feature is an additional incentive for their active and continued involvement in the CratD2C ecosystem.',
  },
  {
    title: 'Support for Ecosystem Growth and Stability',
    description:
      'LiteBackers exhibit a deep-seated dedication to fostering the platform’s growth and ensuring its stability by staking their coins. This commitment is crucial for the development and success of the CratD2C ecosystem.',
  },
  {
    title: 'Incentivized Active Participation',
    description:
      'The structured rewards system, including both APR and bonus IP portions, encourages LiteBackers to remain actively engaged in the ecosystem. This engagement is vital for building a supportive and vibrant community within the CratD2C Decentralized Autonomous Smart Chain Ecosystem.',
  },
];

export const benefitsTurboBacker = [
  {
    title: 'Attractive Annual Returns',
    description:
      'TurboBackers are rewarded with a higher Annual Percentage Rate (APR) of 20% on their staked coins, acknowledging their increased stake and commitment to the ecosystem. This elevated APR offers significant financial returns, reflecting the value placed on their enhanced contribution.',
  },
  {
    title: 'Weekly Re-Staking for Compounded Earnings',
    description:
      'Unlike LiteBackers, who re-stake biweekly, TurboBackers have the privilege of re-staking their holdings weekly. This frequent re-staking capability allows them to compound their rewards more often, accelerating their earnings growth and maximizing their investment potential.',
  },
  {
    title: 'Exclusive Bonus IP Portions',
    description:
      'TurboBackers also receive Bonus CratD2C IP portions, similar to LiteBackers. These IP portions grant them eligibility for potential biannual royalty returns, rewarding their substantial contributions and long-term dedication to the CratD2C ecosystem.',
  },
  {
    title: 'Strengthening Network Decentralization and Security',
    description: `TurboBackers play a pivotal role in bolstering the ecosystem's decentralization and enhancing its security by holding a significant stake in the network. Their substantial investment helps to distribute control more broadly across the network, reducing vulnerabilities and increasing resilience.`,
  },
  {
    title: 'Recognition as Esteemed Contributors',
    description: `TurboBackers are acknowledged as elite members of the CratD2C community. Their willingness to invest a higher amount of CRAT Coins earns them a place of honour within the ecosystem, recognizing their vital role in supporting and shaping the platform's future.`,
  },
];
