import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userModel } from 'entities/user';
import { useShallowSelector, ZERO_ADDRESS } from 'shared';
import Web3 from 'web3';
import * as Yup from 'yup';

export interface UseBuyFormArgs {
  stableTokenBalance: string;
  maxValue: string;
}

export interface FormData {
  stablesAmount: string;
  fatherAddress: string;
}

const defaultValues: FormData = {
  stablesAmount: '',
  fatherAddress: '',
};

export const useBuyForm = ({ stableTokenBalance, maxValue }: UseBuyFormArgs) => {
  const { userAddress } = useShallowSelector(userModel.selectors.selectIsAuthenticated);

  const validationSchema = Yup.object().shape({
    stablesAmount: Yup.string()
      .test('Check balance', 'Insufficient balance', (val) => {
        return Number(val) <= Number(stableTokenBalance);
      })
      .test('Min value', 'Minimum number is 10', (val) => {
        return Number(val) >= 10;
      })
      .test('Max value', 'This number of coins not available for purchase', (val) => {
        return Number(val) <= Number(maxValue);
      })
      .required('Enter the number of stablecoins'),

    fatherAddress: Yup.string().test('Address', 'Invalid address', (val) => {
      if (val === '') return true;

      return (
        !!val &&
        val.toLocaleLowerCase() !== userAddress.toLocaleLowerCase() &&
        val !== ZERO_ADDRESS &&
        Web3.utils.isAddress(val)
      );
    }),
  });

  const form = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  return form;
};
