import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Bitcoin: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 36 36"
    sx={{
      width: '36px',
      height: '36px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M35.4613 22.3543C33.0573 31.9972 23.2906 37.8658 13.6465 35.4611C4.00641 33.0571 -1.86211 23.2897 0.54298 13.6476C2.94595 4.00354 12.7127 -1.86541 22.3538 0.538622C31.9972 2.94265 37.8654 12.7111 35.4611 22.3545L35.4612 22.3543H35.4613Z"
      fill="#F7931A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9385 15.436C26.2968 13.0406 24.4731 11.753 21.9793 10.894L22.7883 7.6492L20.8131 7.15703L20.0256 10.3164C19.5063 10.1869 18.973 10.0648 18.443 9.94387L19.2363 6.76366L17.2623 6.27148L16.4529 9.51522C16.0232 9.41738 15.6011 9.32069 15.1916 9.21881L15.1939 9.2086L12.4701 8.52841L11.9447 10.638C11.9447 10.638 13.4101 10.974 13.3792 10.9946C14.1791 11.1943 14.3237 11.7238 14.2997 12.1434L13.3782 15.84C13.4332 15.854 13.5047 15.8742 13.5835 15.9059L13.5261 15.8916C13.4774 15.8794 13.4267 15.8667 13.3747 15.8543L12.0831 21.0326C11.9853 21.2756 11.7372 21.6403 11.178 21.5018C11.1978 21.5305 9.74237 21.1436 9.74237 21.1436L8.76172 23.4046L11.3321 24.0453C11.6136 24.1159 11.8918 24.1884 12.1671 24.2602L12.1674 24.2603C12.3597 24.3104 12.5507 24.3602 12.7404 24.4087L11.923 27.6907L13.8959 28.1829L14.7054 24.9357C15.2443 25.082 15.7674 25.217 16.2795 25.3442L15.4727 28.5761L17.448 29.0682L18.2653 25.7924C21.6334 26.4298 24.166 26.1728 25.232 23.1264C26.091 20.6736 25.1893 19.2588 23.4173 18.3362C24.7079 18.0386 25.68 17.1897 25.9392 15.4363L25.9386 15.4358L25.9385 15.436ZM21.4256 21.7642C20.8656 24.0146 17.3432 23.0842 15.7316 22.6585L15.7315 22.6585C15.5866 22.6202 15.4571 22.586 15.3466 22.5586L16.4312 18.2105C16.5659 18.2441 16.7305 18.2811 16.9169 18.3229C18.5839 18.697 21.9994 19.4635 21.4257 21.7642H21.4256ZM17.2513 16.3049C18.5949 16.6635 21.526 17.4457 22.0365 15.4004H22.0367C22.5579 13.3086 19.7094 12.678 18.3182 12.37C18.1616 12.3353 18.0236 12.3047 17.9108 12.2766L16.9275 16.2201C17.0203 16.2432 17.1292 16.2723 17.2513 16.3049Z"
      fill="white"
    />
  </SvgIcon>
);
