import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { camelize, decamelize, logger } from 'shared';

// eslint-disable-next-line import/no-cycle

export const BASE_URL =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://cratd2csmartchain.io/api/v1'
    : 'https://crat3.rocknblock.io/api/v1';
// export const BASE_URL = 'https://cratd2csmartchain.io/api/v1';

interface AxiosInstanceTyped extends AxiosInstance {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-unused-vars
  <T = any>(config: AxiosRequestConfig<any>): Promise<AxiosResponse<T>>;
}

const client: AxiosInstanceTyped = axios.create({
  baseURL: `${BASE_URL}`,
  responseType: 'json' as const,
  validateStatus(status) {
    return status >= 200 && status < 300 && status !== 208;
  },
  headers: {
    'Content-Type': 'application/json',
  },
});
client.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token');
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }

    return {
      ...config,
      transformRequest: (request) => {
        if (request instanceof FormData) {
          return request;
        }
        return JSON.stringify(decamelize(request));
      },
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(decamelize(params)).toString();
        },
      },
      transformResponse: (response, headers) => {
        if (response) {
          if (headers['content-type']?.toLowerCase() === 'text/csv') {
            return response;
          }
          return camelize(JSON.parse(response));
        }
        return null;
      },
    };
  },
  (error) => {
    logger('error', error, 'error');
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    logger('error axios', error, 'error');

    if (error.response) {
      logger('Error response:', error.response, 'error');
    } else {
      logger('Error Message:', error.message, 'error');
    }
    return Promise.reject(error);
  },
);

export default client;
