import { CalculatorBlock } from 'widgets';

import { ComparisonTable, Intro, Mechanics } from './ui';

export const Staking = () => {
  return (
    <>
      <Intro />
      <Mechanics />
      <ComparisonTable />
      <CalculatorBlock
        bottomText="In summary, the staking mechanism of CratD2C Decentralized Autonomous Smart Chain offers a comprehensive and
          inclusive approach to network participation. It enables Validators, Delegators, and Backers to contribute to
          the platform's security, consensus, and governance, while earning rewards and potential royalty returns.
          This mechanism underlines CratD2C's commitment to creating an engaged, empowered, and sustainable
          ecosystem."
      />
    </>
  );
};
