import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Display: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 28 29"
    sx={{
      width: '28px',
      height: '29px',
      fill: 'none',
      color: '#B4EB56',
      ...sx,
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3325 21.6773H4.66764C2.60635 21.6773 0.935547 20.0065 0.935547 17.9452V5.08683C0.935547 3.02554 2.60635 1.35474 4.66764 1.35474H23.331C25.3938 1.35474 27.0646 3.02554 27.0646 5.08683V17.9438C27.0646 20.0065 25.3938 21.6773 23.3325 21.6773Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9039 21.6772L17.6297 27.4837"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0965 21.6772L10.3707 27.4837"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.39648 27.4839H19.6029"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9992 7.16109V5.70947"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9992 15.8708V17.3225"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4809 14.8344C11.8568 15.4498 12.4985 15.8766 13.2751 15.8766H14.0009H14.866C15.9925 15.8766 16.9041 14.9636 16.9041 13.8386C16.9041 12.9037 16.2683 12.0894 15.361 11.8615L12.6393 11.1792C11.7335 10.9498 11.0977 10.134 11.0977 9.1992C11.0977 8.0742 12.0107 7.16113 13.1357 7.16113H14.0009H14.7267C15.5004 7.16113 16.1406 7.585 16.518 8.19758"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
