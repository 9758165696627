import { FC, Fragment } from 'react';
import { Card, Divider, Typography } from '@mui/material';
import { CardProps } from '@mui/material/Card';
import { Stack } from '@mui/system';
import { useBreakpoints } from 'shared';

import { aboutData } from '../../constants';

type Props = {
  sx?: CardProps['sx'];
};

export const AboutCard: FC<Props> = ({ sx }) => {
  const { isDownMdLayout } = useBreakpoints();

  return (
    <Card
      sx={{
        px: { xs: 2, sm: 3, md: 1.25 },
        py: { xs: 2.5, sm: 3, md: 1.25 },
        mt: { xs: 3, md: 6.25 },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: 2, md: 0 },
        ...sx,
      }}
    >
      {aboutData.map(({ title, value }, index) => (
        <Fragment key={title}>
          <Stack alignItems="center" flexGrow={1} py={{ md: 1.25 }}>
            <Typography variant="h4" className="accent">
              {value}
            </Typography>
            <Typography variant="h6">{title}</Typography>
          </Stack>
          {index !== aboutData.length - 1 && (
            <Divider flexItem orientation={isDownMdLayout ? 'horizontal' : 'vertical'} />
          )}
        </Fragment>
      ))}
    </Card>
  );
};
