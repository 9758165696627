import { Advantages, COLOR_PRIMARY, Intro, PageParagraph } from 'shared';

import { intro, list, listTitle } from './constants';

export const IntellectualProperty = () => {
  return (
    <>
      <Intro
        mainTitle="Intellectual Property"
        paragraphSubtitle={intro.subtitle}
        paragraph={intro.paragraph}
        paragraphProps={{ lineHeight: { md: '36px !important' }, mt: 2.5 }}
        paragraphSubtitleProps={{ color: `${COLOR_PRIMARY} !important`, maxWidth: 620 }}
      />
      <Advantages list={list} listTitle={listTitle} headTitle="Property" listGap={4} />
      <PageParagraph
        sx={{ mb: { xs: 6, md: 11 } }}
        pProps={{
          variant: 'body1',
        }}
      >
        The overarching intellectual property rights have been thoroughly evaluated and are protected internationally
        across 181 countries, a testament to the collaborative efforts between INTEROCO and the Ministry of Economy of
        the United Arab Emirates. This global recognition validates the innovative essence of CratD2C&apos;s
        Decentralized Autonomous Smart Chain and establishes a solid foundation for its adoption and utilization across
        multiple jurisdictions.
        <br />
        <br />
        In essence, the protection and acknowledgement provided by INTEROCO, coupled with the Author&apos;s Design
        (literary and financial) by the Ministry of Economy of the United Arab Emirates, underscore the groundbreaking
        nature of CratD2C&apos;s contribution to blockchain technology. This comprehensive approach to intellectual
        property protection ensures that CratD2C&apos;s innovation remains secure, propelling it to the forefront of
        technological advancement and offering significant potential for economic and technological development
        worldwide.
      </PageParagraph>
    </>
  );
};
