import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Menu: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 18 14"
    sx={{
      width: '18px',
      height: '14px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path d="M1 1H17M1 7H17M1 13H17" stroke="#B4EB56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
