export const progress = [
  {
    title: 'IP-Portion Royalties',
    total: 100,
    filled: 30,
    label: '90,000,000',
    subtitle: 'Shares rewards with community members through IP royalties.',
  },
  {
    title: 'Validators, Delegators, Backers',
    total: 100,
    filled: 18,
    label: '54,000,000',
    subtitle: 'Rewards network participants for securing the blockchain.',
  },
  {
    title: 'Community Trust Vesting Grant (CTVG)',
    total: 100,
    filled: 10,
    label: '30,000,000',
    subtitle: 'Grants support to promising blockchain startups.',
  },
  {
    title: 'Team',
    total: 100,
    filled: 10,
    label: '30,000,000',
    subtitle: 'Ensures team alignment and commitment to long-term success.',
  },
  {
    title: 'Liquidity',
    total: 100,
    filled: 10,
    label: '30,000,000',
    subtitle: 'Ensures a liquid market for the native coin.',
  },
  {
    title: 'Marketing',
    total: 100,
    filled: 7.041,
    label: '21,123,000',
    subtitle: 'Drives awareness, adoption, and community growth.',
  },
  {
    title: 'Manufacturers/Merchants, Consumers, Partners. Commissions',
    total: 100,
    filled: 5,
    label: '15,000,000',
    subtitle: 'Encourages ecosystem engagement through commissions.',
  },
  {
    title: 'Network Operations',
    total: 100,
    filled: 3,
    label: '9,000,000',
    subtitle: 'Facilitate a flawless network infrastructure for the ecosystem.',
  },
  {
    title: 'Ecosystem Development',
    total: 100,
    filled: 3,
    label: '9,000,000',
    subtitle: 'Nurtures continuous growth and innovation.',
  },
  {
    title: 'Advisors',
    total: 100,
    filled: 2,
    label: '6,000,000',
    subtitle: 'Gathers expertise for informed decisions and guidance.',
  },
  {
    title: 'SWAP',
    total: 100,
    filled: 1.709,
    label: '5,127,000',
    subtitle: 'Facilitates seamless transition and upgrades.',
  },
  {
    title: 'AirDrop',
    total: 100,
    filled: 0.25,
    label: '750,000',
    subtitle: 'Rewards early supporters and community engagement.',
  },
];

export const tokenomicsInfo = [
  {
    title: 'CRAT',
    subtitle: 'Native Coin',
  },
  {
    title: '300,000,000',
    subtitle: 'Max supply',
  },
  {
    title: '0.5 - 3 sec.',
    subtitle: 'Latency',
  },
  {
    title: 'upto 100k/s',
    subtitle: 'TPS',
  },
];
