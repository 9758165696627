import { Typography } from '@mui/material';
import { COLOR_WH } from 'shared';

export const BOTTOM_TEXT =
  'In essence, the CratD2C Eco-Community represents a thriving and inclusive network of blockchain enthusiasts and stakeholders who are collectively driving the growth and adoption of the CratD2C Decentralized Autonomous Smart Chain. It embodies the principles of decentralization, collaboration, and innovation, creating a vibrant ecosystem that continues to evolve and expand.';

export const intro = {
  paragraph:
    'The CratD2C Eco-Community refers to a dynamic and interconnected ecosystem of individuals, organizations, developers, investors, and enthusiasts who actively participate in and contribute to the CratD2C Decentralized Autonomous Smart Chain (D.A.S.C). This community is the lifeblood of the CratD2C ecosystem, and it plays a pivotal role in its growth, development, and success.',
};

export const list = [
  {
    title: 'Diverse Participants',
    description:
      'The community comprises a diverse range of participants, including blockchain developers, Validators, Delegators, Backers, entrepreneurs, merchants, manufacturers, content creators, investors, and everyday users of the CratD2C blockchain.',
  },
  {
    title: 'Collaboration',
    description: `Collaboration is at the core of the CratD2C Eco-Community. Participants work together to improve the blockchain's infrastructure, develop decentralized applications (dApps), and explore innovative use cases across various industries.`,
  },
  {
    title: 'Innovation',
    description: `The community fosters an environment of innovation. Developers and entrepreneurs within the community constantly explore new ideas and concepts, pushing the boundaries of what's possible with blockchain technology.`,
  },
  {
    title: 'Education and Learning',
    description:
      'The CratD2C Eco-Community places a strong emphasis on education and learning. It provides resources, tutorials, and opportunities for members to enhance their understanding of blockchain technology and its applications.',
  },
  {
    title: 'Support and Governance',
    description:
      'The community actively participates in the governance of the CratD2C blockchain. This includes voting on proposals, protocol upgrades, and the selection of Validators.',
  },
  {
    title: 'Engagement and Rewards',
    description:
      'Members of the community are incentivized to engage actively with the CratD2C ecosystem. This includes participating in staking, referring others to the platform, and contributing to ecosystem growth. Engagement often leads to rewards in the form of CRAT Coins.',
  },
  {
    title: 'Events and Meetups',
    description:
      "The community maintains an open feedback loop with the CratD2C development team. Suggestions, improvements, and ideas from the community play a critical role in shaping the platform's future.",
  },
  {
    title: 'Global Reach',
    description:
      'The CratD2C Eco-Community is not confined to a specific geographic location. It has a global reach, with members from different countries and backgrounds, contributing to a rich tapestry of ideas and perspectives.',
  },
  {
    title: 'Economic Empowerment',
    description:
      "The CratD2C ecosystem aims to empower individuals economically. Through staking, participating in IP-Portion royalties, and engaging with the ecosystem's portals, community members have opportunities to earn rewards and build financial independence.",
  },
];

export const ListTitle = () => (
  <Typography variant="h4" className="secondary summary" color={`${COLOR_WH} !important`}>
    Key attributes and features of the CratD2C Eco-Community include:
  </Typography>
);
