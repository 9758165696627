import { Grid, Stack, Typography } from '@mui/material';
import { maxBy } from 'lodash';
import { Intro } from 'shared';

import { pressReleases } from './constants';
import { PressCard } from './ui';

export const PressReleases = () => {
  const maxPressImgHeight = maxBy(pressReleases, 'height')?.height || 50;

  return (
    <Stack mb={{ xs: 6, md: 17.5 }}>
      <Intro mainTitle="Press Releases" highlightedTitleWords={[0]} sx={{ mb: { xs: 5, md: 6.25 } }} />
      <Typography variant="h4" className="summary secondary">
        Yahoo Finance, Globe Newswire, Bloomberg University, Benzinga, Pennystocks, Goldstocks, StocksToBuyNow, Stock
        Pulse, fox47, Yahoo Tech, and an extensive list of 815 other global media outlets have all featured a special
        edition highlighting CratD2C&apos;s Innovative Potentials...
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={3.25} mt={{ xs: 4, md: 9 }}>
        {pressReleases.map((release) => (
          <Grid item key={release.name} xs={12} sm={6} md={4}>
            <PressCard {...release} maxHeight={maxPressImgHeight} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
