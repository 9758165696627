export const rows = [
  {
    title: 'Scientific Contribution',
    description:
      'The Decentralized Autonomous Smart Chain embodies groundbreaking research and development in blockchain technology, marking a significant leap forward in the field.',
  },
  {
    title: 'Technical Documentation',
    description:
      'Detailed annotations and definitions provide a foundational understanding of the Smart Chain, ensuring clarity and accessibility for stakeholders.',
  },
  {
    title: 'Comparative Analysis',
    description:
      "Rigorous evaluation of existing technologies underscores the unique value and advancement of CratD2C's innovation.",
  },
  {
    title: 'Strategic Relevance',
    description:
      "The certification highlights the broad implications of CratD2C's work, emphasizing its potential to transform technological applications and business models.",
  },
  {
    title: 'Legal Protection',
    description:
      "A robust legal framework has been established, securing CratD2C's intellectual property against infringement and unauthorized use, based on comprehensive legal documentation.",
  },
  {
    title: 'Branding and Domains',
    description:
      "The certification encompasses the protection of CratD2C's brand identity, including its name, logo, and associated digital domains, reinforcing its market presence and proprietary rights.",
  },
  {
    title: 'Application in Business Technologies',
    description:
      "CratD2C's Smart Chain is recognized for its adaptability and relevance to contemporary business infrastructures, offering versatile applications across various sectors.",
  },
];
