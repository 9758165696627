import { ReactNode } from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import { COLOR_BG, COLOR_SECONDARY } from 'shared';

const AMBASSADORIAL_SYSTEM_FILL_IN_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSf409CG9idP1qngxItZpZsoj40Ww0B36A6drL5vJ_0FTVBQsw/viewform';

export const intro = {
  title: 'Ambassadorial System',
  paragraph:
    "The Ambassadorial System of the CratD2C Decentralized Autonomous Smart Chain (DASC) serves as a foundational pillar for fostering global awareness, engagement, and adoption of CratD2C's innovative blockchain solutions.",
  subtitle:
    "This program is meticulously designed to recruit, educate, and empower diverse individuals passionate about blockchain technology and its transformative potential. Here's an introductory explanation of the CratD2C Ambassadorial System, highlighting its objectives, structure, and the pivotal role ambassadors play within the ecosystem.",
};

type RowItem = {
  title: string;
  description: string | string[];
};

export type AmbassadorialSectionProps = {
  id: string;
  title: string;
  subtitle?: string | ReactNode;
  withNumeration?: boolean;
  items?: RowItem[];
  subitemsList?: {
    subitemsTitle: string;
    subitemsSubtitle?: string;
    withNumeration?: boolean;
    subitems: RowItem[];
  }[];
  bottomCard?: ReactNode;
  bottomText?: string;
};

export const list: AmbassadorialSectionProps[] = [
  {
    id: 'objectives',
    title: 'Objectives',
    subtitle:
      'The primary goal of the CratD2C Ambassadorial System is to extend the reach and impact of the CratD2C ecosystem through strategic advocacy, education, and community engagement. Ambassadors act as the face of CratD2C in their respective regions and networks, driving initiatives that align with the following objectives:',
    items: [
      {
        title: 'Awareness',
        description:
          "Elevate the visibility of CratD2C and its offerings, showcasing the platform's unique advantages and applications across various industries",
      },
      {
        title: 'Education',
        description:
          'Spread knowledge about blockchain technology and the CratD2C ecosystem, demystifying complex concepts for a broad audience and encouraging informed participation.',
      },
      {
        title: 'Community Growth',
        description:
          'Cultivate a vibrant, global community of users, developers, and enthusiasts who are engaged with and contribute to the CratD2C platform.',
      },
      {
        title: 'Feedback and Innovation',
        description:
          'Serve as a conduit for feedback between the community and the CratD2C development team, fostering continuous improvement and innovation within the ecosystem.',
      },
    ],
  },
  {
    id: 'structure',
    title: 'Structure',
    subtitle:
      'The Ambassadorial System is structured to accommodate individuals from diverse backgrounds and skill sets, including but not limited to tech enthusiasts, educators, influencers, and developers. The program is segmented into various roles and responsibilities, tailored to leverage the unique strengths and interests of each ambassador. Key components include:',
    items: [
      {
        title: 'Recruitment and Selection',
        description:
          "Prospective ambassadors undergo a careful selection process to ensure they align with CratD2C's values and have the potential to contribute effectively to the ecosystem's growth.",
      },
      {
        title: 'Training and Development',
        description:
          'Ambassadors receive comprehensive training covering blockchain technology, the CratD2C platform, and effective community engagement strategies. This ensures they are well-equipped to represent CratD2C confidently and effectively.',
      },
      {
        title: 'Support and Resources',
        description:
          'CratD2C provides ambassadors with ongoing support and access to resources, including marketing materials, educational content, and direct communication channels. This enables them to execute their roles successfully and drive meaningful initiatives.',
      },
      {
        title: 'Recognition and Incentives',
        description:
          'Ambassadors are recognized for their contributions and achievements within the ecosystem. They receive incentives and rewards, reflecting the value they bring to the CratD2C community and their role in its expansion.',
      },
    ],
  },
  {
    id: 'role',
    title: 'Role',
    subtitle:
      "Ambassadors play a multi-faceted role within the CratD2C ecosystem, encompassing advocacy, community building, education, and feedback collection. They organize events, lead workshops, create content, and engage with both online and offline communities to foster a deeper understanding and appreciation of CratD2C's technology. Through their efforts, ambassadors not only contribute to the ecosystem's growth but also help shape its future direction based on community insights and needs.",
  },
  {
    id: 'education-and-training',
    title: 'Education and Training',
    subtitle:
      "The CratD2C Ambassadorial System is a comprehensive program designed to empower and educate individuals who are passionate about promoting the CratD2C Decentralized Autonomous Smart Chain (DASC) ecosystem. This system focuses on equipping ambassadors with the knowledge, skills, and resources they need to advocate for CratD2C's technologies and benefits effectively. Below are the detailed specifications for the Education and Training component of the CratD2C Ambassadorial System:Objective:The primary objective of the Education and Training program is to create a cadre of well-informed, skilled, and motivated ambassadors who can represent CratD2C across various platforms and stakeholders. These ambassadors will play a key role in expanding the reach of CratD2C, fostering community engagement, and driving the adoption of the CratD2C ecosystem.",
    subitemsList: [
      {
        subitemsTitle: 'Curriculum',
        withNumeration: true,
        subitems: [
          {
            title: 'Introduction to CratD2C \nand Blockchain Technology',
            description: [
              'Overview of the CratD2C ecosystem and its components.',
              'Fundamental principles of blockchain technology and its importance in modern digital economies.',
              'The role and vision of CratD2C within the blockchain space.',
            ],
          },
          {
            title: 'Deep Dive into CratD2C Features \nand Offerings',
            description: [
              "Detailed exploration of CratD2C's e-Market Portals, including the E-commerce Trading Portal, Real Estate Trading Portal, and Luxury Lifestyle Booking Portal.",
              'Understanding the universal payment gateway, including the Crypto Debit Card, Vending Machine, and POS Machine.',
              'Specifications and benefits of the CRAT coin and its use cases within the ecosystem.',
            ],
          },
          {
            title: 'Ambassadorial Skills Development',
            description: [
              'Effective communication and public speaking skills tailored to various audiences and platforms.',
              'Strategies for engaging with the community, including hosting events, workshops, and online forums.',
              'Social media and digital marketing techniques to promote CratD2C and its initiatives.',
            ],
          },
          {
            title: 'Blockchain and Cryptocurrency Market Dynamics',
            description: [
              'Analyse current trends, challenges, and opportunities within the blockchain and cryptocurrency markets.',
              'Insights into regulatory environments and their impact on blockchain technologies and cryptocurrencies.',
            ],
          },
          {
            title: 'Ethics and Best Practices',
            description: [
              'Ethical considerations in promoting blockchain technologies.',
              'Best practices for community engagement and representing CratD2C.',
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'training-modalities',
    title: 'Training Modalities',
    subtitle:
      'The Ambassadorial System is structured to accommodate individuals from diverse backgrounds and skill sets, including but not limited to tech enthusiasts, educators, influencers, and developers. The program is segmented into various roles and responsibilities, tailored to leverage the unique strengths and interests of each ambassador. Key components include:',
    items: [
      {
        title: 'Online Learning Platform',
        description:
          "Elevate the visibility of CratD2C and its offerings, showcasing the platform's unique advantages and applications across various induA comprehensive suite of online courses and materials accessible to ambassadors at any time, facilitating self-paced learning.stries",
      },
      {
        title: 'Webinars and Workshops',
        description:
          'Regularly scheduled live sessions with experts from the CratD2C team and the wider blockchain community, offering deep dives into specific topics and interactive Q&A sessions.',
      },
      {
        title: 'Peer-to-Peer Learning',
        description:
          'Opportunities for ambassadors to learn from each other through discussion forums, study groups, and collaborative projects.',
      },
    ],
  },
  {
    id: 'certification',
    title: 'Certification',
    subtitle:
      'Upon successful completion of the Education and Training program, ambassadors will receive a certification recognizing their knowledge and skills. This certification serves as a testament to their expertise and enhances their credibility as representatives of the CratD2C ecosystem.',
    items: [
      {
        title: 'Continuous Learning and Support',
        description: [
          'Ambassadors will have ongoing access to updated materials and resources to keep abreast of the latest blockchain and CratD2C ecosystem developments.',
          'Regular feedback sessions and support channels will be available to address any questions or challenges ambassadors may face in their roles.',
        ],
      },
    ],
  },
  {
    id: 'community-engagement',
    title: 'Community Engagement',
    subtitle:
      "Community engagement is a cornerstone of the CratD2C Decentralized Autonomous Smart Chain (DASC) ecosystem, embodying the spirit of collaboration, openness, and participation that blockchain technology champions. At CratD2C, we believe that fostering a vibrant, active, and inclusive community is essential for our ecosystem's growth, innovation, and success. Here's an overview of our approach to community engagement, highlighting key strategies and initiatives designed to cultivate a thriving community around the CratD2C ecosystem.",
    subitemsList: [
      {
        subitemsTitle: 'Core Principles of Community Engagement',
        withNumeration: true,
        subitems: [
          {
            title: 'Transparency',
            description:
              'We prioritize clear, open communication about developments, updates, and decisions within the CratD2C ecosystem to build trust and foster a sense of ownership among community members.',
          },
          {
            title: 'Inclusivity',
            description:
              'Our community is open to everyone, from blockchain enthusiasts and developers to investors and everyday users, ensuring a diverse range of perspectives and ideas.',
          },
          {
            title: 'Empowerment',
            description:
              'We equip our community with the tools, resources, and platforms they need to contribute to the ecosystem, encouraging innovation and participation at all levels.',
          },
        ],
      },
      {
        subitemsTitle: 'Engagement Strategies',
        subitems: [
          {
            title: 'Regular Updates and AMAs \n(Ask Me Anything)',
            description:
              'Through social media channels, blogs, and live sessions, we keep the community informed and engaged with the latest news, and developments, and provide a platform for questions and feedback.',
          },
          {
            title: 'Educational Resources \nand Workshops',
            description:
              'We offer a variety of educational materials and host workshops to help community members understand blockchain technology, the CratD2C ecosystem, and how to participate actively and safely.',
          },
          {
            title: 'Community-Driven Development',
            description:
              'Community input is vital to our development process. We encourage community members to propose new features, vote on proposals, and participate in beta testing of new products and services.',
          },
          {
            title: 'Reward and Recognition Programs',
            description:
              'To acknowledge and incentivize community contributions, we have implemented reward programs for activities such as content creation, bug reporting, and community support.',
          },
          {
            title: 'Events and Meetups',
            description:
              'Both virtual and physical events serve as opportunities for community members to connect, share ideas, and collaborate on projects. These gatherings range from informal meetups to conferences and hackathons.',
          },
        ],
      },
      {
        subitemsTitle: 'Initiatives for Engagement',
        subitems: [
          {
            title: 'Ambassador Program',
            description:
              'The CratD2C Ambassador Program recruits passionate members from the community to represent and promote the CratD2C ecosystem worldwide, offering them training, resources, and support.',
          },
          {
            title: 'Decentralized Governance',
            description:
              'Community members have a say in the governance of the CratD2C ecosystem through a decentralized voting mechanism, allowing them to influence decisions and the direction of development.',
          },
          {
            title: 'Feedback Loops',
            description:
              'Regular surveys and feedback sessions enable the community to voice their opinions and suggestions, which are crucial for continuous improvement and alignment with community needs.',
          },
        ],
      },
      {
        subitemsTitle: 'Building a Supportive Environment',
        subitems: [
          {
            title: 'Community Support Channels',
            description:
              'Dedicated channels on platforms like Discord, Telegram, and Reddit provide a space for community members to seek help, share knowledge, and connect with like-minded individuals.',
          },
          {
            title: 'Collaborations and Partnerships',
            description:
              'We actively seek collaborations with other projects, communities, and organizations to bring new opportunities, resources, and value to our community members.',
          },
          {
            title: 'Feedback Loops',
            description:
              'Regular surveys and feedback sessions enable the community to voice their opinions and suggestions, which are crucial for continuous improvement and alignment with community needs.',
          },
        ],
      },
    ],
  },
  {
    id: 'tiered-structure',
    title: 'Tiered Structure',
    withNumeration: true,
    items: [
      {
        title: 'Gold Tier (Top 10 Ambassadors)',
        description: [
          'Highest rewards and incentives due to exceptional performance.',
          'Additional perks include exclusive access to CratD2C events, early project updates, and one-on-one meetings with the core team.',
          'Monthly rewards distribution.',
        ],
      },
      {
        title: 'Silver Tier (Next 15 Ambassadors)',
        description: [
          'Substantial rewards and incentives for significant contributions.',
          'Access to webinars with key project stakeholders and recognition on official CratD2C platforms.',
          'Monthly rewards distribution.',
        ],
      },
      {
        title: 'Bronze Tier (Remaining 25 Ambassadors)',
        description: [
          'Substantial rewards and incentives for significant contributions.',
          'Access to webinars with key project stakeholders and recognition on official CratD2C platforms.',
          'Monthly rewards distribution.',
        ],
      },
    ],
  },
  {
    id: 'rewards-and-incentives-plan',
    title: 'Rewards and Incentives Plan',
    subtitle:
      "To effectively distribute rewards and incentives to 50 ambassadors, a structured approach that recognizes performance and contribution while maintaining fairness and motivation is essential. Here's a plan:",
    subitemsList: [
      {
        subitemsTitle: 'Rewards Distribution',
        withNumeration: true,
        subitems: [
          {
            title: 'Engagement and Activity',
            description: [
              'Ambassadors earn points based on their engagement activities like social media interactions, forum participation, and content creation.',
              'Points translate into rewards, ensuring a fair distribution.',
            ],
          },
          {
            title: 'Content Creation',
            description: [
              'High-quality content creation (articles, videos, tutorials) receives points.',
              "The evaluation is based on the content's impact, creativity, and relevance.",
            ],
          },
          {
            title: 'Referrals',
            description: [
              'Encouraging new participants to the CratD2C ecosystem earns ambassadors referral rewards.',
              'Successful referrals can lead to additional rewards.',
            ],
          },
          {
            title: 'Performance Benchmarks',
            description: [
              'Set performance benchmarks for each tier (Gold, Silver, Bronze).',
              'Ambassadors reaching or surpassing these benchmarks receive bonuses.',
            ],
          },
          {
            title: 'Community Impact',
            description: [
              'Community-building efforts like hosting events, AMAs, or meetups are rewarded.',
              "Ambassadors contributing to the ecosystem's growth receive recognition and incentives.",
            ],
          },
        ],
      },
      {
        subitemsTitle: 'Incentives',
        withNumeration: true,
        subitems: [
          {
            title: 'Native Coins',
            description: [
              'The primary incentive is distributed based on tier and performance.',
              'Rewards could range from a fixed amount of coins to a percentage of coins generated by the ecosystem.',
            ],
          },
          {
            title: 'Exclusive Access',
            description: [
              'Ambassadors gain access to exclusive events, webinars, and meetings with the core team and partners.',
            ],
          },
          {
            title: 'Merchandise',
            description: ['Branded CratD2C merchandise such as T-shirts, Face-Caps, Hoodies, and Accessories.'],
          },
          {
            title: 'Staking Opportunities',
            description: ['Top-performing ambassadors can stake CRAT coins as a Delegator of the Ecosystem.'],
          },
          {
            title: 'Certificates and Badges',
            description: [
              "Recognize ambassadors' achievements with official certificates, digital badges, and badges on the community platform.",
            ],
          },
          {
            title: 'Featured Content',
            description: [
              "Outstanding content creators might get their work featured on CratD2C's official platforms.",
            ],
          },
        ],
      },
    ],
    bottomCard: (
      <Card sx={{ p: 2 }}>
        <Typography variant="h5" className="md" maxWidth={543}>
          <Box component="span" color={COLOR_SECONDARY}>
            Total Ambassadorial Allocation:
          </Box>{' '}
          2.5% of 300M CratD2C Max Supply = 7.5M CRAT Coins
        </Typography>
        <Typography mt="21px">Total Number of CratD2C Ambassadors Globally at Early Stage (50 Ambassadors)</Typography>
      </Card>
    ),
  },
  {
    id: 'jumbo-bonuses',
    title: 'Jumbo Bonuses',
    withNumeration: true,
    items: [
      {
        title: 'Performance Jumbo',
        description: 'Ambassadors surpassing predefined benchmarks receive extra coins.',
      },
      {
        title: 'Content Creation Jumbo',
        description: 'Ambassadors who consistently create high-quality content get extra coins.',
      },
      {
        title: 'Referral Jumbo',
        description:
          'Ambassadors with higher successful referrals receive a bonus based on the extra number of referrals surpassing benchmarks (an additional 2-5% on each extra sale).',
      },
    ],
  },
  {
    id: 'recognition-and-leadership',
    title: 'Recognition and Leadership',
    subtitle: (
      <>
        The Ambassador Program of CratD2C&apos;s Decentralized Autonomous Smart Chain (DASC) plays a pivotal role in the
        ecosystem&apos;s growth, outreach, and community engagement. Recognizing the critical importance of these
        ambassadors, CratD2C has established a framework that highlights their contributions and positions them as key
        leaders within the blockchain community.
        <br />
        <br />
        Here&apos;s an overview of the recognition and leadership structure for CratD2C Ambassadors, underscoring the
        value they bring and the impact they make.
      </>
    ),
    subitemsList: [
      {
        subitemsTitle: 'Ambassador Recognition',
        subitemsSubtitle:
          'CratD2C places immense value on the efforts and contributions of its ambassadors, recognizing their work in various ways:',
        subitems: [
          {
            title: 'Recognition Programs',
            description:
              'Ambassadors are celebrated through various recognition programs that highlight their achievements, contributions, and impact. These programs include awards, public acknowledgments, and features in CratD2C communications.',
          },
          {
            title: 'Performance-Based Incentives',
            description:
              'Beyond verbal recognition, ambassadors receive tangible rewards based on their performance and contributions. These incentives include CRAT coins, access to exclusive events, and opportunities for personal and professional development.',
          },
          {
            title: 'Professional Development Opportunities',
            description:
              'Recognizing the potential and dedication of its ambassadors, CratD2C offers them opportunities for growth and learning. This includes access to advanced training, workshops, and networking events with industry leaders.',
          },
        ],
      },
    ],
  },
  {
    id: 'ambassador-leadership',
    title: 'Ambassador Leadership',
    subtitle:
      'Ambassadors are not just representatives of the CratD2C ecosystem; they are leaders who guide, inspire, and influence both within the community and the broader blockchain industry.',
    items: [
      {
        title: 'Community Leadership',
        description:
          'Ambassadors take on leadership roles within the community, organizing events, leading discussions, and providing guidance to new members. They act as the bridge between CratD2C and its community, ensuring a vibrant and engaged ecosystem.',
      },
      {
        title: 'Influence in Development',
        description:
          'Ambassadors have a voice in the development and direction of the CratD2C ecosystem. Through regular feedback sessions and direct communication channels with the CratD2C development team, they can influence product development, feature enhancements, and strategic decisions.',
      },
      {
        title: 'Advocacy and Outreach',
        description:
          "As leaders, ambassadors advocate for CratD2C's vision and technology across various platforms and forums. They play a crucial role in outreach initiatives, presenting at conferences, participating in panel discussions, and creating content that educates and promotes the CratD2C ecosystem.",
      },
    ],
    subitemsList: [
      {
        subitemsTitle: 'Empowering Ambassadors as Leaders',
        subitemsSubtitle: 'CratD2C empowers its ambassadors to be effective leaders through:',
        subitems: [
          {
            title: 'Comprehensive Training',
            description:
              'Ambassadors receive in-depth training on blockchain technology, the CratD2C ecosystem, and leadership skills. This prepares them to confidently represent CratD2C and lead community initiatives.',
          },
          {
            title: 'Support and Resources',
            description:
              "CratD2C provides its ambassadors with the necessary tools, resources, and support to successfully fulfill their roles. This includes marketing materials, educational content, and direct access to CratD2C's team for assistance and guidance.",
          },
          {
            title: 'Collaborative Environment',
            description:
              "Encouraging a culture of collaboration, CratD2C fosters an environment where ambassadors can work together on projects, share best practices, and support each other's initiatives.",
          },
        ],
      },
    ],
  },
  {
    id: 'events-and-meet-ups',
    title: 'Events and Meet-Ups',
    subtitle: (
      <>
        The Events and Meet-Ups organized by the Ambassadors of CratD2C&apos;s Decentralized Autonomous Smart Chain
        (DASC) play a vital role in fostering community engagement, education, and the spread of blockchain technology.
        These gatherings are instrumental in building a strong, well-informed community about the CratD2C ecosystem and
        its innovations.
        <br />
        <br />
        Here’s an overview of these events and meet-ups&apos; significance, variety, and impact.
      </>
    ),
    subitemsList: [
      {
        subitemsTitle: 'Significance of Events and Meet-Ups',
        subitems: [
          {
            title: 'Community Building',
            description:
              'Events and meet-ups are the cornerstone for creating a vibrant community around the CratD2C ecosystem. They provide a platform for enthusiasts, developers, investors, and newcomers to connect, share ideas, and foster meaningful relationships.',
          },
          {
            title: 'Education and Awareness',
            description:
              "Through workshops, presentations, and discussions, ambassadors educate participants about blockchain technology, the specifics of the CratD2C ecosystem, and the potential impact on various industries. This knowledge dissemination is crucial for increasing the adoption and understanding of blockchain's.",
          },
          {
            title: 'Feedback and Collaboration',
            description:
              'These gatherings offer a unique opportunity for the CratD2C team to receive direct feedback from the community. They facilitate a two-way conversation where suggestions, concerns, and ideas can be exchanged, driving improvements and innovations within the ecosystem.',
          },
        ],
      },
      {
        subitemsTitle: 'Types of Events and Meet-Ups',
        subitems: [
          {
            title: 'Workshops and Training Sessions',
            description:
              'Focused on providing hands-on experience and learning, these sessions cover a range of topics, from the basics of blockchain to the technical details of the CratD2C platform. They are designed to empower participants with the knowledge to actively participate in the DASC ecosystem.',
          },
          {
            title: 'Networking Events',
            description:
              'Bringing together a diverse group of individuals with a shared interest in blockchain, these events are perfect for forging new connections, exploring potential collaborations, and discussing the latest trends and developments in the industry.',
          },
          {
            title: 'Community Discussions and AMAs',
            description:
              'Open forums and Ask Me Anything (AMA) sessions with CratD2C ambassadors and team members encourage transparency and open dialogue. These discussions are invaluable for addressing community queries, concerns, and suggestions.',
          },
          {
            title: 'Hackathons and Innovation Challenges',
            description:
              'Aimed at developers and tech enthusiasts, these competitive events challenge participants to create innovative solutions using the CratD2C platform. They stimulate creativity, technical skill development, and practical applications of blockchain technology.',
          },
        ],
      },
      {
        subitemsTitle: 'Impact of Ambassador-Led Events',
        subitems: [
          {
            title: 'Enhanced Visibility',
            description:
              'Ambassador-led events significantly contribute to raising awareness and visibility of the CratD2C ecosystem within the global blockchain community and beyond. They highlight the platform’s features, benefits, and potential applications to a broader audience.',
          },
          {
            title: 'Strengthened Ecosystem',
            description:
              'By engaging with the community directly, ambassadors play a critical role in strengthening the ecosystem. These events ensure that the community remains vibrant, informed, and actively involved in the development and expansion of CratD2C.',
          },
          {
            title: 'Empowered Users',
            description:
              'Participants leave these events not just as passive observers but as empowered users who are knowledgeable about CratD2C and blockchain technology. They become advocates for the ecosystem, further driving its growth and adoption.',
          },
        ],
      },
    ],
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    bottomCard: (
      <Card sx={{ p: 4 }}>
        <Typography variant="h5" className="md">
          Interested To Be One Among The 50 Global CratD2C Ambassadors?
          <br />
          Fill Out This Form Below:
        </Typography>
        <Button
          href={AMBASSADORIAL_SYSTEM_FILL_IN_LINK}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ px: 2, mt: 3, width: 258 }}
        >
          <Typography variant="button" className="sm medium" color={COLOR_BG}>
            Fill in the questionnaire
          </Typography>
        </Button>
      </Card>
    ),
    bottomText:
      "The Ambassadorial System of the CratD2C Decentralized Autonomous Smart Chain is a critical initiative designed to promote the widespread adoption and understanding of blockchain technology. By empowering a global network of ambassadors, CratD2C aims to build a strong, knowledgeable, and engaged community that supports the platform's vision of offering decentralized solutions that address real-world challenges. Through collaboration, education, and innovation, the Ambassadorial System seeks to pave the way for a more connected and blockchain-empowered future.",
  },
];
