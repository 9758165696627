import { useLocation } from 'react-router-dom';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { appRoutes, BORDER, camelize, COLOR_GRAY_7, COLOR_PRIMARY } from 'shared';
import { BottomNavigation, extractRootPath } from 'shared/ui';
import { certificates, socials } from 'widgets/layout/constants';

import { Community, NavLinks } from './ui';

export const Footer = () => {
  const { pathname } = useLocation();

  const isConnectPath = appRoutes[camelize(extractRootPath(pathname))]?.dirName === 'connect';

  return (
    <Container>
      {appRoutes[camelize(extractRootPath(pathname))]?.children && !isConnectPath && (
        <BottomNavigation pathname={pathname} withDivider sx={{ mb: { xs: 6, md: 12 } }} />
      )}
      <Community />
      <Divider flexItem sx={{ mx: { xs: -2.5, lg: 'calc((1300px - 100vw) / 2 )' } }} />
      <NavLinks />
      <Stack
        mt={1}
        component="footer"
        sx={{
          borderTop: BORDER,
          borderColor: COLOR_GRAY_7,
          pt: 3.5,
          pb: 5,
        }}
      >
        <Stack direction={{ md: 'row' }} alignItems={{ sm: 'center' }} useFlexGap gap={{ xs: 3, md: 4 }}>
          <Typography variant="body2">Copyright © 2024 CratD2C</Typography>
          <Stack direction="row" gap={{ xs: 4, md: 3 }} ml={{ md: 'auto' }}>
            {certificates.map(({ title, img, url }) => (
              <a key={title} href={url} rel="noreferrer" target="_blank">
                <Box component="img" src={img} alt={title} sx={{ objectFit: 'contain', width: '100px' }} />
              </a>
            ))}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={{ xs: 3.85, md: 2, lg: 3.85 }}>
            {socials.map(({ Icon, url }) => {
              return (
                <Box component="a" key={url} rel="noreferrer" href={url} target="_blank">
                  <Icon
                    sx={{
                      color: COLOR_PRIMARY,
                    }}
                  />
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};
