import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Eos: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 36 36"
    sx={{
      width: '36px',
      height: '36px',
      ...sx,
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 36C8.1 36 0 27.9002 0 17.9104C0.09 8.01058 8.1 -0.0892553 18.09 0.000742806C27.99 0.090741 36 8.10058 36 18.1804C35.91 27.9902 27.9 36 18 36"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2504 13.728L17.8664 6L23.4344 13.68L25.8344 25.152L17.8664 30L9.89844 25.152L12.2504 13.728ZM12.9705 15.9357L11.1945 24.6237L17.0025 28.1757L12.9705 15.9357ZM13.5465 13.9682L17.8665 8.01621L22.1865 13.9682L17.8665 27.0242L13.5465 13.9682ZM22.7142 15.9357L18.6822 28.1757L24.4902 24.6237L22.7142 15.9357Z"
      fill="white"
    />
  </SvgIcon>
);
