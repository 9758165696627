import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Breadcrumbs as MuiBreadcrumbs, IconButton, Typography } from '@mui/material';
import { appRoutes, BORDER_MAIN, BORDER_RADIUS_S, COLOR_SECONDARY, COLOR_WH } from 'shared/config';
import { Icons } from 'shared/ui';

import { buildNavigationMap, splitPathnameRegExp } from '../navigation.helper';

const concatPathname = (arr: string[], index: number) => arr.slice(0, index).join('');

export const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const homePage = pathname === '/';

  const pathChunks = pathname.match(splitPathnameRegExp);

  // avoid the "Connect" link on breadcrumbs
  const filteredPathChunks = pathChunks?.filter((chunk) => chunk !== appRoutes.connect.root.path);
  const isConnectPath = (filteredPathChunks?.length || 0) < (pathChunks?.length || 0);

  const navigationMap = useMemo(() => buildNavigationMap(appRoutes), []);

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" sx={{ display: homePage ? 'none' : 'block', ol: { rowGap: 1.5 } }}>
      <IconButton component={Link} to="/" className="transparent">
        <Icons.House />
      </IconButton>

      {filteredPathChunks?.map((chunk, index) => {
        const last = index === filteredPathChunks.length - 1;
        const opts = navigationMap?.[concatPathname(pathChunks || [], index + (isConnectPath ? 2 : 1))];

        return (
          <Box
            component={Link}
            to={opts?.path}
            key={`${chunk}`}
            sx={{
              display: 'inline-block',
              py: 0.25,
              px: 1,
              border: last ? BORDER_MAIN : 'none',
              borderRadius: BORDER_RADIUS_S,
            }}
          >
            <Typography variant="body2" color={last ? COLOR_WH : COLOR_SECONDARY}>
              {opts?.name}
            </Typography>
          </Box>
        );
      })}
    </MuiBreadcrumbs>
  );
};
