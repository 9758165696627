import { COLOR_PRIMARY, Intro, PageParagraph, SplittedRows } from 'shared';

import { intro, list } from './constants';

export const IpPortionsIssuance = () => {
  return (
    <>
      <Intro
        mainTitle="IP-Portions Issuance"
        highlightedTitleWords={[0]}
        subTitle={intro.subtitle}
        subTitleProps={{ className: 'secondary sm', maxWidth: 752, mt: { xs: 1.25, md: 3 } }}
        paragraphSubtitle={intro.paragraphSubtitle}
        paragraphSubtitleProps={{ mt: { xs: 2, sm: 6 }, sx: { gridColumn: { xs: 'span 12', md: '7 / 13' } } }}
      />
      <SplittedRows data={list} titleColor={COLOR_PRIMARY} rowTitleVariant="h4" />
      <PageParagraph pProps={{ mb: { xs: 6, md: 12 } }}>{intro.bottomParagraph}</PageParagraph>
    </>
  );
};
