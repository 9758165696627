import { useMemo, useState } from 'react';
import { Stack, Switch, SxProps, TextField, Typography } from '@mui/material';
import { BORDER_RADIUS_XL, useValidateInputField, ValidationTypes } from 'shared';

import { calculateAPY, increaseValueByPercent } from '../lib';
import { CALCULATOR_BORDER, initialOptions, OptionsEnum, poolsConfig } from '../model';

import { Details } from './details';
import { Earnings } from './earnings';
import { Options } from './options';

export interface CalculatorProps {
  activeOption?: OptionsEnum;
  sx?: SxProps;
}

export const Calculator = ({ activeOption, sx }: CalculatorProps) => {
  const [selectedOption, setSelectedOption] = useState(activeOption || OptionsEnum.Validator);
  const [isAutoCompound, setIsAutoCompound] = useState(false);
  const [value, handleChangeValue] = useValidateInputField({
    initialState: '',
    type: ValidationTypes.number,
    minValue: 0,
    decimals: 2,
  });

  const poolConfig = useMemo(() => poolsConfig[selectedOption], [selectedOption]);

  const handleSelectOption = (selected: OptionsEnum) => {
    setSelectedOption(selected);
  };

  return (
    <Stack
      spacing={2}
      sx={{ width: '100%', padding: 3, border: CALCULATOR_BORDER, borderRadius: BORDER_RADIUS_XL, ...sx }}
    >
      {!activeOption && (
        <Options onCurrencyClick={handleSelectOption} activeOption={selectedOption} optionsList={initialOptions} />
      )}
      <TextField
        value={value}
        onChange={handleChangeValue}
        placeholder="0.00"
        label={
          <Typography variant="body2" className="secondary">
            Enter Staking Amount
          </Typography>
        }
        InputProps={{
          inputProps: {
            style: {
              minHeight: 36,
            },
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Stack direction="row" spacing={0.75}>
        <Switch checked={isAutoCompound} onChange={() => setIsAutoCompound((prev) => !prev)} />
        <Typography variant="body2">Auto Compound</Typography>
      </Stack>
      <Details data={poolConfig} autoCompoundEnabled={isAutoCompound} activeOption={selectedOption} />
      <Earnings
        value={increaseValueByPercent(
          value,
          isAutoCompound ? calculateAPY(poolConfig.apr, poolConfig.frequency) : poolConfig.apr,
        )}
      />
    </Stack>
  );
};
