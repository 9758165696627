import { Advantages, Intro, PageParagraph } from 'shared';

import { intro, list } from './constants';
import { MechanismTable } from './ui';

export const BiennialLayer = () => {
  return (
    <>
      <Intro
        mainTitle={intro.mainTitle}
        highlightedTitleWords={[0, 1]}
        paragraph={intro.paragraph}
        paragraphSubtitle={intro.subtitle}
        paragraphSubtitleProps={{ mt: { xs: 0, md: 1 } }}
        sx={{ h1: { maxWidth: { sm: 720, md: 950 } } }}
      />
      <Advantages headTitle="Advantages" list={list} />
      <MechanismTable />
      <PageParagraph
        sx={{ mb: { xs: 6, md: 12 } }}
        pProps={{ variant: 'body1', sx: { gridColumn: { xs: 'span 12', md: '7 / 13' } } }}
      >
        The CratD2C 8-Layer Zig-Zag Supply Mechanism&#39;s innovation lies in its ability to address the coin
        distribution aspect and the overarching goals of sustainability, community involvement, and ecosystem
        resilience. By combining the best practices of blockchain economics with a tailored approach to participant
        engagement, CratD2C sets a new benchmark for blockchain projects. This mechanism paves the way for a dynamic,
        stable, and vibrant ecosystem that stands at the forefront of innovation and excellence in the blockchain
        industry.
      </PageParagraph>
    </>
  );
};
