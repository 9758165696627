import { Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import {
  Advantages,
  BORDER_RADIUS_L,
  COLOR_ACCENT,
  COLOR_SECONDARY,
  COLOR_STROKE,
  FontWeights,
  formatNumber,
  Intro,
  Section,
} from 'shared';

import { bugBountyRewardsList, howToParticipate, intro, rulesAndGuidelines, scope } from './constants';

export const BugBounty = () => {
  return (
    <>
      <Intro
        mainTitle="Bug Bounty"
        subTitle="CratD2C Bug Bounty Program"
        paragraph={intro.paragraph}
        paragraphProps={{ letterSpacing: '-0.4px !important' }}
        sx={{ h1: { maxWidth: 1000 } }}
      />

      <Section>
        <Grid container rowSpacing={{ xs: 3, md: 0 }}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" className="secondary">
              Program Overview:
            </Typography>
          </Grid>

          <Grid item container xs={12} md={8}>
            <Typography variant="h5" sx={{ fontWeight: FontWeights.Regular, letterSpacing: 'unset' }}>
              Our Bug Bounty Program invites security researchers, developers, and enthusiasts from around the world to
              actively participate in improving the security of the CratD2C Decentralized Autonomous Smart Chain. By
              reporting security issues and vulnerabilities, participants can earn rewards and contribute to a safer and
              more secure ecosystem.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Advantages
        headTitle={
          <Typography variant="h4" className="secondary" maxWidth={330}>
            Scope of the Bug Bounty Program
          </Typography>
        }
        listProps={{ rowSpacing: 0 }}
        listTitle={
          <Typography variant="body1" sx={{ fontWeight: FontWeights.SemiBold, marginBottom: 4 }}>
            The program covers all aspects of the CratD2C ecosystem, including but not limited to:
          </Typography>
        }
        list={scope}
      />

      <Section>
        <Grid container rowSpacing={{ xs: 3, md: 0 }}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" className="secondary">
              Rewards:
            </Typography>
          </Grid>

          <Grid item container xs={12} md={8}>
            <Typography variant="h5" sx={{ fontWeight: FontWeights.Regular, marginBottom: 7 }}>
              The CratD2C Bug Bounty Program offers competitive rewards based on the severity and impact of the reported
              vulnerabilities. Rewards are typically distributed as follows:
            </Typography>

            <Stack
              spacing={1}
              sx={{
                border: `1px solid ${COLOR_STROKE}`,
                borderRadius: BORDER_RADIUS_L,
                width: '100%',
                padding: 2,
                marginBottom: 4,
              }}
            >
              {bugBountyRewardsList.map((bugBounty, bugBountyIndex) => (
                <Stack
                  key={bugBounty.title}
                  spacing={1}
                  direction={{ xs: 'column', sm: 'row' }}
                  sx={{
                    paddingY: 1.5,
                    alignItems: { xs: 'start', sm: 'center' },
                    justifyContent: 'space-between',
                    borderBottom:
                      bugBountyIndex !== bugBountyRewardsList.length - 1 ? `1px dashed ${COLOR_STROKE}` : '',
                  }}
                >
                  <Typography variant="h6" sx={{ color: COLOR_ACCENT }}>
                    {bugBounty.title}
                  </Typography>
                  <Typography variant="h6" sx={{ color: COLOR_SECONDARY }}>
                    Up to{' '}
                    <Box component="span" sx={{ color: '#DCD4E2' }}>
                      ${formatNumber(bugBounty.reward)}
                    </Box>{' '}
                    in CRAT Coins
                  </Typography>
                </Stack>
              ))}
            </Stack>

            <Typography sx={{ maxWidth: 735 }}>
              Please note that reward amounts are subject to change based on the severity and uniqueness of the reported
              issues.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container rowSpacing={{ xs: 3, md: 0 }}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" className="secondary">
              Rules and Guidelines:
            </Typography>
          </Grid>

          <Grid item container xs={12} md={8}>
            <Stack component="ul" spacing={3}>
              {rulesAndGuidelines.map((guideline) => (
                <Stack key={guideline} component="li" direction="row" spacing={2} sx={{ alignItems: 'flex-start' }}>
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      aspectRatio: '1 / 1',
                      background: COLOR_ACCENT,
                      borderRadius: '50%',
                      marginTop: 0.75,
                    }}
                  />
                  <Typography variant="body2" sx={{ color: COLOR_SECONDARY, fontWeight: FontWeights.Regular }}>
                    {guideline}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container rowSpacing={{ xs: 3, md: 0 }}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" className="secondary">
              How to Participate
            </Typography>
          </Grid>

          <Grid item container xs={12} md={8}>
            <Grid item xs={12}>
              <Stack gap={5}>
                {howToParticipate.map((item, index) => (
                  <Grid key={item} container sx={{ borderTop: `1px solid ${COLOR_STROKE}`, paddingTop: 2 }}>
                    <Grid item xs={1.5}>
                      <Typography variant="h6" sx={{ fontWeight: FontWeights.SemiBold }}>
                        {`${index + 1}`.padStart(2, '0')}
                      </Typography>
                    </Grid>
                    <Grid item xs={10.5}>
                      <Typography variant="body2" className="secondary">
                        {item}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            mt: { xs: 2, sm: 3.5 },
          }}
        >
          <Typography
            sx={{
              gridColumn: { xs: 'span 12', md: '5 / 13' },
            }}
          >
            Join us in making the CratD2C ecosystem more secure by participating in our Bug Bounty Program. Together, we
            can strengthen our platform and protect the interests of our community. Your contributions are greatly
            appreciated.
          </Typography>
        </Box>
      </Section>
    </>
  );
};
