import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'app/store';

import { IModalPayload, ModalTransition } from './types';

export type ModalState = {
  modal: IModalPayload | null;
  transition?: ModalTransition | null;
};

export const initialState: ModalState = {
  modal: null,
  transition: null,
};

export const modalSelector = (state: State): ModalState => state.modal;

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state: ModalState, action: PayloadAction<ModalState['modal'] & Pick<ModalState, 'transition'>>) {
      state.modal = action.payload;
      state.transition = action.payload.transition;
    },

    closeModal(state: ModalState) {
      state.modal = null;
    },

    updateModal(state: ModalState, action: PayloadAction<Partial<IModalPayload>>) {
      state.modal = { ...state.modal, ...action.payload } as any;
    },
  },
});

export const { reducer } = modalSlice;
export const { actions } = modalSlice;
