import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { TextExpander } from 'shared';

type AboutTextProps = {
  text: string;
  maxLength: number;
};

export const AboutText = ({ text, maxLength }: AboutTextProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleTextExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <Typography variant="body2" whiteSpace="pre-line">
        <TextExpander isExpanded={isExpanded} maxLength={maxLength} text={text} />
      </Typography>
      <Button
        onClick={toggleTextExpand}
        variant="text"
        className="secondary"
        size="medium"
        sx={{
          width: 'fit-content',
          mt: 2,
          mb: 2,
          '& > *': {
            textDecoration: 'underline',
          },
        }}
      >
        <span>{isExpanded ? 'Roll up' : 'Read more'}</span>
      </Button>
    </>
  );
};
