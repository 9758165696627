import { Features, Intro } from './ui';

export const BlockchainFeatures = () => {
  return (
    <>
      <Intro />
      <Features />
    </>
  );
};
