import React from 'react';
import { Box, Button, Divider, MenuItem, SelectProps, Stack, TextFieldProps, Typography } from '@mui/material';
import { userModel } from 'entities/user';
import { InputWithSelect, UsdcLogo, UsdtLogo, useBreakpoints, useShallowSelector } from 'shared';

const coinsMock = [
  {
    name: 'USDT',
    image: UsdtLogo,
    value: 'USDT',
  },
  {
    name: 'USDC',
    image: UsdcLogo,
    value: 'USDC',
  },
];

const renderOptions = () => {
  return coinsMock.map((coin) => {
    return (
      <MenuItem value={coin.value} key={coin.value}>
        <Box
          component="img"
          width={20}
          height={20}
          src={coin.image}
          sx={{
            mr: 1,
          }}
        />
        {coin.name}
      </MenuItem>
    );
  });
};

type BuyInputProps = {
  onMaxClick: VoidFunction;
  onCurrencyChange: SelectProps['onChange'];
  currencyValue: string;
  stableBalance: string;
  isError?: boolean;
} & TextFieldProps;

export const BuyInput = React.forwardRef<any, BuyInputProps>(
  ({ onCurrencyChange, onMaxClick, isError, currencyValue, stableBalance, ...inputProps }, ref) => {
    const { isAuthenticated } = useShallowSelector(userModel.selectors.selectUserInfo);
    const { isDownSmLayout } = useBreakpoints();

    return (
      <InputWithSelect
        selectProps={{
          onChange: onCurrencyChange,
          value: currencyValue,
          options: renderOptions(),
          renderValue: (val) => {
            const coin = coinsMock.find((c) => c.value === val);

            if (!coin) {
              return null;
            }

            return (
              <Stack direction="row">
                <Box
                  component="img"
                  width={20}
                  height={20}
                  src={coin?.image}
                  sx={{
                    mr: 1,
                  }}
                />
                {isDownSmLayout ? null : coin?.name}
              </Stack>
            );
          },

          before: isAuthenticated && (
            <>
              <Button onClick={onMaxClick} color="secondary">
                Max
              </Button>
              <Divider
                orientation="vertical"
                sx={{
                  height: 'auto',
                }}
              />
            </>
          ),
        }}
        inputProps={{
          ...inputProps,
          ref,
          placeholder: '0.00',
          color: isError ? 'error' : 'primary',
          helperText: isAuthenticated && (
            <Stack direction="row" alignItems="center" justifyContent="flex-end" component="span">
              <Typography
                component="span"
                fontSize={15}
                variant="h6"
                className="secondary"
                sx={{
                  textAlign: 'right',
                }}
              >
                {currencyValue} Balance:{' '}
                <Typography variant="inherit" component="span">
                  {stableBalance} {currencyValue}
                </Typography>
              </Typography>
            </Stack>
          ),
        }}
      />
    );
  },
);
