import { NavLink } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Card, MenuItem } from '@mui/material';
import { COLOR_ACCENT, COLOR_PRIMARY } from 'shared';

import { MobileNavTitle } from '..';

type NavLinkWithAccordionProps = {
  title: string;
  navItems: { title: string; path?: string }[];
  onMenuItemClick: () => void;
};
export const NavLinkWithAccordion = ({ title, navItems, onMenuItemClick }: NavLinkWithAccordionProps) => {
  return (
    <Accordion sx={{ border: 'none', p: 0 }}>
      <AccordionSummary
        sx={{
          minHeight: 'unset',
          '&:hover': {
            '& p': { color: COLOR_ACCENT },
          },
          '.MuiAccordionSummary-expandIconWrapper': { svg: { color: COLOR_PRIMARY } },
        }}
      >
        <MobileNavTitle>{title}</MobileNavTitle>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 4 }}>
        <Card className="secondary" sx={{ p: 0, py: 1, maxWidth: 320, mx: 'auto' }}>
          {navItems.map((link) => (
            <MenuItem
              key={link.title}
              component={NavLink}
              to={link.path || ''}
              onClick={onMenuItemClick}
              sx={{
                borderRadius: 0,
                fontSize: 14,
                lineHeight: '21.7px',
                minHeight: 'unset',
                py: 0.5,
                whiteSpace: 'normal',
              }}
            >
              {link.title}
            </MenuItem>
          ))}
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};
