import { Grid, Typography } from '@mui/material';
import { COLOR_STROKE, Intro, Section } from 'shared';

import { privacyPolicyItems } from './constants';

export const PrivacyPolicy = () => {
  return (
    <>
      <Intro
        mainTitle="Privacy Policy"
        subTitle="Last Updated: March, 2024"
        paragraphProps={{ letterSpacing: '-0.4px !important' }}
        sx={{ h1: { maxWidth: 1000 } }}
      />

      <Section>
        {privacyPolicyItems.map((item, i) => (
          <Grid
            key={item.title}
            container
            sx={{ borderTop: `1px solid ${COLOR_STROKE}`, paddingTop: 2, paddingBottom: 4 }}
            rowSpacing={1}
          >
            {item.mainTitle && (
              <Grid item xs={12}>
                <Typography variant="h4" className="secondary md">
                  {item.mainTitle}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" className="secondary">
                {i + 1}. {item.title}
              </Typography>
            </Grid>
            {item.description && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" className="secondary">
                  {item.description}
                </Typography>
              </Grid>
            )}
            {item.subitems && (
              <Grid item container xs={12} sm={6}>
                {item.subitemsTitle && <Typography className="semi-bold secondary">{item.subitemsTitle}</Typography>}
                {item.subitems.map(({ subDescription }) => (
                  <Typography
                    component="li"
                    key={subDescription}
                    variant="body2"
                    className="secondary"
                    sx={{ mt: 1.25, width: '100%' }}
                  >
                    {subDescription}
                  </Typography>
                ))}
              </Grid>
            )}
          </Grid>
        ))}
      </Section>
    </>
  );
};
