import { Box, Card, Stack, Typography } from '@mui/material';
import { BORDER_MAIN, BORDER_RADIUS_S, H1, Icons, PageParagraph, Section } from 'shared';

const perksHelper = [
  {
    title: 'Block Rewards',
  },
  {
    title: 'Tiered StakePool APR+',
  },
  {
    title: 'EcoIP Royalties',
  },
  {
    title: 'Liquidity Pool Reward',
  },
  {
    title: 'Defined Price Progression',
  },
];

export const Intro = () => {
  return (
    <Section>
      <H1 text="Earning with CratD2C" highlightedWords={[0]} />

      <PageParagraph pProps={{ variant: 'h4', className: 'summary', sx: { gridColumn: 'span 12' } }}>
        Ecosystem Profits and Coin Holder Perks
      </PageParagraph>

      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', mt: { xs: 2, sm: 3.5 } }}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gridColumn: { xs: 'span 12', sm: '4 / 13', lg: '5 / 13' },
            padding: { xs: 1.5, sm: 2.25, md: 5, lg: 8.25 },
          }}
        >
          <Icons.PerksWithArrows
            sx={{
              maxWidth: { xs: 148, sm: 200, md: 341 },
              height: { xs: 130, sm: 150, md: 256 },
              width: '100%',
              mr: { xs: 1, sm: 1.25 },
            }}
          />
          <Stack spacing={{ xs: 0.75, sm: 1, md: 3 }}>
            {perksHelper.map(({ title }, index) => {
              const num = `${index > 8 ? '' : '0'}${index + 1}`.substring(-2);
              return (
                <Stack
                  key={title}
                  direction="row"
                  alignItems="center"
                  spacing={{ xs: 0.5, sm: 1 }}
                  sx={(theme) => ({
                    width: 'fit-content',
                    border: BORDER_MAIN,
                    borderRadius: BORDER_RADIUS_S,
                    padding: theme.spacing(0.75, 1),

                    [theme.breakpoints.down('sm')]: {
                      padding: theme.spacing(0.5, 1),
                    },
                  })}
                >
                  <Typography
                    fontSize={{ xs: '7px', sm: '12px', md: '16px' }}
                    lineHeight={{ xs: '8px', sm: '12px', md: '24px' }}
                    className="semi-bold secondary"
                  >
                    {num}
                  </Typography>
                  <Typography
                    fontSize={{ xs: '7px', sm: '10px', md: '16px' }}
                    lineHeight={{ xs: '9px', sm: '12px', md: '24px' }}
                    className="semi-bold accent"
                  >
                    {title}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Card>
      </Box>
    </Section>
  );
};
