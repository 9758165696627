export const list = [
  {
    id: '1',
    text: `Decentralized Automated Process: CratD2C Luxury Lifestyle booking portal automates booking processes, such as booking reservations, payments, and cancellations. These self-executing contracts can ensure that transactions are transparent, secure,And irreversible, reducing the potential for disputes and fraud. Tokenized Payment: The CRAT Coin is the means of payment within the booking portal and also for rewards, loyalty programs or exclusive offers for users.`,
  },
  {
    id: '2',
    text: `Decentralized Identity (DID): the booking portal has a decentralized identity management system to protect users' personal information, ensuring privacy and security. This system can enable users to maintain control over their data and share it only with authorized parties when necessary.`,
  },
  {
    id: '3',
    text: `Decentralized storage: It Utilizes decentralized storage solutions, such as IPFS or Filecoin, to store and distribute platform data. It enhances security, prevents data loss, and ensures better platform availability.`,
  },
  {
    id: '4',
    text: `User interface (UI) and user experience (UX): Designed with a user-friendly interface that makes it easy for users to browse, book, and manage luxury experiences. The UI/UX is intuitive, visually appealing, and compatible with desktop and mobile devices.`,
  },
  {
    id: '5',
    text: `Partner integrations: the platform establishes partnerships with luxury service providers (hotels, restaurants, bars, cruise yards, airlines, and event organizers) to offer a wide range of booking options and exclusive experiences. These partners can be onboarded and verified through the platform's decentralized systems, ensuring trust and reliability.`,
  },
  {
    id: '6',
    text: `Marketing and community building: Develop a marketing strategy to attract users and luxury service providers to the platform. Leverage social media, influencer partnerships, and public relations to build awareness and create a community around the portal.`,
  },
  {
    id: '7',
    text: `Governance: Establish a governance model that allows users and other stakeholders to participate in the platform's decision-making process, achieved through coin-based voting, and a decentralized autonomous community (DAC), promoting a fair and democratic governance structure.`,
  },
];
