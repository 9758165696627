import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { STORAGE_KEY } from '../constants';
import { useModalConditions } from '../hooks';
import { Inputs } from '../types';
import { sendFeedback } from '../utils';

import type { TABS } from './displayed-tab';

type ModalState = {
  isOpen: boolean;
  tab: keyof typeof TABS;
  onSubmit: (data: Inputs) => Promise<void>;
  onClose: () => void;
};

const ModalStateContext = createContext({} as ModalState);

export const useModalState = () => {
  return useContext(ModalStateContext);
};

export const ModalStateProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState<keyof typeof TABS>('form');
  const conditions = useModalConditions();
  const hasOpened = useRef(false);

  const onSubmit = useCallback(async (data: Inputs) => {
    try {
      const isSuccess = await sendFeedback(data);

      if (!isSuccess) {
        throw Error('Failed to send feedback');
      }

      localStorage.setItem(STORAGE_KEY, 'true');
      setTab('success');
    } catch {
      setTab('error');
    }
  }, []);

  const onClose = useCallback(() => {
    if (tab === 'form') {
      sessionStorage.setItem(STORAGE_KEY, 'true');
    }

    setIsOpen(false);
  }, [tab]);

  useEffect(() => {
    if (hasOpened.current || !conditions) {
      return;
    }

    setIsOpen(true);
  }, [conditions]);

  const contextState = useMemo(
    () => ({
      isOpen,
      tab,
      onSubmit,
      onClose,
    }),
    [tab, onSubmit, onClose, isOpen],
  );

  return <ModalStateContext.Provider value={contextState}>{children}</ModalStateContext.Provider>;
};
