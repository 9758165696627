import { COLOR_BG_BLACK, COLOR_GRAY, COLOR_RED, COLOR_SECONDARY, COLOR_STROKE, COLOR_WH } from '../colors.constants';

export const colorsTextField = {
  colorsTextFieldBackground: COLOR_BG_BLACK,
  colorsTextFieldBorder: COLOR_STROKE,
  colorsTextFieldColor: COLOR_WH,
  colorsTextFieldPlaceholderColor: COLOR_SECONDARY,
  colorsTextFieldColorError: COLOR_RED,
  colorsTextFieldColorDisabled: COLOR_GRAY,
  colorsFormLabelTextField: COLOR_SECONDARY,
};
