import { ReactNode } from 'react';
import { BoxProps, Grid, GridProps, Stack, Typography } from '@mui/material';
import { ListItem, Section } from 'shared';

interface AdvantagesProps {
  headTitle?: string | ReactNode;
  listTitle?: string | ReactNode;
  list: Array<{ title: string; description: string }>;
  listProps?: GridProps;
  listGap?: number;
}

export const Advantages = ({
  listTitle = '',
  headTitle = 'Advantages',
  list,
  listProps,
  listGap,
  ...boxProps
}: AdvantagesProps & BoxProps) => {
  return (
    <Section {...boxProps}>
      <Grid container rowSpacing={{ xs: 3, md: 0 }}>
        <Grid item xs={12} md={4}>
          <Typography variant="h4" className="secondary">
            {headTitle}
          </Typography>
        </Grid>

        <Grid item container xs={12} md={8} rowSpacing={6} {...listProps}>
          {!!listTitle && (
            <Grid item xs={12}>
              {typeof listTitle === 'string' ? (
                <Typography variant="h4" className="secondary">
                  {listTitle}
                </Typography>
              ) : (
                listTitle
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <Stack gap={listGap || 4.5}>
              {list.map((item, i) => {
                return (
                  <ListItem
                    key={item.title}
                    number={i + 1}
                    title={item.title}
                    description={item.description}
                    spacing={{ xs: 2 }}
                    childrenOpts={{
                      titleGridProps: { xs: 12, md: 3, lg: 2.9 },
                      descriptionGridProps: { xs: 12, md: 9 },
                    }}
                  />
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};
