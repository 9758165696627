export const list = [
  {
    title: 'Property Listing',
    description:
      'Sellers can list their properties on the platform, providing essential information such as descriptions, images, pricing, location, and legal documentation, enabling buyers to search and filter properties based on preferences. These listings are stored on the blockchain, ensuring transparency and immutability.',
  },
  {
    title: 'Decentralized Identity and Reputation',
    description:
      'The platform incorporates decentralized identity solutions, enabling users to create and manage their digital identities without relying on a centralized authority. Additionally, a decentralized reputation system can track user behaviour, ratings, and reviews, allowing stakeholders to establish trust and credibility within the community.',
  },
  {
    title: 'Offer and Negotiation',
    description:
      'Allows buyers to submit offers and negotiate with sellers through smart contracts and automates offer acceptance, counteroffers, and rejections with records stored on the blockchain.',
  },
  {
    title: 'Cryptocurrency Payments',
    description:
      'Transactions on CratD2C decentralized real estate trading portal uses the blockchain native coin and coin, which enables fast, secure, and low-cost payments, as well as global accessibility and reduced reliance on the traditional approach.',
  },
  {
    title: 'Decentralized Escrow and Financing',
    description:
      'The platform integrate with decentralized financial services, such as escrow, mortgages, or loans, to facilitate secure and transparent property transactions.',
  },
  {
    title: 'Property Tokenization',
    description:
      'Properties can be tokenized, dividing them into fractional ownership represented by digital coins. This enables increased liquidity and accessibility for a wider range of investors.',
  },
  {
    title: 'Land Registry and Title Management',
    description:
      'The platform store property titles and land registry information on the blockchain, ensuring a transparent, tamper-proof, and easily accessible record of property ownership.',
  },
  {
    title: 'Dispute Resolution',
    description:
      'Decentralized dispute resolution mechanisms, such as community-based arbitration or automated smart contract resolutions, are employed to handle conflicts between stakeholders, ensuring a fair and transparent process.',
  },
  {
    title: 'Governance',
    description:
      'A decentralized real estate trading portal utilize a governance model that allows users to participate in decision-making processes, such as voting on platform updates, fee structures, or dispute resolution policies.',
  },
  {
    title: 'Interoperability and Scalability',
    description:
      'The platform is designed to ensure compatibility with other blockchain networks and systems, allowing for seamless interaction and integration of various decentralized services. Which is also built to handle high volumes of transactions and support a growing user base.',
  },
];
