import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Widget: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 31 31"
    sx={{
      width: '31px',
      height: '31px',
      fill: 'none',
      color: '#B4EB56',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M28.7916 7.4356C28.7916 5.89564 28.1798 4.41874 27.0909 3.32982C26.002 2.2409 24.5251 1.62915 22.9851 1.62915C21.4452 1.62915 19.9683 2.2409 18.8794 3.32982C17.7904 4.41874 17.1787 5.89564 17.1787 7.4356C17.1787 8.97557 17.7904 10.4525 18.8794 11.5414C19.9683 12.6303 21.4452 13.2421 22.9851 13.2421C24.5251 13.2421 26.002 12.6303 27.0909 11.5414C28.1798 10.4525 28.7916 8.97557 28.7916 7.4356ZM12.8238 23.4033C12.8238 21.8634 12.2121 20.3865 11.1232 19.2976C10.0343 18.2086 8.55736 17.5969 7.01739 17.5969C5.47742 17.5969 4.00053 18.2086 2.91161 19.2976C1.82269 20.3865 1.21094 21.8634 1.21094 23.4033C1.21094 24.9433 1.82269 26.4202 2.91161 27.5091C4.00053 28.598 5.47742 29.2098 7.01739 29.2098C8.55736 29.2098 10.0343 28.598 11.1232 27.5091C12.2121 26.4202 12.8238 24.9433 12.8238 23.4033Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M1.45384 5.76625C1.08233 7.00467 1.1348 8.33165 1.60291 9.53687C2.07102 10.7421 2.928 11.7566 4.03801 12.4196C5.14802 13.0826 6.44756 13.3561 7.73065 13.1969C9.01374 13.0376 10.207 12.4547 11.1212 11.5404C12.0355 10.6262 12.6184 9.43293 12.7777 8.14984C12.9369 6.86675 12.6634 5.56721 12.0004 4.4572C11.3374 3.34719 10.3229 2.49021 9.11768 2.0221C7.91246 1.55399 6.58548 1.50152 5.34706 1.87303M29.2725 25.7985C29.644 24.5601 29.5916 23.2331 29.1235 22.0279C28.6554 20.8227 27.7984 19.8082 26.6884 19.1452C25.5784 18.4822 24.2788 18.2086 22.9957 18.3679C21.7126 18.5272 20.5194 19.1101 19.6052 20.0244C18.6909 20.9386 18.108 22.1318 17.9487 23.4149C17.7895 24.698 18.063 25.9976 18.726 27.1076C19.389 28.2176 20.4035 29.0746 21.6087 29.5427C22.8139 30.0108 24.1409 30.0632 25.3793 29.6917"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);
