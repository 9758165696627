import { Box } from '@mui/material';

import { About, Benefits, Crowdsale, Ecosystem, FeaturedIn, Intro, Team, UseCases } from './ui';

export const Home = () => {
  return (
    <Box>
      <Intro />
      <About />
      <FeaturedIn />
      <UseCases />
      <Crowdsale />
      <Ecosystem />
      <Benefits />
      <Team />
    </Box>
  );
};
