import { ComponentsOverrides, Theme } from '@mui/material';

export const getMuiLinearProgressOverrides = (theme: Theme): ComponentsOverrides['MuiLinearProgress'] => ({
  root: {
    backgroundColor: theme.themeColors.colorsLinearProgressBackground,
    borderRadius: '100px',
    height: 14,
  },

  bar: {
    backgroundColor: theme.themeColors.colorsLinearProgressFillBackground,
  },
});
