import { Box, Grid, Stack, Typography } from '@mui/material';
import { FontFamilies } from 'shared/config/theme/Typography';
import { Section } from 'shared/ui/section';

interface ExplanationBlockProps {
  data: Array<{ title: string; description: string }>;
  bottomText?: string;
}

export const ExplanationBlock = ({ data, bottomText }: ExplanationBlockProps) => {
  return (
    <Section
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        rowGap: { xs: 0, md: 12 },
      }}
    >
      <Typography
        variant="h4"
        className="secondary"
        mb={{ xs: 3, md: 0 }}
        sx={{ gridColumn: { xs: 'span 13', md: '1 / 3' } }}
      >
        Explanation
      </Typography>

      <Box
        sx={{
          gridColumn: { xs: 'span 13', md: '5 / 13' },
        }}
      >
        <Stack spacing={{ xs: 3, md: 5 }}>
          {data.map(({ title, description }) => (
            <Grid
              key={title}
              container
              sx={{
                gridColumn: { md: 'span 6', sm: '4 / 13', xs: 'span 13' },
              }}
            >
              <Grid item xs={12} md={3}>
                <Typography mb={{ xs: 2, md: 0 }} className="semi-bold" pr={{ xs: 0, md: 4 }}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography fontFamily={FontFamilies.secondary} className="secondary">
                  {description}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Box>

      {bottomText && (
        <Typography
          variant="h4"
          className="summary"
          sx={{ mt: { xs: 6, md: 0 }, gridColumn: { xs: 'span 13', md: '5 / 13' } }}
        >
          {bottomText}
        </Typography>
      )}
    </Section>
  );
};
