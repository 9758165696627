import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LinkedinSecondary: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 21 21"
    sx={{
      width: '21px',
      height: '21px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <g clipPath="url(#clip0_2867_13572)">
      <rect width="20" height="20" transform="translate(0.669922 0.958984)" fill="#B4EB56" />
      <path
        d="M4.94443 6.64667C5.95143 6.64667 6.76776 5.83033 6.76776 4.82333C6.76776 3.81633 5.95143 3 4.94443 3C3.93743 3 3.12109 3.81633 3.12109 4.82333C3.12109 5.83033 3.93743 6.64667 4.94443 6.64667Z"
        fill="#0F0A13"
      />
      <path
        d="M8.48943 8.02539V18.1412H11.6303V13.1387C11.6303 11.8187 11.8786 10.5404 13.5153 10.5404C15.1294 10.5404 15.1494 12.0496 15.1494 13.2221V18.1421H18.2919V12.5946C18.2919 9.86956 17.7053 7.77539 14.5203 7.77539C12.9911 7.77539 11.9661 8.61456 11.5469 9.40872H11.5044V8.02539H8.48943ZM3.37109 8.02539H6.51693V18.1412H3.37109V8.02539Z"
        fill="#0F0A13"
      />
    </g>
    <defs>
      <clipPath id="clip0_2867_13572">
        <rect x="0.669922" y="0.958984" width="20" height="20" rx="4" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
