import { Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_SECONDARY } from 'shared';

import { AmbassadorialSectionProps } from '../constants';

import { AmbassadorialListItem } from './ambassadorial-list-item';

export const AmbassadorialSystemSection = ({
  id,
  title,
  subtitle,
  withNumeration,
  items,
  subitemsList,
  bottomCard,
  bottomText,
}: AmbassadorialSectionProps) => {
  return (
    <Stack data-section id={id} pt={{ xs: 8, md: 0 }} spacing={{ xs: 4, md: 6 }}>
      <Typography variant="h4" className="large semi-bold">
        {title}
      </Typography>
      {subtitle && <Typography>{subtitle}</Typography>}
      {items && (
        <Stack spacing={4}>
          {items.map(({ title: itemTitle, description }, index) => (
            <AmbassadorialListItem
              key={`${title + index}`}
              title={itemTitle}
              description={description}
              orderNumber={withNumeration ? index + 1 : undefined}
            />
          ))}
        </Stack>
      )}
      {subitemsList?.map(({ subitemsTitle, subitemsSubtitle, subitems, withNumeration: withSubNumeration }, index) => (
        <Stack key={`${subitemsTitle + index}`} spacing={4}>
          <Typography variant="h5" className="md" color={COLOR_ACCENT}>
            {subitemsTitle}
          </Typography>
          {subitemsSubtitle && (
            <Typography variant="body2" color={COLOR_SECONDARY}>
              {subitemsSubtitle}
            </Typography>
          )}
          {subitems.map(({ title: subitemTitle, description: subitemDescription }, subIndex) => (
            <AmbassadorialListItem
              key={`${title + subIndex}`}
              title={subitemTitle}
              description={subitemDescription}
              orderNumber={withSubNumeration ? subIndex + 1 : undefined}
            />
          ))}
        </Stack>
      ))}
      {bottomCard}
      {bottomText && <Typography>{bottomText}</Typography>}
    </Stack>
  );
};
