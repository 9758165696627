import { PropsWithChildren } from 'react';
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { wagmiConfig } from 'app/config/wallet';
import { chains } from 'app/config/wallet/chains';
import { WagmiConfig } from 'wagmi';

export const WithWagmi = ({ children }: PropsWithChildren) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider theme={darkTheme()} chains={chains}>
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
