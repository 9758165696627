import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { formatNumber } from 'shared';

export const LinearProgressWithLabel = ({
  value,
  wrapperSx,
  label,
  labelSx,
  ...props
}: LinearProgressProps & {
  wrapperSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  value: number;
  label?: React.ReactNode | string;
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1.5, ...wrapperSx }}>
        <LinearProgress
          {...props}
          variant="determinate"
          value={value}
          sx={{
            ...props.sx,
          }}
        />
      </Box>
      {typeof label === 'string' ? (
        <Box sx={{ minWidth: 40, ...labelSx }}>
          <Typography variant="body2" className="font-secondary">
            {label || `${formatNumber(value)}%`}
          </Typography>
        </Box>
      ) : (
        label
      )}
    </Box>
  );
};
