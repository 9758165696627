export const cliffPurposes = [
  {
    title: 'Stability and Gradual Introduction',
    description:
      'The 3-month Cliff Period of CratD2C ensures that a sudden influx of coins into the market is avoided. This measure helps maintain price stability and protect the interests of both early participants and the broader ecosystem.',
  },
  {
    title: 'Building Confidence',
    description:
      "The Cliff Period instils confidence in participants by demonstrating the project's commitment to gradual and measured coin release, fostering trust and stability within the community.",
  },
  {
    title: 'Controlled Market Dynamics',
    description:
      'The Cliff Period prevents speculative trading and excessive price volatility often accompanying coin launches. It enables a controlled and calculated entry of coins into the market.',
  },
];

export const vestingAdvantages = [
  {
    title: 'Long-Term Commitment',
    description:
      "By implementing a Vesting Period, CratD2C encourages long-term commitment among participants. This approach aligns the interests of the participants with the project's long-term success, discouraging short-term speculation.",
  },
  {
    title: 'Steady Coin Circulation',
    description:
      'The gradual release of coins through the Vesting Period prevents a sudden oversupply of coins in the market, promoting a balanced circulation that contributes to price stability.',
  },
  {
    title: 'Mitigation of Dumping',
    description:
      'The Vesting Period curbs the possibility of participants immediately selling off their acquired coins, thereby avoiding potential "coin dumps" that could adversely affect coin prices across multiple tier-markets.',
  },
];
