import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CratThin: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 41 47"
    sx={{
      fill: 'none',
      width: '41px',
      height: '47px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M20.8777 30.4221C17.1076 30.4221 14.369 27.603 14.3868 23.7199C14.3868 19.8369 17.1076 17.0354 20.8777 17.0354C23.7052 17.0354 26.1948 18.9504 26.8172 21.5745L25.2345 21.7873C24.7722 19.8546 22.9405 18.4539 20.8777 18.4539C18.0324 18.4539 16.0051 20.6702 16.0051 23.7199C16.0051 26.7873 18.0501 29.0037 20.8777 29.0037C22.9049 29.0037 24.7366 27.6384 25.2523 25.7944L26.7995 26.0958C26.177 28.5959 23.6874 30.4221 20.8777 30.4221Z"
      fill="#B4EB56"
    />
    <path
      d="M30.4975 26.2417L30.5681 17.0355L12.3548 6.5173M40 24.9269L21.368 35.6841L13.4305 31.0198L22.1558 25.9998M18.5701 13.2107L10.5619 17.7526V39.267M1.58724 34.2071L1 12.3325L21.015 1.73633L39.9892 12.3266V34.2378L21.6235 45.5511L1.58724 34.2071Z"
      stroke="#B4EB56"
      strokeWidth="1.67334"
    />
  </SvgIcon>
);
