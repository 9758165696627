import { Grid, Stack, styled, Typography } from '@mui/material';
import { BORDER_MAIN, BORDER_RADIUS_L, BORDER_RADIUS_S, COLOR_BG_BLACK_4, COLOR_SECONDARY, Icons } from 'shared';

const BorderWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: BORDER_RADIUS_L,
  border: BORDER_MAIN,
  padding: theme.spacing(2),
  width: '100%',
  maxWidth: 517,
  height: 192,
  justifyContent: 'space-between',
}));

const BgWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: BORDER_RADIUS_S,
  background: COLOR_BG_BLACK_4,
  padding: theme.spacing(2, 2, 3),
  width: '100%',
  maxWidth: 517,
}));

const Subtitle = styled(Typography)({
  color: COLOR_SECONDARY,
  fontSize: 14,
  lineHeight: '155%',
});

const IconWrapper = styled(Stack)({
  borderRadius: '50%',
  border: BORDER_MAIN,
  width: 40,
  height: 40,
  justifyContent: 'center',
  alignItems: 'center',
});

export const Steps = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={2} mb={{ xs: 3, md: 0 }}>
        <Typography variant="h4" className="secondary">
          Steps
        </Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <Typography variant="h3" mb={{ xs: 3, sm: 4, md: 6.5 }}>
          To gain more points and boost your chances of winning, simply complete the easy steps
        </Typography>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          gap={{ xs: 2, md: 1 }}
          alignItems="center"
        >
          <BorderWrapper>
            <Stack direction="row" justifyContent="space-between">
              <IconWrapper>
                <Icons.Purchase />
              </IconWrapper>
              <Typography variant="h4" className="secondary">
                01
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="h5" className="no-resize">
                Personal Purchase
              </Typography>
              <Subtitle>Minimum of $250 ICO Purchase</Subtitle>
            </Stack>
          </BorderWrapper>
          <Icons.ArrowChevron sx={{ rotate: { xs: '90deg', md: 'unset' } }} />
          <BorderWrapper>
            <Stack direction="row" justifyContent="space-between">
              <IconWrapper>
                <Icons.Tasks />
              </IconWrapper>
              <Typography variant="h4" className="secondary">
                02
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="h5" className="no-resize">
                Complete Simple Tasks
              </Typography>
              <Subtitle>Answer Correctly Just 1 Quiz Question</Subtitle>
            </Stack>{' '}
          </BorderWrapper>
          <Icons.ArrowChevron sx={{ rotate: { xs: '90deg', md: 'unset' } }} />
          <BorderWrapper>
            <Stack direction="row" justifyContent="space-between">
              <IconWrapper>
                <Icons.Referral />
              </IconWrapper>
              <Typography variant="h4" className="secondary">
                03
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="h5" className="no-resize">
                Refer Others to Gather More Points
              </Typography>
              <Subtitle>Refer others using your unique ICO referral link</Subtitle>
            </Stack>
          </BorderWrapper>
        </Stack>
        <Stack gap={2.5} alignItems={{ xs: 'center', md: 'end' }} mt={{ xs: 3, sm: 4, md: 6.5 }}>
          <Typography variant="body2" className="secondary" maxWidth={513}>
            Refer others using your unique ICO referral link. Each of your referrals from 1st to 6th that join the
            contest through your unique link and complete their purchase earns you bulk points (20 Points each per
            referral), while the 7th referral and upward earns you jumbo points (50 Points each per referral).
            <br />
            <br />
            Each point you earn boosts your rank on the Leaderboard. In addition, you&apos;ll get an instant 10%
            commission deposited into your USDT wallet automatically for every transaction completed by your referred
            users.
          </Typography>
          <BgWrapper>
            <Typography className="semi-bold" mb={1.5}>
              Please note
            </Typography>
            <Typography variant="body2" className="secondary">
              To qualify for the CratD2C Pre-ICO Jumbo Giveaway, a minimum ICO purchase of{' '}
              <Typography component="span" variant="body2">
                $250
              </Typography>{' '}
              is required.
            </Typography>
          </BgWrapper>
        </Stack>
      </Grid>
    </Grid>
  );
};
