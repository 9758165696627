import { giveawayModel } from 'entities/giveaway';
import { createAppAsyncThunk, logger } from 'shared';

import { actionTypes } from '../action-types';
import { giveawayApi } from '../api';

export const getUserLeaderboardStats = createAppAsyncThunk(
  actionTypes.GET_USER_LEADERBOARD_STATS,
  async (any, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { index, points },
      } = await giveawayApi.getLeaderboardStats();

      dispatch(
        giveawayModel.actions.updateGiveawayState({
          winningOdds: index,
          points,
        }),
      );

      return null;
    } catch (err) {
      logger('getUserLeaderboardStats', err);
      return rejectWithValue(err);
    }
  },
);
