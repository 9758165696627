import { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import { calculateAPY, formatUIValue } from 'features/calculator';
import { BORDER_RADIUS_XL, COLOR_ACCENT, COLOR_PRIMARY, COLOR_STROKE } from 'shared';

import {
  CALCULATOR_BORDER,
  Frequency,
  Included,
  OptionsEnum,
  Pool,
  PoolConfigKeys,
  poolsConfig,
  uiTitles,
} from '../model';

interface DetailsProps {
  data: Pool;
  autoCompoundEnabled: boolean;
  activeOption: OptionsEnum;
}

type DataEntries = [PoolConfigKeys, Frequency | Included | number][];

const highlightedValues: Set<PoolConfigKeys> = new Set(['minAmount', 'apr']);

interface RowProps {
  title: string;
  value: Included | Frequency | number;
  isLast: boolean;
  shouldHighlight: boolean;
}

const Row = ({ title, value, isLast, shouldHighlight }: RowProps) => {
  return (
    <Stack
      pb={1}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'start', sm: 'center' }}
      justifyContent="space-between"
      sx={{ borderBottom: !isLast ? `1px dashed ${COLOR_STROKE}` : 'none' }}
      spacing={1}
    >
      <Typography variant="h6" className="secondary">
        {title}
      </Typography>
      <Typography variant="h6" color={shouldHighlight ? COLOR_ACCENT : COLOR_PRIMARY}>
        {formatUIValue(title, value)}
      </Typography>
    </Stack>
  );
};

export const Details = memo(({ data, autoCompoundEnabled, activeOption }: DetailsProps) => {
  const dataEntries = Object.entries(data) as DataEntries;

  return (
    <Stack spacing={1} sx={{ border: CALCULATOR_BORDER, borderRadius: BORDER_RADIUS_XL, padding: 2 }}>
      {dataEntries.map(([key, value], idx) => {
        const showApy = autoCompoundEnabled && key === 'apr';
        return (
          <Row
            key={key}
            value={showApy ? calculateAPY(+value, poolsConfig[activeOption].frequency) : value}
            title={showApy ? 'APY' : uiTitles[key]}
            shouldHighlight={highlightedValues.has(key)}
            isLast={dataEntries.length - 1 === idx}
          />
        );
      })}
    </Stack>
  );
});

Details.displayName = 'Details';
