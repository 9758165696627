import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useRef } from 'react';
import { giveawayModel } from 'entities/giveaway';
import { userModel } from 'entities/user';
import { noop } from 'lodash';
import { getToastMessage, logger, notifyText, toastError, useAppDispatch, useShallowSelector } from 'shared';
import { Address } from 'viem';
import { useAccount, useDisconnect, useNetwork, useSignMessage } from 'wagmi';

interface DisconnectParams {
  showNotification?: boolean;
}

interface WagmiConnectContextState {
  disconnect: (params?: DisconnectParams) => void;
}

const WagmiConnectContext = createContext<WagmiConnectContextState>({
  disconnect: noop,
});

export const useWagmiConnectContext = () => useContext(WagmiConnectContext);

export const WithWagmiConnect: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();

  const { disconnect: disconnectWallet, isLoading: isDisconnecting } = useDisconnect();

  const { accessToken } = useShallowSelector(userModel.selectors.selectIsAuthenticated);

  const { address: accountAddress } = useAccount();

  const isAuthorizedManually = useRef(false);

  const { chain, chains } = useNetwork();

  const { signMessageAsync } = useSignMessage();

  const disconnect = useCallback(
    ({ showNotification = true }: DisconnectParams = {}) => {
      disconnectWallet();
      dispatch(userModel.actions.disconnectWalletState());
      dispatch(giveawayModel.actions.clearUserGiveawayState());
      isAuthorizedManually.current = false;
      if (showNotification) {
        getToastMessage('success', notifyText.wallet.disconnect.info);
      }
    },
    [disconnectWallet, dispatch],
  );

  const authorize = useCallback(async () => {
    if (accountAddress && !isDisconnecting) {
      try {
        const message = await dispatch(userModel.thunks.getLoginMessage()).unwrap();
        const signedMessage = await signMessageAsync({ message });
        await dispatch(userModel.thunks.login({ address: accountAddress as Address, signedMessage, message }));
        isAuthorizedManually.current = true;
      } catch (error) {
        disconnect({ showNotification: false });
        toastError({ error, defaultMessage: 'Failed to log in' });
        logger('connect', error);
      }
    }
  }, [accountAddress, disconnect, dispatch, isDisconnecting, signMessageAsync]);

  useEffect(() => {
    // clear wagmi connected status in local storage when access token is empty
    // to prevent instant authorize call after page reload
    if (!accessToken.length && isAuthorizedManually.current === false) {
      disconnect({ showNotification: false });
    }
  }, [accessToken.length, disconnect]);

  useEffect(() => {
    if (accountAddress) {
      if (accessToken.length) {
        isAuthorizedManually.current = true;
        dispatch(userModel.actions.updateUserState({ address: accountAddress as Address, isAuthenticated: true }));
      } else {
        authorize();
      }
    }
  }, [accessToken.length, accountAddress, authorize, dispatch]);

  useEffect(() => {
    if (!isAuthorizedManually.current || !chain) return;

    if (!chains.some((supportedChain) => supportedChain.id === chain.id)) {
      getToastMessage('error', notifyText.wallet.connect.error.wrongNetwork(chains[0]));
      disconnect();
    }
  }, [chain, chains, disconnect]);

  return <WagmiConnectContext.Provider value={{ disconnect }}>{children}</WagmiConnectContext.Provider>;
};
