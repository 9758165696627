import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Linkedin: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 25"
    sx={{
      width: '24px',
      height: '25px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M4.98292 7.69729C6.19132 7.69729 7.17092 6.71769 7.17092 5.50929C7.17092 4.30089 6.19132 3.32129 4.98292 3.32129C3.77452 3.32129 2.79492 4.30089 2.79492 5.50929C2.79492 6.71769 3.77452 7.69729 4.98292 7.69729Z"
      fill="currentColor"
    />
    <path
      d="M9.2377 9.35469V21.4937H13.0067V15.4907C13.0067 13.9067 13.3047 12.3727 15.2687 12.3727C17.2057 12.3727 17.2297 14.1837 17.2297 15.5907V21.4947H21.0007V14.8377C21.0007 11.5677 20.2967 9.05469 16.4747 9.05469C14.6397 9.05469 13.4097 10.0617 12.9067 11.0147H12.8557V9.35469H9.2377ZM3.0957 9.35469H6.8707V21.4937H3.0957V9.35469Z"
      fill="currentColor"
    />
  </SvgIcon>
);
