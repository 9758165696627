import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { BORDER_RADIUS_M } from '../common';
import { FontFamilies, FontWeights } from '../Typography';

export const getMuiMenuItemOverrides = (theme: Theme): ComponentsOverrides['MuiMenuItem'] => ({
  root: {
    fontSize: 16,
    lineHeight: '26px',
    borderRadius: BORDER_RADIUS_M,
    color: theme.themeColors.colorMenuItemText,
    fontWeight: FontWeights.Regular,
    fontFamily: FontFamilies.primary,
    padding: '8px 16px',

    '&.Mui-selected, &:hover, &.Mui-selected:hover': {
      color: theme.themeColors.colorMenuItemText,
      backgroundColor: theme.themeColors.colorsMenuItemActiveBackground,
      '.MuiTypography-root': {
        color: theme.themeColors.colorMenuItemText,
      },
    },
  },
});

export const getMuiMenuItemDefaultProps = (): ComponentsProps['MuiMenuItem'] => ({
  disableRipple: true,
});
