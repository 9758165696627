import { Card } from '@mui/material';

import { InvestmentsInfo, ReferalBlock, Status } from './ui';

export const UserStatistic = () => {
  return (
    <Card
      sx={{
        padding: { sm: '32px 32px 16px', xs: 2 },
        height: '100%',
      }}
    >
      <Status />
      <ReferalBlock />
      <InvestmentsInfo />
    </Card>
  );
};
