import { Card, Stack, styled, Typography } from '@mui/material';
import { COLOR_LIGHT, COLOR_SECONDARY } from 'shared';

const CardItem = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
  },
}));

const CardLabel = styled(Typography)({
  color: COLOR_SECONDARY,
});

const CardText = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  lineHeight: '32px',
  color: COLOR_LIGHT,
  maxWidth: 505,
  [theme.breakpoints.down('md')]: {
    fontSize: 15,
    lineHeight: '20px',
  },
}));

export const CertificationCard = () => (
  <Card
    sx={{
      padding: 3,
      maxWidth: { sx: '100%', md: 638 },
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: { xs: 2.5, md: 3.75 },
    }}
  >
    <CardItem>
      <CardLabel textTransform="uppercase">Work of science:</CardLabel>
      <CardText>Interoco (Certificate № EC-01-004149)</CardText>
    </CardItem>
    <CardItem>
      <CardLabel>Certification Authority:</CardLabel>
      <CardText>International Online Copyright Office (INTEROCO), European Union, Berlin, Germany.</CardText>
    </CardItem>
  </Card>
);
