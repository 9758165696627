/* eslint-disable no-control-regex */
import { object, string } from 'yup';

export const STORAGE_KEY = 'dont-show-feedback-modal';

export const FORM_FIELDS = [
  { id: 'name', label: 'Name', placeholder: 'My name', isRequired: true },
  { id: 'email', label: 'Email', placeholder: 'my@email.com', isRequired: true },
  { id: 'telegram', label: 'Telegram', placeholder: 'Username or link', isRequired: false },
] as const;

const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const schema = object({
  name: string().required('Name is required'),
  email: string().matches(emailRegex, 'Email must be a valid email').required('Email is required'),
  telegram: string()
    .transform((v) => v || null)
    .nullable()
    .optional()
    .min(5, 'Telegram must be at least 5 characters long'),
}).required();
