import { bsc, bscTestnet } from 'viem/chains';
import { configureChains } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

const SUPPORTED_CHAINS_MAINNET = [bsc];
const SUPPORTED_CHAINS_TESTNET = [bscTestnet];

const CURRENT_CHAINS =
  process.env.REACT_APP_NODE_ENV === 'production' ? SUPPORTED_CHAINS_MAINNET : SUPPORTED_CHAINS_TESTNET;

export const { chains, publicClient, webSocketPublicClient } = configureChains([...CURRENT_CHAINS], [publicProvider()]);
