export const BOTTOM_TEXT =
  "LiteBackers are foundational to the CratD2C ecosystem, offering more than just financial support. Through their staking, they underline a commitment to the ecosystem's growth and stability. In return, they are rewarded with attractive earnings, the ability to compound these earnings, exclusive access to valuable IP portions, and the chance to contribute meaningfully to the ecosystem's development. These benefits highlight the ecosystem's appreciation for LiteBackers and ensure their active and ongoing participation, making them an indispensable part of the CratD2C community.";

export const intro = {
  paragraph:
    'LiteBackers play a pivotal role in the CratD2C Decentralized Autonomous Smart Chain Ecosystem, underpinning its growth and stability through substantial contributions. By staking a minimum of 200,000 CRAT Coins, LiteBackers affirm their support for the platform and actively engage in its development. Their commitment is rewarded with an attractive Annual Percentage Rate (APR) of 17%, offering substantial returns on their investment and incentivising continued participation.',
  subtitle: (
    <>
      The advantages of being a LiteBacker are manifold. Firstly, the option to re-stake holdings biweekly allows
      LiteBackers to compound their rewards, significantly increasing their earnings over time. Secondly, they gain
      exclusive access to Bonus CratD2C IP portions, which hold considerable value by entitling them to potential
      biannual royalty returns. This unique benefit provides an additional incentive layer, underscoring the
      ecosystem&#39;s appreciation for their involvement. <br />
      <br /> Moreover, staking itself demonstrates a LiteBacker&#39;s dedication to fostering the ecosystem&#39;s
      growth, ensuring its long-term stability and success. This level of support cultivates a more vibrant and engaged
      community, which is essential for the flourishing of the CratD2C ecosystem. Through these contributions and
      benefits, LiteBackers are recognised as investors and foundational members who contribute significantly to shaping
      and advancing the CratD2C Decentralized Autonomous Smart Chain Ecosystem.
    </>
  ),
};

export const list = [
  {
    title: 'Attractive Annual Returns',
    description:
      'LiteBackers are rewarded for their support with an Annual Percentage Rate (APR) of 17% on their staked coins, providing them with significant earnings as a coin of appreciation for their investment in the ecosystem.',
  },
  {
    title: 'Compounded Earnings through Biweekly Re-Staking',
    description:
      'The unique opportunity to re-stake their holdings biweekly enables LiteBackers to compound their rewards, enhancing their overall earnings over time and maximizing the return on their investment.',
  },
  {
    title: 'Exclusive Access to Bonus IP Portions',
    description:
      'LiteBackers receive exclusive Bonus CratD2C IP portions, which add significant value to their investment and entitle them to potential biannual royalty returns. This feature is an additional incentive for their active and continued involvement in the CratD2C ecosystem.',
  },
  {
    title: 'Support for Ecosystem Growth and Stability',
    description:
      'LiteBackers exhibit a deep-seated dedication to fostering the platform’s growth and ensuring its stability by staking their coins. This commitment is crucial for the development and success of the CratD2C ecosystem.',
  },
  {
    title: 'Incentivized Active Participation',
    description:
      'The structured rewards system, including both APR and bonus IP portions, encourages LiteBackers to remain actively engaged in the ecosystem. This engagement is vital for building a supportive and vibrant community within the CratD2C Decentralized Autonomous Smart Chain Ecosystem.',
  },
];
