import { ExplanationBlock, SplittedRows } from 'shared';

import { EXPLANATION_BOTTOM_TEXT, explanations, infoRows } from './constants';
import { ComparativeAnalysis, Intro, Roadmap } from './ui';

export const SmartChain = () => {
  return (
    <>
      <Intro />
      <Roadmap />
      <SplittedRows data={infoRows} />
      <ComparativeAnalysis />
      <ExplanationBlock data={explanations} bottomText={EXPLANATION_BOTTOM_TEXT} />
    </>
  );
};
