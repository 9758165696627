import { Box, Typography } from '@mui/material';
import { COLOR_GRAY_5 } from 'shared';

export const PaymentGateway = () => {
  return (
    <Box>
      <Typography variant="h5" letterSpacing="-0.021em">
        In the current world where cryptocurrencies and web3 applications are gaining much adoption, quite a few
        individuals own or apply their usage for daily activities. However, the recent wave of eCommerce growth implies
        that cryptocurrency can be used for any means of payment wherever possible, so the relevant industries are worth
        paying close attention to it.
      </Typography>
      <Box
        sx={{
          mt: 4.5,
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Box
          sx={{
            gridColumn: { xs: 'span 12', md: '4 / 13' },
          }}
        >
          <Typography>
            <Typography variant="body2" color={COLOR_GRAY_5} className="font-secondary">
              The main points where eCommerce businesses gain many transactions in the real world are PoS Terminals,
              Online Widget, API Transactions, and Vending Machines.
            </Typography>
            <br />
            <Typography variant="body2" color={COLOR_GRAY_5} className="font-secondary">
              The CratD2C Universal Payment Gateway give immediate solutions on a global scale in this perspective for
              this paradigm shift.
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
