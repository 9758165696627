import { Box, Grid, Stack, Typography } from '@mui/material';
import { Blockchain, ListItem, Note } from 'shared';

import { benefitsDelegator, benefitsLiteBacker, benefitsTurboBacker, benefitsValidator } from './constants';

export const Benefits = () => {
  return (
    <Box component="section" mt={{ xs: 6, md: 18 }}>
      <Grid container pb={6} spacing={3}>
        <Grid item sm={2} md={4} xs={12}>
          <Typography variant="h4" className="secondary">
            Benefits
          </Typography>
        </Grid>
        <Grid item sm={10} md={8} xs={12}>
          <Typography variant="h4" fontSize={36} className="accent" mb={2.5}>
            Validator
          </Typography>
          <Typography variant="body2" className="secondary font-secondary">
            {`In a Delegated Proof of Stake system (DPoS), validators are elected by coin holders to validate
            transactions and produce new blocks. The number of validators is limited, and they are chosen based on their
            reputation, the amount of cryptocurrency staked, and the number of votes they receive from (Delegators)
            coin holders. Validators are rewarded with new coins and transaction fees for their work.`}
            <br /> <br />
            {`In all these
              systems, validators play a crucial role in securing the network, maintaining its integrity, and ensuring its
              smooth operation. Validators are incentivized to act honestly and efficiently to maintain the community's
              trust and protect the network's value.`}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: 3,
        }}
      >
        <Box
          sx={{
            gridColumn: 'span 6',
            display: {
              md: 'block',
              xs: 'none',
            },
          }}
        >
          <Blockchain
            sx={{
              height: '100%',
              width: '940px',
              transform: 'translateX(-20%) scale(-1, 1)',
            }}
          />
        </Box>
        <Box
          sx={{
            gridColumn: { md: 'span 6', sm: '4 / 13', xs: 'span 13' },
          }}
        >
          <Stack useFlexGap gap={4.5}>
            {benefitsValidator.map((item, i) => {
              return <ListItem key={item.title} number={i + 1} description={item.description} title={item.title} />;
            })}
          </Stack>
        </Box>
      </Box>

      <Grid container pt={12} spacing={3}>
        <Grid item sm={2} md={4} xs={12} />
        <Grid item sm={10} md={8} xs={12}>
          <Typography variant="h4" fontSize={36} className="accent" mb={2.5}>
            Delegator
          </Typography>
          <Typography variant="body2" className="secondary font-secondary">
            A delegator in a Delegated Proof of Stake (DPoS) network is a participant who holds the native
            cryptocurrency of the network and delegates their staking power to one or more validators. Delegators do not
            actively participate in the consensus process or create new blocks; instead, they entrust their coins to
            validators who perform these tasks on their behalf.
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: 3,
        }}
      >
        <Box
          sx={{
            gridColumn: 'span 6',
            alignSelf: 'flex-end',
            display: {
              md: 'block',
              xs: 'none',
            },
          }}
        >
          <Blockchain
            sx={{
              height: '100%',
              width: '940px',
              transform: 'translateX(-20%)',
            }}
          />
        </Box>
        <Box
          sx={{
            gridColumn: { md: 'span 6', sm: '4 / 13', xs: 'span 13' },
          }}
        >
          <Stack mt={8} useFlexGap gap={4.5}>
            {benefitsDelegator.map((item, i) => {
              return <ListItem key={item.title} number={i + 1} description={item.description} title={item.title} />;
            })}
          </Stack>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Box
          sx={{
            gridColumn: { md: '5 / 13', xs: 'span 12' },
          }}
        >
          <Note
            sx={{
              mt: 7,
            }}
          >
            {`It's important to note that some risks may be associated with delegating in a DPoS network, such as the
            potential for slashing or losing coins if the validator they delegate behaves maliciously or fails to
            maintain the network adequately. However, delegators can mitigate these risks by carefully choosing
            validators, monitoring their performance, and benefiting from participating in the DPoS ecosystem.`}
          </Note>
        </Box>
      </Box>

      <Grid container pt={12} spacing={3}>
        <Grid item sm={2} md={4} xs={12} />
        <Grid item sm={10} md={8} xs={12}>
          <Typography variant="h4" fontSize={36} className="accent" mb={2.5}>
            LiteBacker
          </Typography>
          <Typography variant="body2" className="secondary font-secondary">
            LiteBackers are supporters of the CratD2C ecosystem who stake a minimum of 200,000 CRAT Coins. By staking
            their coins, LiteBackers demonstrate their commitment to the platform&apos;s growth and stability. In return
            for their contribution, LiteBackers earn an attractive Annual Percentage Rate (APR) of 17% on their staked
            coins. This APR serves as a reward for their support and further incentivizes their active participation in
            the network.
            <br />
            <br />
            LiteBackers have the unique advantage of being eligible to re-stake their holdings biweekly. This option
            allows them to compound their rewards and enhance their earnings over time. Additionally, LiteBackers enjoy
            the exclusive privilege of receiving Bonus CratD2C IP portions. These IP portions hold significant value, as
            they entitle LiteBackers to potential biannual royalty returns, providing an additional layer of incentive
            for their involvement in the CratD2C ecosystem.
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: 3,
        }}
      >
        <Box
          sx={{
            gridColumn: 'span 6',
            display: {
              md: 'block',
              xs: 'none',
            },
          }}
        >
          <Blockchain
            sx={{
              height: '100%',
              width: '940px',
              transform: 'translateX(-20%) scale(-1, 1)',
            }}
          />
        </Box>
        <Box
          sx={{
            gridColumn: { md: 'span 6', sm: '4.5 / 13', xs: 'span 13' },
          }}
        >
          <Stack mt={8} useFlexGap gap={4.5}>
            {benefitsLiteBacker.map((item, i) => {
              return (
                <ListItem
                  key={item.title}
                  number={i + 1}
                  description={item.description}
                  title={item.title}
                  spacing={3}
                />
              );
            })}
          </Stack>
        </Box>
      </Box>

      <Grid container pt={12} spacing={3}>
        <Grid item sm={2} md={4} xs={12} />
        <Grid item sm={10} md={8} xs={12}>
          <Typography variant="h4" fontSize={36} className="accent" mb={2.5}>
            TurboBacker
          </Typography>
          <Typography variant="body2" className="secondary font-secondary">
            TurboBackers are esteemed contributors who stake a minimum of 300,000 CRAT Coins. TurboBackers play a
            critical role in fortifying the network&apos;s decentralization and security by holding a substantial stake.
            TurboBackers enjoy a higher APR of 20% on their staked coins, reflecting their enhanced commitment and
            contribution to the ecosystem. Like LiteBackers, TurboBackers can re-stake their holdings weekly,
            compounding their rewards and maximizing their earnings potential.
            <br />
            <br />
            TurboBackers, like their LiteBacker counterparts, also benefit from the advantage of receiving Bonus CratD2C
            IP portions. These special IP portions confer eligibility for potential biannual royalty returns, rewarding
            TurboBackers for their substantial contribution and long-term commitment to the CratD2C ecosystem.
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          gap: 3,
        }}
      >
        <Box
          sx={{
            gridColumn: 'span 6',
            alignSelf: 'flex-end',
            display: {
              md: 'block',
              xs: 'none',
            },
          }}
        >
          <Blockchain
            sx={{
              height: '100%',
              width: '940px',
              transform: 'translateX(-20%)',
            }}
          />
        </Box>
        <Box
          sx={{
            gridColumn: { md: 'span 6', sm: '4 / 13', xs: 'span 13' },
          }}
        >
          <Stack mt={8} useFlexGap gap={4.5}>
            {benefitsTurboBacker.map((item, i) => {
              return <ListItem key={item.title} number={i + 1} description={item.description} title={item.title} />;
            })}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
