import { userModel } from 'entities/user';
import Cookies from 'js-cookie';
import { createAppAsyncThunk, getToastMessage, logger } from 'shared';
import { notifyText } from 'shared/config';

import { actionTypes } from '../action-types';
import { userApi } from '../api';
import { Login } from '../types';

export const login = createAppAsyncThunk(
  actionTypes.LOGIN,
  async ({ address, message, signedMessage }: Login, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { token },
      } = await userApi.login({
        data: {
          message,
          signedMsg: signedMessage,
          address,
        },
      });

      Cookies.set('token', token, { expires: 1 });

      dispatch(
        userModel.actions.updateUserState({
          accessToken: token,
          address,
          isAuthenticated: true,
        }),
      );

      Cookies.set('token', token, { expires: 1 });

      getToastMessage('success', notifyText.wallet.connect.success(address));

      return null;
    } catch (err) {
      logger('login', err);
      dispatch(userModel.actions.disconnectWalletState());
      return rejectWithValue(err);
    }
  },
);
