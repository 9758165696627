export const GET_TIMESTAMP = 'giveaway/getTimestamp';
export const GET_REFERRALS = 'giveaway/getReferrals';
export const GET_PURCHASES_INFO = 'giveaway/getPurchasesInfo';
export const GET_USER_LEADERBOARD_STATS = 'giveaway/getUserLeaderboardStats';
export const GET_LEADERBOARD = 'giveaway/getLeaderboard';
export const GET_USER_QUIZ_STATS = 'giveaway/getUserQuizStats';
export const ANSWER_QUIZ = 'giveaway/answerQuiz';

export const actionTypes = {
  GET_TIMESTAMP,
  GET_REFERRALS,
  GET_PURCHASES_INFO,
  GET_USER_LEADERBOARD_STATS,
  GET_LEADERBOARD,
  GET_USER_QUIZ_STATS,
  ANSWER_QUIZ,
};
