import { useRef, useState } from 'react';
import { Button, IconButton, Popover } from '@mui/material';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { userModel } from 'entities/user';
import { COLOR_STROKE, Icons, shortenPhrase, useBreakpoints, useShallowSelector } from 'shared';

import { LogoutMenu } from './logout-menu';

export const ConnectWallet = () => {
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [anchorElLogout, setAnchorElLogout] = useState<HTMLButtonElement | null>(null);

  const { isAuthenticated, userAddress } = useShallowSelector(userModel.selectors.selectIsAuthenticated);

  const { openConnectModal } = useConnectModal();

  const { isDownMdLayout } = useBreakpoints();

  const openLogout = Boolean(anchorElLogout);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isAuthenticated) {
      setAnchorElLogout(event.currentTarget);
    } else {
      openConnectModal?.();
    }
  };

  const handleCloseLogout = () => {
    setAnchorElLogout(null);
  };

  return (
    <>
      {isDownMdLayout ? (
        <IconButton
          id="connect-button"
          ref={anchorEl}
          onClick={handleClick}
          color="secondary"
          sx={{
            position: 'relative',
          }}
        >
          {!isAuthenticated ? (
            <Icons.CancelCircleRed
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'translate(50%, -50%)',
              }}
            />
          ) : (
            <Icons.ConfirmCircleGreen
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'translate(50%, -50%)',
              }}
            />
          )}
          <Icons.Wallet
            sx={{
              color: COLOR_STROKE,
            }}
          />
        </IconButton>
      ) : (
        <Button
          id="connect-button"
          ref={anchorEl}
          onClick={handleClick}
          variant="outlined"
          sx={{
            fontSize: 14,
            fontWeight: 400,
            px: 2,
            width: '145px',
            whiteSpace: 'nowrap',
          }}
        >
          {userAddress ? shortenPhrase(userAddress, 6) : 'Connect wallet'}
        </Button>
      )}
      <Popover
        open={openLogout}
        anchorEl={anchorElLogout}
        onClose={handleCloseLogout}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            padding: '32px',
            maxWidth: 350,
            width: '100%',
            mt: 1,
          },
        }}
      >
        <LogoutMenu handleClose={handleCloseLogout} />
      </Popover>
    </>
  );
};
