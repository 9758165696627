import { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { GridBreakpointsType } from 'shared/types';

import { TdProps } from './types';

export interface TableHeadProps<T> {
  headLeftSpace?: number;
  thData: T[];
  TdSlot: FC<TdProps<T>>;
  gridConfig?: { [key: number]: GridBreakpointsType<number> };
}

export const TableHead = <T extends string | number>({
  headLeftSpace = 4,
  thData,
  TdSlot,
  gridConfig,
}: TableHeadProps<T>) => {
  return (
    <Box component="thead" width="100%">
      <Grid container component="tr">
        <Grid item xs={headLeftSpace} component="td" sx={{ visibility: 'hidden' }} />

        {thData.map((option, index) => {
          const calculatedColumns = (12 - headLeftSpace) / thData.length;
          return (
            <Grid item component="td" key={option} xs={gridConfig?.[index].xs || calculatedColumns}>
              <TdSlot value={option} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
