import { ExplanationBlock } from 'shared';

import { BOTTOM_TEXT, explanationData } from './constants';
import { Analysis, Intro } from './ui';

export const Latency = () => {
  return (
    <>
      <Intro />
      <Analysis />
      <ExplanationBlock data={explanationData} bottomText={BOTTOM_TEXT} />
    </>
  );
};
