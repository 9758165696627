import React, { PropsWithChildren } from 'react';
import { IconButton, Stack } from '@mui/material';
import { BORDER_RADIUS_M, COLOR_BG_BLACK_3, COLOR_GRAY_3, Icons } from 'shared';

interface ModalProps {
  showClose?: boolean;
  onClose?: VoidFunction;
}

export const Modal = React.forwardRef<any, PropsWithChildren<ModalProps>>(({ children, showClose, onClose }, ref) => {
  return (
    <Stack flexGrow={1} alignItems="center" justifyContent="center" px={{ xs: 2, md: 0 }}>
      <Stack
        ref={ref}
        sx={{
          maxWidth: '630px',
          width: '100%',
          background: COLOR_BG_BLACK_3,
          border: '1px solid',
          borderColor: '#414141',
          borderRadius: BORDER_RADIUS_M,
          zIndex: 100,
          padding: '27px 70px',
          pointerEvents: 'auto',
          color: COLOR_GRAY_3,
          position: 'relative',
        }}
      >
        {!!showClose && (
          <IconButton
            onClick={onClose}
            className="transparent"
            sx={{
              position: 'absolute',
              top: 32,
              right: 32,
            }}
          >
            <Icons.Close
              sx={{
                color: '#F2F2F2',
              }}
            />
          </IconButton>
        )}
        {children}
      </Stack>
    </Stack>
  );
});
