import { Box } from '@mui/material';
import { COLOR_PRIMARY } from 'shared';

export const rows = [
  {
    title: 'Blockchain Validators',
    description:
      "Validators are the custodians of security and consensus within the CratD2C blockchain. They are responsible for confirming transactions, maintaining the network's integrity, and safeguarding against malicious activities. Validators are required to stake a minimum of 100,000 CRAT Coins, demonstrating their commitment to the network's security. In return, Validators receive a staking reward of 15% APR and transaction fees generated on the blockchain. They also possess the unique ability to re-stake their coins, ensuring continued network participation. While every native coin of CratD2C possesses the unique capability to receive EcoIP royalties, Validators are not eligible for Bonus CratD2C IP portions.",
  },
  {
    title: 'Delegators',
    description:
      "Delegators are essential contributors to the CratD2C ecosystem, participating in the network's consensus mechanism through Validators. Delegators stake their CRAT Coins in a chosen Validator's Pool, enabling them to earn rewards based on the staked amount. Delegators earn a competitive 13% APR on their staked coins and have the opportunity to re-stake their holdings periodically. While every native coin of CratD2C possesses the unique capability to receive EcoIP Royalties, like Validators, Delegators do not receive Bonus CratD2C IP portions.",
  },
  {
    title: 'LiteBackers',
    description:
      'LiteBackers are key supporters of the CratD2C ecosystem, with a minimum stake of 200,000 CRAT Coins. They are eligible for an APR of 17% on their staked coins. They can choose to re-stake their holdings biweekly, enhancing their reward potential. Unlike Validators and Delegators, LiteBackers do not receive transaction fees from the blockchain. However, they have a unique privilege: eligibility for Bonus CratD2C IP portions, which provide the opportunity for biannual royalties returns, in addition to every native coin they hold which possesses the unique capability to receive EcoIP royalties.',
  },
  {
    title: 'TurboBackers',
    description:
      'TurboBackers are prominent contributors who maintain a minimum stake of 300,000 CRAT Coins. They receive a remarkable APR of 20% on their staked holdings and can re-stake their coins weekly. Like LiteBackers, TurboBackers do not earn transaction fees from the blockchain but enjoy the privilege of Bonus CratD2C IP portions, offering potential biannual royalties, in addition to every native coin they hold which possesses the unique capability to receive EcoIP royalties.',
  },
  {
    title: 'Bonus CratD2C IP Portions',
    description: (
      <>
        Bonus CratD2C IP portions significantly enhance the engagement and rewards structure within the ecosystem. These
        portions are exclusively available to LiteBackers and TurboBackers, providing them unique opportunities. Holders
        of these portions are entitled to biannual royalty returns, an added benefit that rewards their commitment and
        contribution to the CratD2C ecosystem.
        <br />
        <br /> Collectively, these roles form the backbone of the CratD2C Decentralized Autonomous Smart Chain, uniting
        individuals with diverse interests and commitments towards the platform&#39;`s growth and success. Each role
        plays a critical part in fostering security, stability, decentralization, and financial empowerment within this
        innovative ecosystem.
      </>
    ),
  },
  {
    title: 'Layer-1 Blockchain Infrastructure',
    description:
      'CratD2C operates on a robust Layer-1 blockchain infrastructure, ensuring speed, scalability, and security for its diverse ecosystem of applications.',
  },
  {
    title: 'DPoS Consensus',
    description:
      'The DPoS consensus mechanism facilitates efficient validation and verification of transactions, enhancing network efficiency and maintaining security.',
  },
  {
    title: '300M Max Supply',
    description:
      'With a maximum supply of 300 million CRAT Coins, the ecosystem offers a controlled and balanced coin distribution.',
  },
  {
    title: '8-Layer ZigZag Supply Mechanism',
    description:
      'The innovative supply mechanism ensures a gradual distribution of coins, preventing concentration and fostering a fair ecosystem.',
  },
  {
    title: (
      <>
        Latency:{' '}
        <Box component="span" fontSize="inherit" color={COLOR_PRIMARY}>
          {' '}
          0.5 - 3 Seconds Finality Time
        </Box>
      </>
    ),
    description:
      'Transactions within CratD2C achieve rapid finality with low latency, enhancing user experience and efficiency.',
  },
  {
    title: 'Upto 100K TPS',
    description:
      'Transactions within CratD2C achieve rapid finality with low latency, enhancing user experience and efficiency.',
  },
  {
    title: 'Indigenous Ecosystem dApps',
    description:
      'CratD2C offers a range of primary dApps, facilitating various transactions, interactions, and operations within the ecosystem.',
  },
  {
    title: 'Can Host Several dApps (Coins, NFTs, MetaVerses, DEx, DAOs, & more)',
    description:
      'Beyond primary dApps, CratD2C has the flexibility to accommodate a diverse array of decentralized applications, including coins, NFTs, MetaVerses, DEx, and DAOs, enhancing its utility and use cases.',
  },
];
