import type { AbiItem } from 'web3-utils';

import crowdsale from './crowdsale.abi.json';
import erc20 from './erc20.abi.json';

export * from './crowdsaleAbi';
export * from './erc20Abi';

export const crowdsaleAbi = crowdsale as AbiItem[];
export const erc20Abi = erc20 as AbiItem[];
