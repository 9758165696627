import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ConnectModalState = {
  open: boolean;
  anchorId?: string;
};

export const initialState: ConnectModalState = {
  open: false,
  anchorId: '',
};

const connectModalSlice = createSlice({
  name: 'connectModal',
  initialState,
  reducers: {
    openModal(state: ConnectModalState, action: PayloadAction<{ anchorId?: string } | undefined>) {
      state.open = true;
      state.anchorId = action.payload?.anchorId;
    },

    closeModal(state: ConnectModalState) {
      state.open = false;
      state.anchorId = '';
    },
  },
});

export const { reducer } = connectModalSlice;
export const { actions } = connectModalSlice;
