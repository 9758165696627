import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Chevron: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 16 9"
    sx={{
      fill: 'none',
      width: '16px',
      height: '9px',
      ...sx,
    }}
    {...props}
  >
    <path d="M15 1L8 8L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
