import { Box, Typography } from '@mui/material';
import { H1, Section } from 'shared';

export const Intro = () => {
  return (
    <Section>
      <H1 text="Blockchain Features" highlightedWords={[0]} />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          mt: { xs: 2, sm: 3.5 },
          rowGap: { xs: 2, md: 6 },
        }}
      >
        <Box
          sx={{
            gridColumn: { xs: 'span 12', md: '5 / 13' },
          }}
        >
          <Typography variant="h4" fontSize="26px" lineHeight="35px">
            Descriptive Overview of CratD2C Decentralized Autonomous Smart Chain (D.A.S.C) and its Blockchain Features:{' '}
          </Typography>
        </Box>

        <Box
          sx={{
            gridColumn: { xs: 'span 12', md: '7 / 13' },
          }}
        >
          <Typography variant="body2" className="font-secondary secondary">
            The CratD2C Decentralized Autonomous Smart Chain (D.A.S.C) is a revolutionary blockchain platform that
            empowers individuals and businesses with a host of innovative features. This cutting-edge blockchain
            technology is designed to foster efficiency, security, and transparency in various industries. From rapid
            transactions to enhanced privacy, CratD2C offers a comprehensive suite of features that redefine the
            possibilities of decentralized ecosystems.
          </Typography>
        </Box>
      </Box>
    </Section>
  );
};
