import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Todo: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 36 35"
    sx={{
      width: '36px',
      height: '35px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M12.1932 28.3064H6.38672"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1694 18.5081C27.5763 18.5081 30.3387 21.2705 30.3387 24.6774C30.3387 28.0843 27.5763 30.8468 24.1694 30.8468C20.7624 30.8468 18 28.0843 18 24.6774C18 21.2705 20.7624 18.5081 24.1694 18.5081"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5686 23.4783L23.5695 26.4773L21.7695 24.6773"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1932 21.0483H6.38672"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.6125 13.7903H6.38672"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M29.6125 6.5322H6.38672" stroke="#B4EB56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
