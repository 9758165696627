import { Br } from 'shared';

export const infoRows = [
  {
    title: 'Unleashing Possibilities:',
    description:
      "CratD2C's SmartChain is a testament to our commitment to innovation and progress. We're not just building a blockchain; we're creating a platform that fuels industries, empowers participants, and fosters collaboration.",
  },
  {
    title: (
      <>
        Validators, Delegators, Backers: <Br display={{ xs: 'none', sm: 'inline' }} /> Powering the Network
      </>
    ),
    description:
      "Our SmartChain thrives on the active participation of Validators, Delegators, and Backers. Validators ensure security and consensus, Delegators contribute to transaction processing and validation, while Backers enhance the network's health and stability.",
  },
  {
    title: (
      <>
        Empowering Startups: Community <Br display={{ xs: 'none', sm: 'inline' }} /> Trust Vesting Grant (CTVG)
      </>
    ),
    description:
      'The SmartChain also supports promising startups through the Community Trust Vesting Grant (CTVG). This grant empowers startups to build their architecture on our SmartChain, fostering innovation, collaboration, and real-world solutions.',
  },
  {
    title: "Experience the Future with CratD2C's SmartChain:",
    description:
      "Our SmartChain isn't just technology; it's a vision realised. It's a bridge between the present and the future, where innovation meets practicality and blockchain becomes more than a buzzword—it becomes a game-changer.",
  },
];

export const explanations = [
  {
    title: 'Latency',
    description:
      "CratD2C boasts an impressive latency of 0.5 to 3 seconds, providing nearly instant finality for transactions. In contrast, Bitcoin's latency can range from several minutes to much longer, Ethereum's latency is in the seconds range, Cardano and EOS offer similar low-latency experiences, while Solana achieves sub-second latency.",
  },
  {
    title: 'Transactions Per Second (TPS)',
    description:
      "CratD2C shines with a high TPS capacity of up to 100,000 transactions per second. Bitcoin, with 3 to 7 TPS, is significantly slower due to its Proof of Work (PoW) consensus mechanism. Ethereum's TPS ranges from 30 to 45, but it faces scalability challenges. Cardano and EOS offer higher TPS, catering to around 1,000 to 1,500 and 4,000 to 8,000 respectively. Solana stands out with an impressive TPS of 50,000 to 65,000, making it a leader in scalability within the threshold of PoS .",
  },
];

export const EXPLANATION_BOTTOM_TEXT =
  "CratD2C's competitive latency and remarkable TPS capabilities position it as a strong contender among leading blockchain projects, offering efficiency, speed, and scalability for a wide range of applications.";
