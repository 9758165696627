import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Advantages, Intro } from 'shared';

import { intro, list, listTitle } from './constants';
import { EcosystemValuationTable, EcosystemValuationTableMobile } from './ui';

export const EcosystemValuation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Intro
        mainTitle="Ecosystem Valuation"
        paragraphSubtitle={intro.subtitle}
        paragraph={intro.paragraph}
        paragraphProps={{ lineHeight: { md: '36px !important' } }}
      />
      <Advantages list={list} listTitle={listTitle} headTitle="Valuation Summary" />
      <Grid container rowSpacing={{ xs: 3, md: 0 }}>
        <Grid item xs={12} md={4}>
          <Typography variant="h4" className="secondary">
            Valuation Overview
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={8}
          mb={{ xs: 6, md: 11 }}
          rowSpacing={{ xs: 3, md: 6 }}
          justifyContent="flex-end"
        >
          <Grid item xs={12}>
            <Typography variant="h4" className="summary">
              The valuation of CratD2C&apos;s IP was carefully segmented under two key spectrums, each reflecting a
              distinct aspect of CratD2C&apos;s intellectual and creative contributions:
            </Typography>
          </Grid>
          <Grid item container xs={12} md={9}>
            {isMobile ? <EcosystemValuationTableMobile /> : <EcosystemValuationTable />}
          </Grid>
          <Grid item xs={12} mt={1.5}>
            <Typography>
              The Intellectual Property Valuation Report underscores the significant economic and strategic value of
              CratD2C&apos;s Decentralized Autonomous Smart Chain, as evidenced by the substantial valuations attributed
              to both the Work of Science IP and the Author&apos;s Design. With a combined total value exceeding 160
              million USD, this valuation not only highlights the innovative strength and market potential of
              CratD2C&apos;s contributions but also positions the organization at the forefront of the blockchain
              technology sector. The meticulous valuation carried out by the London Rate International Office for IP
              Valuation affirms the exceptional worth of CratD2C&apos;s IP assets, underlining their importance in
              driving forward the boundaries of blockchain innovation and application.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
