import { Box, Grid } from '@mui/material';

import { steps } from './constants';
import { InfoRow } from './ui';

export const InfoLine = () => {
  return (
    <Box component="section" mt={{ xs: 8, md: 14 }}>
      <Grid container>
        {steps.map((step, i, arr) => {
          const isLast = i === arr.length - 1;

          return (
            <InfoRow key={step.id} head={step.head} isLast={isLast} title={step.title} icon={step.icon}>
              {step.children}
            </InfoRow>
          );
        })}
      </Grid>
    </Box>
  );
};
