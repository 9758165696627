import { ComponentsOverrides, ComponentsProps, iconButtonClasses, Theme } from '@mui/material';

import { BORDER_RADIUS_M, colorVariations, TRANSITION_300 } from '../common';
import { FontFamilies } from '../Typography';

const roundedVariation = {
  '&.rounded': {
    borderRadius: '50%',
  },
};

const transparentVariation = {
  '&.transparent': {
    padding: 0,
    width: 'auto',
    height: 'auto',
    backgroundColor: 'transparent',
    border: 'none',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export const getMuiIconButtonOverrides = (theme: Theme): ComponentsOverrides['MuiIconButton'] => ({
  root: {
    borderRadius: BORDER_RADIUS_M,
    fontFamily: FontFamilies.primary,
    boxShadow: 'none',
    textTransform: 'none',
    transition: TRANSITION_300,
    ...roundedVariation,
    ...colorVariations,
    ...transparentVariation,
  },

  sizeLarge: {
    height: 44,
    width: 44,
  },

  sizeMedium: {
    height: 40,
    width: 40,
  },

  sizeSmall: {},

  colorPrimary: {
    color: theme.themeColors.colorIconButtonContainedText,
    backgroundColor: theme.themeColors.colorIconButtonContainedBackground,

    '&:hover': {
      backgroundColor: theme.themeColors.colorIconButtonContainedHoverBackground,
    },

    [`&.${iconButtonClasses.disabled}`]: {
      pointerEvents: 'none',
      backgroundColor: theme.themeColors.colorIconButtonContainedDisabledBackground,
      opacity: 1,
      color: theme.themeColors.colorIconButtonContainedText,
    },
  },

  colorSecondary: {
    height: 72,
    width: 72,
    color: theme.themeColors.colorIconButtonOutlinedText,
    backgroundColor: theme.themeColors.colorIconButtonOutlinedBackground,
    border: `1px solid`,
    borderColor: theme.themeColors.colorIconButtonOutlinedBorder,

    '&:hover': {
      borderColor: theme.themeColors.colorButtonOutlinedHoverBorder,
    },

    '&.scale:hover': {
      transform: 'scale(1.3)',
    },

    [`&.${iconButtonClasses.disabled}`]: {
      pointerEvents: 'none',
      opacity: 0.7,
    },
  },
});

export const getMuiIconButtonDefaultProps = (): ComponentsProps['MuiIconButton'] => ({
  disableFocusRipple: true,
  disableRipple: true,
  size: 'medium',
  color: 'primary',
});
