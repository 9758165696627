import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_ACCENT, COLOR_BG_BLACK, COLOR_SECONDARY } from 'shared/config/theme/colors';
import { BORDER_MAIN } from 'shared/config/theme/common';

export const getMuiSwitchOverrides = (theme: Theme): ComponentsOverrides['MuiSwitch'] => {
  const trackSize = {
    width: '30px',
    height: '16px',
    borderRadius: '96px',
    padding: 0,
    border: 'none',
  };

  const switchBaseSize = {
    padding: 0,
    width: trackSize.height,
    height: trackSize.height,
    borderRadius: '50%',
  };

  return {
    root: {
      ...trackSize,
      boxSizing: 'content-box',
      opacity: '1 !important',
      margin: theme.spacing(0),
      overflow: 'visible',
      border: BORDER_MAIN,

      '&:focus-within::before': {
        display: 'block',
      },
    },
    switchBase: {
      ...switchBaseSize,
      backgroundColor: 'transparent',
      opacity: '1 !important',
      border: 'none',

      '&.Mui-checked': {
        transform: 'translateX(calc(100% - 1px))',

        '.MuiSwitch-thumb': {
          backgroundColor: COLOR_BG_BLACK,
        },

        '& + .MuiSwitch-track': {
          backgroundColor: COLOR_ACCENT,
        },
      },
    },
    input: {
      ...switchBaseSize,
      top: 0,
      left: 0,
      width: '100%',
    },
    thumb: {
      boxShadow: 'unset',
      width: 12,
      height: 12,
      border: 'none',
      backgroundColor: COLOR_SECONDARY,
    },
    track: {
      borderRadius: '48px',
      opacity: '1 !important',
      backgroundColor: COLOR_BG_BLACK,
    },
  };
};

export const getMuiSwitchDefaultProps = (): ComponentsProps['MuiSwitch'] => ({
  color: 'primary',
});
