import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Solana: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 38 37"
    sx={{
      width: '38px',
      height: '37px',
      ...sx,
    }}
    {...props}
  >
    <circle cx="19" cy="18.5488" r="18.4512" fill="black" />
    <path
      d="M11.9857 22.9212C12.1135 22.7933 12.2893 22.7188 12.4758 22.7188H29.3855C29.6945 22.7188 29.849 23.0917 29.6306 23.3101L26.2902 26.6505C26.1623 26.7784 25.9865 26.8529 25.8001 26.8529H8.89034C8.58134 26.8529 8.42684 26.48 8.64527 26.2616L11.9857 22.9212Z"
      fill="url(#paint0_linear_2969_3485)"
    />
    <path
      d="M11.9857 10.4485C12.1188 10.3207 12.2947 10.2461 12.4758 10.2461H29.3855C29.6945 10.2461 29.849 10.619 29.6306 10.8375L26.2902 14.1778C26.1623 14.3057 25.9865 14.3803 25.8001 14.3803H8.89034C8.58134 14.3803 8.42684 14.0074 8.64527 13.7889L11.9857 10.4485Z"
      fill="url(#paint1_linear_2969_3485)"
    />
    <path
      d="M26.2902 16.6439C26.1623 16.516 25.9865 16.4414 25.8001 16.4414H8.89034C8.58134 16.4414 8.42684 16.8143 8.64527 17.0328L11.9857 20.3732C12.1135 20.501 12.2893 20.5756 12.4758 20.5756H29.3855C29.6945 20.5756 29.849 20.2027 29.6306 19.9842L26.2902 16.6439Z"
      fill="url(#paint2_linear_2969_3485)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2969_3485"
        x1="-29.6759"
        y1="7.10981"
        x2="-30.1259"
        y2="29.7492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2969_3485"
        x1="-34.7931"
        y1="4.43743"
        x2="-35.243"
        y2="27.0768"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2969_3485"
        x1="-32.2508"
        y1="5.76406"
        x2="-32.7007"
        y2="28.4034"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
