import { ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { BarElement, BarElementsWrapper, ChartWrapper, YAxisText } from './vertical-bar-chart.styled';

type Axis = {
  label?: string | ReactNode;
  data?: Array<string>;
};

interface VerticalBarChartProps {
  data: Array<number>;
  xAxis: Axis;
  yAxis?: Axis;
  gap?: string;
  chartHeight?: number;
}

const YElementsSideSpace = '40px';

export const VerticalBarChart = ({ data, xAxis, yAxis, gap = '24px', chartHeight = 285 }: VerticalBarChartProps) => {
  const finalDataIndex = data.length - 1;
  return (
    <Stack width="100%">
      {yAxis?.label && (
        <Typography mb={4.25} className="secondary">
          {yAxis?.label}
        </Typography>
      )}
      <ChartWrapper height={`${chartHeight}px`}>
        <Stack width="100%" height="100%" position="absolute" top={0}>
          {(yAxis?.data || data.slice().reverse())?.map((elem, index) => (
            <Box
              key={elem}
              display="flex"
              width="100%"
              alignItems="flex-start"
              sx={{
                height: index === finalDataIndex ? '0' : `${100 / finalDataIndex}%`,
              }}
            >
              <YAxisText variant="subtitle2" className="secondary">
                {elem}%
              </YAxisText>
            </Box>
          ))}
        </Stack>
        <BarElementsWrapper
          width={`calc(100% - ${YElementsSideSpace})`}
          direction="row"
          alignItems="flex-end"
          gap={gap}
        >
          {data.map((elem) => (
            <BarElement key={elem} height={`${elem || 1}%`} width={`${100 / data.length}%`} />
          ))}
        </BarElementsWrapper>
      </ChartWrapper>
      {!!xAxis?.data?.length && (
        <Stack width={`calc(100% - ${YElementsSideSpace})`} direction="row" alignSelf="end" gap={gap} ml="auto" mt={2}>
          {xAxis.data.map((label) => (
            <Typography
              textAlign="center"
              className="secondary"
              key={label}
              sx={{ width: `${100 / (xAxis?.data?.length || 100)}%` }}
            >
              {label}
            </Typography>
          ))}
        </Stack>
      )}

      {xAxis?.label && (
        <Stack
          width={`calc(100% - ${YElementsSideSpace})`}
          display="flex"
          alignSelf="end"
          mt={1.25}
          alignItems="center"
          sx={{ p: { textAlign: 'center' } }}
        >
          {xAxis?.label}
        </Stack>
      )}
    </Stack>
  );
};
