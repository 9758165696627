import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { buyTokens, getCrowdsaleData } from './thunks';
import { BuyData } from './types';

export type CrowdsaleState = {
  cratAvailableAmount: string;
  cratTotalCount: number;
  usdTotalCount: number;
  usdTotalRaised: string;
  cratPrice: string;
  cratBalance: string;
  usdtBalance: string;

  currentBuyData: BuyData | null;
};

const initialCrowdsaleState: CrowdsaleState = {
  cratAvailableAmount: '0',
  cratTotalCount: 20_000_000,
  usdTotalCount: 5_000_000,
  usdTotalRaised: '0',
  cratPrice: '0',
  cratBalance: '0',
  usdtBalance: '0',

  currentBuyData: null,
};

const crowdsaleSlice = createSlice({
  name: 'crowdsale',
  initialState: initialCrowdsaleState,
  reducers: {
    updateCrowdsaleState: (state: CrowdsaleState, action: PayloadAction<Partial<CrowdsaleState>>) => ({
      ...state,
      ...action.payload,
    }),

    updateBuyData: (state: CrowdsaleState, action: PayloadAction<BuyData | null>) => {
      state.currentBuyData = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(getCrowdsaleData.fulfilled, (state, { payload }) => {
      state.cratPrice = payload.cratPrice;
      state.cratAvailableAmount = payload.cratAvailableAmount;
      state.usdTotalRaised = payload.usdTotalRaised;
    });

    builder.addCase(buyTokens.fulfilled, (state) => {
      state.currentBuyData = null;
    });
  },
});

export const { reducer } = crowdsaleSlice;
export const { actions } = crowdsaleSlice;
