import { CSSProperties, memo, ReactNode } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { COLOR_ACCENT } from 'shared/config';

interface H1Props {
  text: string;
  highlightedWords?: number[];
}

const renderHighlightedText = (str: string, iterable: Set<number>) => {
  const words = str.split(' ');
  const result: Array<ReactNode | string> = [];

  words.forEach((word, index) => {
    const wordElement = !iterable.has(index) ? (
      word
    ) : (
      <Typography variant="inherit" component="span" key={`${index + 1}`}>
        {word}
      </Typography>
    );

    result.push(wordElement);

    if (index < words.length - 1) {
      result.push(' ');
    }
  });

  return result;
};

export const H1 = memo(({ text, highlightedWords = [], ...props }: H1Props & TypographyProps) => {
  return (
    <Typography
      variant="h1"
      className="no-resize"
      {...props}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          fontSize: 30,
        },
        span: {
          fontSize: 'inherit',
          fontWeight: 'inherit',
          color: COLOR_ACCENT,
        },
        ...(props?.sx as CSSProperties),
      })}
    >
      {!highlightedWords.length ? text : renderHighlightedText(text, new Set(highlightedWords))}
    </Typography>
  );
});

H1.displayName = 'H1';
