import { Grid, Typography } from '@mui/material';
import { Advantages, FontWeights } from 'shared';

import { cliffPurposes } from '../constants';

export const CliffPeriod = () => {
  return (
    <>
      <Grid container component="section" rowSpacing={{ xs: 3, md: 0 }}>
        <Grid item xs={12} md={4}>
          <Typography variant="h4" className="accent">
            Cliff Period
          </Typography>
        </Grid>

        <Grid item xs={12} md={8} rowSpacing={4.75}>
          <Typography variant="h5" className="secondary" fontWeight={FontWeights.Regular}>
            The Cliff Period of CratD2C Decentralized Autonomous Smart Chain represents an initial phase of coin
            distribution designed to ensure a fair and controlled release of coins into the market. This period spans
            three months from the first day of CRAT Coin&#39;s listing on a centralized exchange. During this phase,
            participants who participated in the Pre-Pre Seed and Pre-Seed Rounds will be unable to sell their acquired
            coins. This mechanism is in place to prevent abrupt market fluctuations that could potentially undermine the
            stability of the CratD2C ecosystem.
          </Typography>
        </Grid>
      </Grid>
      <Advantages
        mt={6}
        component="div"
        headTitle=""
        list={cliffPurposes}
        listTitle={
          <Typography variant="h4" className="summary">
            The Cliff Period serves multiple critical purposes:
          </Typography>
        }
      />
    </>
  );
};
