import { giveawayModel } from 'entities/giveaway';
import { createAppAsyncThunk, fromDecimals, logger } from 'shared';

import { actionTypes } from '../action-types';
import { giveawayApi } from '../api';

export const getPurchasesInfo = createAppAsyncThunk(
  actionTypes.GET_PURCHASES_INFO,
  async (any, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { pointsEarned, purchaseAmount, tokensBought },
      } = await giveawayApi.getPurchasesInfo();

      dispatch(
        giveawayModel.actions.updateGiveawayState({
          purchasePointsEarned: pointsEarned,
          purchaseAmount,
          tokensBought: +fromDecimals(tokensBought),
        }),
      );

      return null;
    } catch (err) {
      logger('getPurchasesInfo', err);
      return rejectWithValue(err);
    }
  },
);
