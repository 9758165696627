import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { IEvent } from 'pages/upcoming-events/constants';
import { COLOR_ACCENT, COLOR_BLACK, Icons, InfoCircle } from 'shared';

import { AboutText } from './ui';

type EventBlockProps = { withDivider: boolean } & IEvent;

export const EventBlock = ({
  title,
  dateStart,
  dateEnd,
  location,
  image,
  description,
  descriptionMaxLength,
  link,
  email,
  booth,
  circlePlacement,
  withDivider,
}: EventBlockProps) => {
  return (
    <Stack>
      {withDivider && (
        <>
          <Typography variant="h4" mb={2} pt={{ xs: 3, md: 6 }}>
            {format(new Date(dateStart), 'MMMM yyyy')}
          </Typography>
          <Divider sx={{ mb: 7 }} />
        </>
      )}

      <Stack direction={{ xs: 'column', md: 'row' }} gap={2.5}>
        <Stack flex={{ xs: 'unset', md: '50%' }} position="relative" height="fit-content">
          <Box component="img" src={image} sx={{ width: '100%' }} />
          {circlePlacement && booth && (
            <InfoCircle placement={circlePlacement} size="sm">
              <Typography variant="h4" className="accent center">
                {booth}
              </Typography>
              <Typography variant="subtitle2" className="secondary medium center">
                BOOTH
              </Typography>
            </InfoCircle>
          )}
        </Stack>
        <Stack flex={{ xs: 'unset', md: '50%' }}>
          <Typography variant="h4" mb={2}>
            {title}
          </Typography>
          <Stack direction="row" gap={1} mb={1}>
            <Icons.Calendar />
            <Typography>
              {dateStart !== dateEnd && `${format(new Date(dateStart), 'dd')}-`}
              {format(new Date(dateEnd), 'dd MMMM yyyy')}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1} mb={2}>
            <Icons.Location />
            <Typography>{location}</Typography>
          </Stack>
          {descriptionMaxLength ? (
            <AboutText text={description} maxLength={descriptionMaxLength} />
          ) : (
            <Typography variant="body2" whiteSpace="pre-line" mb={2}>
              {description}
            </Typography>
          )}
          {email && (
            <Typography variant="body2" sx={{ mb: 2, '.accent': { color: COLOR_ACCENT, textDecoration: 'underline' } }}>
              For RSVP, please email:{' '}
              <a className="accent" href={`mailto:${email}}`}>
                {email}
              </a>
            </Typography>
          )}
          {link && (
            <Button
              size="small"
              endIcon={<Icons.Arrow sx={{ width: 19, height: 19, transform: 'rotate(90deg)', color: COLOR_BLACK }} />}
              href={link}
              rel="noreferrer"
              target="_blank"
              sx={{
                minHeight: '41px',
                height: 41,
                mt: 'auto',
                width: 179,
                whiteSpace: 'nowrap',
                px: 2.5,
                '.MuiButton-endIcon': { ml: 'auto' },
              }}
            >
              Become guest
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
