import {
  CryptoExpoDubaiEvent,
  Token2049Week,
  UniversalTechExpoPhilippinesEvent,
  UtexNightCebu,
  Web3InBaguioPhilippines,
  Web3SummitDubai,
} from 'shared';

export interface IEvent {
  title: string;
  dateStart: number;
  dateEnd: number;
  location: string;
  description: string;
  descriptionMaxLength?: number;
  link?: string;
  email?: string;
  image: string;
  booth?: string;
  circlePlacement?: string;
}

// If the event lasts only a day, add same dateStart and dateEnd
export const events: IEvent[] = [
  {
    title: 'WEB3 in Baguio Philippines',
    dateStart: 1710574534000,
    dateEnd: 1710574534000,
    location: 'Hotline Bar, Baguio city',
    description: `Join us on March 16, 2024, at the "WEB3 in Baguio Philippines" event, proudly sponsored by CratD2C D.A.S.C! 🚀 Immerse yourself in the groundbreaking world of blockchain innovation and explore the transformative power of CratD2C's Decentralized Autonomous Smart Chain.

    💡 Dive deep into the core concept of CratD2C DASC, where blockchain meets real-world applications! 🌐 From revolutionizing manufacturing processes to reshaping the landscape of real estate, CratD2C is spearheading a movement towards transparency, efficiency, and security.
    
    Don't miss this opportunity to unravel the potential of CratD2C and witness firsthand how Decentralized Autonomous Smart Chain is reshaping industries. Save the date and be part of the future at the "WEB3 in Baguio" event! 🚀🔗
    `,
    descriptionMaxLength: 268,
    email: 'elias.dwain1028@gmail.com',
    image: Web3InBaguioPhilippines,
  },
  {
    title: 'UTEX Night Cebu',
    dateStart: 1711179334000,
    dateEnd: 1711179334000,
    location: 'Sentral Bar & Lounge',
    description: `Get ready for an unforgettable night at UTEX Night Cebu, powered by CratD2C D.A.S.C! 🚀 Join us on March 22, 2024, at the Sentral Bar and Lounge for an exclusive opportunity to dive into the world of CratD2C's Decentralized Autonomous Smart Chain.

    💡 Connect with the community and explore the cutting-edge innovations that CratD2C DASC brings to the blockchain realm. From decentralized applications to transformative coins, witness how CratD2C is revolutionizing industries, from Manufacturing to Real Estate and beyond.
    
    🔗 Embrace the future of transparency, efficiency, and security as we showcase the power of CratD2C at UTEX Night Cebu. Don't miss out on this chance to network, learn, and be part of the CratD2C community. Save the date and join us for an evening of discovery and connection!
    `,
    descriptionMaxLength: 253,
    email: 'elias.dwain1028@gmail.com',
    image: UtexNightCebu,
  },
  {
    title: 'TOKEN2049',
    dateStart: 1713166534000,
    dateEnd: 1713684934000,
    location: 'Dubai',
    description: `TOKEN2049 is a global conference series, where the crypto ecosystem's decision-makers connect to exchange ideas, network, and shape the industry. TOKEN2049 is the pre-eminent meeting place for entrepreneurs, institutions, industry insiders, investors, builders, and those with a strong interest in the crypto and blockchain industry.

    Previous editions have been held in leading digital asset capitals – with our latest editions taking place semi-annually in Dubai and Singapore.
    `,
    descriptionMaxLength: 333,
    link: 'https://week.token2049.com/',
    image: Token2049Week,
  },
  {
    title: 'WEB3 Summit Dubai',
    dateStart: 1713252934000,
    dateEnd: 1713339334000,
    location: 'Atlantis, The Palm',
    description: `This conference will bring together experts, scholars, and entrepreneurs from around the world in the Web3 field to explore the latest advancements in Web3 technology, including blockchain technology, decentralized finance, smart contracts, and more.`,
    link: 'https://web3summitdubai.xyz/',
    image: Web3SummitDubai,
  },
  {
    title: 'Crypto Expo Dubai 2024',
    dateStart: 1716163200000,
    dateEnd: 1716249600000,
    location: 'Hall 8, Dubai World Trade Centre',
    description: `The Crypto Expo Dubai 2024, managed by HUAN QIAO EVENT MANAGEMENT L.L.C in the UAE, is set to take place at the World Trade Centre on May 20-21, 2024. This prestigious event will be graced by members of the Emirati Royal Families, venture capitalists, angel investors, family offices, and blockchain aficionados.

Highlighting the event, only five premium TITANIUM Booths are available at the primary entrance of the prestigious exhibition hall. Among these, your favourite Blockchain Enterprise, CratD2C D.A.S.C, will be prominently located at TITANIUM Booth No. 3, welcoming investors from various spectrums as they enter the exhibition space.
`,
    descriptionMaxLength: 313,
    link: 'https://cryptoexpodubai.com/',
    image: CryptoExpoDubaiEvent,
    booth: '03',
    circlePlacement: 'bottom-right',
  },
  {
    title: 'Universal Tech Expo Philippines',
    dateStart: 1717794000000,
    dateEnd: 1717880400000,
    location: 'NUSTAR Convention Centre, Cebu, Philippines',
    description: `We are excited to welcome CratD2C Decentralized Autonomous Smart Chain (DASC) as a sponsor for the Universal Tech Expo 2024! Founded on the vision of a transformative digital infrastructure, CratD2C Decentralized Autonomous Smart Chain (DASC)’s leverages DPoS consensus on Layer-1 to offer unparalleled security, instant finality, and remarkable scalability.

CratD2C Decentralized Autonomous Smart Chain (DASC) stands at the forefront of blockchain innovation, enabling a wide range of decentralized applications and coins that promise to transform industries. From Manufacturing to Real Estate, and beyond, CRATD2C is pioneering solutions that enhance transparency, efficiency, and security across sectors.

Join us in embracing the future of technology with CratD2C Decentralized Autonomous Smart Chain (DASC) at the Universal Tech Expo. Let’s explore how they’re redefining our digital world.
`,
    descriptionMaxLength: 360,
    link: 'https://www.universal-tech-expo.com/',
    image: UniversalTechExpoPhilippinesEvent,
    booth: 'J2',
    circlePlacement: 'top-right',
  },
];
