import { useEffect, useRef, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { COLOR_SECONDARY, H1 } from 'shared';

import { intro, list } from './constants';
import { AmbassadorialMenu, AmbassadorialSystemSection } from './ui';

export const AmbassadorialSystem = () => {
  const [activeSection, setActiveSection] = useState<string>(list[0].id);
  const sections = useRef<NodeListOf<HTMLElement>>();

  const handleScroll = () => {
    const pageYScroll = window.scrollY;
    let currentActiveSection: string | null = null;

    sections.current?.forEach((section) => {
      const sectionOffsetTop = section.offsetTop - 10;
      const sectionHeight = section.offsetHeight;

      if (pageYScroll >= sectionOffsetTop && pageYScroll < sectionOffsetTop + sectionHeight + 98) {
        currentActiveSection = section.id;
      }
    });

    if (currentActiveSection) {
      setActiveSection(currentActiveSection);
    }
  };

  useEffect(() => {
    sections.current = document.querySelectorAll('[data-section]');
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <H1 text={intro.title} highlightedWords={[0]} />
      <Grid item container xs={12} mt={{ xs: 4, md: 12 }} mb={{ xs: 5, md: 15 }}>
        <Grid item xs={12} md={4} display={{ xs: 'none', md: 'block' }}>
          <AmbassadorialMenu activeSection={activeSection} />
        </Grid>
        <Grid item container xs={12} md={8}>
          <Stack spacing={{ xs: 4, md: 12 }}>
            <Stack spacing={{ xs: 3, md: 4 }}>
              <Typography variant="h4" className="summary" maxWidth={776}>
                {intro.paragraph}
              </Typography>
              <Typography variant="body2" color={COLOR_SECONDARY} maxWidth={607}>
                {intro.subtitle}
              </Typography>
            </Stack>
            <AmbassadorialMenu activeSection={activeSection} isMobile />
            {list.map((item) => (
              <AmbassadorialSystemSection key={item.id} {...item} />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
