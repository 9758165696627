import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Card, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { userModel } from 'entities/user';
import { COLOR_ACCENT, fromDecimals, getToastMessage, Icons, useShallowSelector } from 'shared';

import { ReferalTip } from '../referal-tip';

const constructRefLink = (address: string) => {
  return `${window.location.origin}/${address}`;
};

export const ReferralBlock = () => {
  const { userAddress, isAuthenticated } = useShallowSelector(userModel.selectors.selectUserInfo);
  const { referralReceived, totalSpend } = useShallowSelector(userModel.selectors.selectUserCrowdsaleInfo);
  const link = constructRefLink(userAddress);

  const handleCopyReferal = () => {
    getToastMessage('success', 'Referral link coppied');
  };

  return (
    <Card sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      {isAuthenticated ? (
        <>
          <TextField
            value={constructRefLink(userAddress)}
            InputProps={{
              readOnly: true,
              inputProps: { className: 'secondary' },
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboard onCopy={handleCopyReferal} text={link}>
                    <IconButton className="transparent">
                      <Icons.Copy
                        sx={{
                          color: COLOR_ACCENT,
                        }}
                      />
                    </IconButton>
                  </CopyToClipboard>
                </InputAdornment>
              ),
            }}
            label={
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="inherit" className="secondary">
                  Your referral link
                </Typography>
                <ReferalTip />
              </Stack>
            }
            sx={{
              mb: 1,
            }}
          />{' '}
          <Typography variant="inherit" className="secondary" sx={{ lineHeight: 'normal' }}>
            Referral rewards earned:{' '}
            <Typography component="span" variant="inherit">
              {fromDecimals(referralReceived)} USDT
            </Typography>
          </Typography>
        </>
      ) : (
        <Typography className="secondary">You will earn 10% in USDT from all purchases of your referrals.</Typography>
      )}
    </Card>
  );
};
