import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface CratWidgetModalPayload {
  data?: null;
  type: ModalType.CratWidget;
  isOpen?: boolean;
}

export const CratWidgetModal = React.forwardRef<
  HTMLElement,
  Pick<CratWidgetModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="Widget" icon={<Icons.Widget />}>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        Widget is a quick solution to integrate cryptocurrency into our daily business transactions.
      </Typography>
      <br />
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        It generates a Checkout page and automatically redirects users to the payment page where merchant checkout
        integration is enabled. Users can complete all THE PAYMENT PROCESSES and steps IN LESS THAN 2MINS:
      </Typography>
      <br />
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit" fontWeight={700}>
        THE PRICE POINTS ARE CONFIGURED VIA THE CRATD2C SMART CHAIN MECHANISM.
      </Typography>
    </ModalSidebar>
  );
});
