import React from 'react';
import { Box } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface TrustLedgerModalPayload {
  data?: null;
  type: ModalType.TrustLedger;
  isOpen?: boolean;
}

export const TrustLedgerModal = React.forwardRef<
  HTMLElement,
  Pick<TrustLedgerModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Distributed Trust Ledger (DTL)"
      icon={<Icons.Todo />}
    >
      <Box component="p">
        CratD2C introduces a completely decentralized blockchain technology that fosters a direct-to-consumer (D2C)
        economy with peer-to-peer transactions. Manufacturers can directly engage with their target audience via the
        CratD2C Trading Portal and utilize the Distributed Trust Ledger (DTL) to verify transactions. This ledger allows
        for the quick retrieval of transaction histories up to many years old using a unique transaction hash.
      </Box>
      <br />
      <Box component="p">
        By leveraging CratD2C&apos;s blockchain capabilities, the platform significantly reduces the occurrence of
        multiple profit add-on and added costs on goods and services. Traditional e-commerce distribution models often
        involve several layers of retailers, sometimes up to five different intermediaries, before products reach
        consumers, leading to marked-up prices. CratD2C efficiently eliminates this vulnerability, rectifying price
        inflations and simplifying the path products take to reach end-users.
      </Box>
    </ModalSidebar>
  );
});
