import { Stack, Typography } from '@mui/material';
import { COLOR_SECONDARY, COLOR_STROKE } from 'shared';

import { table } from '../constants';

export const EcosystemValuationTableMobile = () => (
  <Stack spacing={2} width="100%">
    {table.rows.map((data, index) => (
      <Stack
        key={`${index + 1}`}
        spacing={2}
        p={(theme) => theme.spacing(2, 1)}
        borderTop={`1px solid ${COLOR_STROKE}`}
      >
        {data.map((value, idx) => (
          <Stack key={value} direction="row" justifyContent="space-between" alignItems="center">
            <Typography color={COLOR_SECONDARY}>{table.head[idx]}</Typography>
            <Typography>{table.rows[index][idx]}</Typography>
          </Stack>
        ))}
      </Stack>
    ))}
    <Stack
      direction="row"
      padding={(theme) => theme.spacing(1)}
      justifyContent="space-between"
      alignItems="center"
      borderTop={`1px solid ${COLOR_STROKE}`}
    >
      {table.total.map(
        (value) =>
          value && (
            <Typography key={value} className="semi-bold">
              {value}
            </Typography>
          ),
      )}
    </Stack>
  </Stack>
);
