import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const People: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 32 24"
    sx={{
      width: '32px',
      height: '34px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <circle
      cx="10.1893"
      cy="6.35096"
      r="5.06824"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="23.2594"
      cy="7.78875"
      rx="3.63054"
      ry="3.63054"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.47656 23.037V21.5601C1.47656 18.3652 4.06587 15.7759 7.26075 15.7759H13.119C16.3139 15.7759 18.9032 18.3652 18.9032 21.5601V23.037"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2598 15.7759H24.8601C28.055 15.7759 30.6443 18.3652 30.6443 21.5601V23.037"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
