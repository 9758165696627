import { giveawayModel } from 'entities/giveaway';
import { createAppAsyncThunk, logger } from 'shared';

import { actionTypes } from '../action-types';
import { AnswerQuizReq, giveawayApi } from '../api';

export const answerQuiz = createAppAsyncThunk(
  actionTypes.ANSWER_QUIZ,
  async ({ answer }: AnswerQuizReq, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { isSuccessfullyAnswer },
      } = await giveawayApi.answerQuiz({ data: { answer } });

      dispatch(
        giveawayModel.actions.updateGiveawayState({
          userQuizAnswer: answer,
          isSuccessfullAnswer: isSuccessfullyAnswer,
        }),
      );

      dispatch(giveawayModel.thunks.getUserLeaderboardStats());

      return null;
    } catch (err) {
      logger('answerQuiz', err);
      return rejectWithValue(err);
    }
  },
);
