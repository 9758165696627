import { connectorsForWallets, Wallet } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  metaMaskWallet,
  okxWallet,
  rainbowWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

import { chains } from './chains';

const wallets: Wallet[] = [
  rainbowWallet({ chains, projectId: process.env.REACT_APP_WC_PROJECT_ID as string }),
  coinbaseWallet({ chains, appName: 'CratD2C' }),
  metaMaskWallet({ chains, projectId: process.env.REACT_APP_WC_PROJECT_ID as string }),
  walletConnectWallet({ chains, projectId: process.env.REACT_APP_WC_PROJECT_ID as string }),
  okxWallet({ chains, projectId: process.env.REACT_APP_WC_PROJECT_ID as string }),
];

export const connectors = connectorsForWallets([{ groupName: 'Main wallets', wallets }]);
