import { shortenPhrase } from 'shared';
import { WalletProviders } from 'shared/config';
import { Chain } from 'wagmi';

export const notifyText = {
  wallet: {
    connect: {
      success: (userWalletAddress: string, isShortenPhrase = true) =>
        `Wallet connected: ${isShortenPhrase ? shortenPhrase(userWalletAddress, 6, 4) : userWalletAddress}`,
      info: {
        noExtension: (provider: WalletProviders) => `${provider} extension isn't installed or unlocked`,
        pleaseLogin: 'Please sign login message at MetaMask',
      },
      error: {
        wrongNetwork: (chain: Chain) =>
          `You changed to wrong network. Please choose ${chain.name} ${chain.testnet ? 'Testnet' : 'Mainnet'}`,
        defaultError: 'Something went wrong',
      },
    },
    disconnect: {
      info: 'Disconnect from wallet',
    },
  },

  approve: {
    success: 'Successful approve',
    error: 'Error approve',
  },

  claimRewards: {
    success: 'Successfully claimed reward',
    error: 'Unable to claim reward',
  },
};

export const NATIVE_CURRENCY_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
