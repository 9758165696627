import { ComponentsOverrides, Theme } from '@mui/material';

import { BORDER_RADIUS_M } from '../common';

export const getMuiMenuOverrides = (theme: Theme): ComponentsOverrides['MuiMenu'] => ({
  paper: {
    background: theme.themeColors.colorsMenuBackground,
    color: theme.themeColors.mainColor,
    borderRadius: BORDER_RADIUS_M,
    boxShadow: 'none',
  },

  list: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
});
