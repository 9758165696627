import { Box, Typography, TypographyProps } from '@mui/material';
import { Calculator, CalculatorProps } from 'features/calculator';
import { PageParagraph, Section } from 'shared';

interface CalculatorBlockProps {
  bottomText: string;
  blockTitle?: string;
  bottomTextProps?: TypographyProps;
}

export const CalculatorBlock = ({
  bottomText,
  blockTitle = 'Calculator',
  bottomTextProps,
  ...calcProps
}: CalculatorBlockProps & CalculatorProps) => {
  return (
    <Section>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          mt: { xs: 2, sm: 3.5 },
        }}
      >
        <PageParagraph
          mb={6}
          sx={{ mt: 0 }}
          pProps={{
            className: 'inherit secondary',
            sx: { gridColumn: { xs: 'span 12', md: '1 / 13' } },
          }}
        >
          {blockTitle}
        </PageParagraph>
        <Calculator
          sx={{
            gridColumn: { xs: 'span 12', md: '5 / 13' },
          }}
          {...calcProps}
        />
        <Typography
          variant="h6"
          {...bottomTextProps}
          sx={{
            mt: 5,
            gridColumn: { xs: 'span 12', md: '5 / 13' },
            ...bottomTextProps?.sx,
          }}
        >
          {bottomText}
        </Typography>
      </Box>
    </Section>
  );
};
