import { crowdsaleModel } from 'entities/crowdsale';
import { giveawayModel } from 'entities/giveaway';
import { modalModel } from 'entities/modal';
import { userModel } from 'entities/user';
import * as connectModalModel from 'features/connect-wallet/model';
import { uiReducer } from 'shared';

export default {
  ui: uiReducer,
  modal: modalModel.reducer,
  connectModal: connectModalModel.reducer,
  user: userModel.reducer,
  crowdsale: crowdsaleModel.reducer,
  giveaway: giveawayModel.reducer,
};
