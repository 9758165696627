import { useEffect, useState } from 'react';
import { DateLike, Nullable } from 'shared/types';

const MILLISECONDS = 1;
const SECONDS = 1000 * MILLISECONDS;
const MINUTE = 60 * SECONDS;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
/**
 * Calculates time left from endTime to now
 * @param endTime
 * @returns {
 *     days:number;
 *     hours:number;
 *     minutes:number;
 *     seconds:number;
 * } | null
 */

export interface ITimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const useTimeLeft = (endTime: DateLike): Nullable<ITimeLeft> => {
  const calculateTimeLeft = (): Nullable<ITimeLeft> => {
    const dateEndTime = new Date(endTime);
    const difference = +dateEndTime - Date.now();

    if (difference > 0) {
      return {
        days: Math.floor(difference / DAY),
        hours: Math.floor((difference % DAY) / HOUR),
        minutes: Math.floor((difference % HOUR) / MINUTE),
        seconds: Math.floor((difference % MINUTE) / 1000),
      };
    }
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  };

  const [timeLeft, setTimeLeft] = useState<Nullable<ITimeLeft>>(calculateTimeLeft());

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  });

  return timeLeft;
};
