import { giveawayModel } from 'entities/giveaway';
import { createAppAsyncThunk, logger } from 'shared';

import { actionTypes } from '../action-types';
import { giveawayApi } from '../api';

export const getLeaderBoard = createAppAsyncThunk(
  actionTypes.GET_LEADERBOARD,
  async ({ pageSize, pageNumber }: { pageSize: number; pageNumber: number }, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { results, next },
      } = await giveawayApi.getLeaderboard({ pageNumber, pageSize });

      dispatch(
        giveawayModel.actions.updateGiveawayState({
          leaderboard: { leaderboardData: results, isLastPage: !next },
        }),
      );

      return null;
    } catch (err) {
      logger('getLeaderBoard', err);
      return rejectWithValue(err);
    }
  },
);
