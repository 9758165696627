import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Check: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    sx={{
      fill: 'none',
      width: '24px',
      height: '24px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M4 12.6111L8.92308 17.5L20 6.5"
      stroke="#B4EB56"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
