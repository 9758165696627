import { ReactElement } from 'react';

export enum Modules {
  smartchain = 'smart-chain',
  tokenomics = 'tokenomics',
  intellectualProperty = 'intellectual-property',
}

export type Route = {
  dirName: string;
  id: number;
  root: {
    title: string;
    path: string;
    module?: Modules;
    isProtected?: boolean;
  };
  children?: Routes;
};

export type Routes = {
  [key: string]: Route;
};

export type CompoenentsWithProps = {
  [key: string]: ReactElement;
};
