import { alpha } from '@mui/material';

import {
  COLOR_ACCENT,
  COLOR_ACCENT_DARK,
  COLOR_BG,
  COLOR_BLACK,
  COLOR_SECONDARY,
  COLOR_STROKE,
} from '../colors.constants';

export const colorsIconButton = {
  colorIconButtonContainedBackground: COLOR_ACCENT,
  colorIconButtonContainedDisabledBackground: alpha(COLOR_ACCENT, 0.5),
  colorIconButtonContainedText: COLOR_BLACK,
  colorIconButtonContainedHoverBackground: COLOR_ACCENT_DARK,
  colorIconButtonContainedFocusBorder: COLOR_SECONDARY,

  colorIconButtonOutlinedBackground: COLOR_BG,
  colorIconButtonOutlinedText: COLOR_ACCENT,
  colorIconButtonOutlinedBorder: COLOR_STROKE,
  colorIconButtonOutlinedHoverBorder: COLOR_ACCENT,
};
