import { ComponentsOverrides, Theme } from '@mui/material';

import { COLOR_BG_ALPHA_8 } from '../colors';
import { BORDER_RADIUS_L } from '../common';

export const getMuiCardOverrides = (theme: Theme): ComponentsOverrides['MuiCard'] => ({
  root: {
    borderRadius: BORDER_RADIUS_L,
    border: '1px solid',
    borderColor: theme.themeColors.colorsCardBorder,
    backgroundColor: theme.themeColors.colorsCardBackground,
    padding: 16,
    '&.blur': {
      background: COLOR_BG_ALPHA_8,
      backdropFilter: 'blur(80px)',
    },
    '&.secondary': {
      backgroundColor: theme.themeColors.colorsCardBackgroundSecondary,
      border: 'none',
    },
  },
});
