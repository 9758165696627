import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { CssBaseline } from '@mui/material';
import { ModalContainer } from 'entities/modal';
import { RouteManager } from 'pages';
import { Layout, ScrollToTop } from 'widgets';

import {
  withPersistor,
  withProviders,
  withRouter,
  withStore,
  withTheme,
  WithWagmi,
  WithWagmiConnect,
} from './providers';

import 'react-toastify/dist/ReactToastify.css';
import './appStyles/index.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@splidejs/react-splide/dist/css/themes/splide-default.min.css';
import '@rainbow-me/rainbowkit/styles.css';

const App = () => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'; // scroll to top after refresh
  }, []);

  return (
    <>
      <Layout>
        <RouteManager />
      </Layout>
      <ScrollToTop />
      <ModalContainer />
      <CssBaseline />
      <ToastContainer autoClose={10000} hideProgressBar position="bottom-right" closeButton />
    </>
  );
};

export default withProviders(withRouter, withStore, withPersistor, withTheme, WithWagmi, WithWagmiConnect)(App);
