import { Box, Card, Grid, Stack, styled, Typography } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { useBreakpoints } from 'shared';

import { news, partners } from './constants';

const StyledCard = styled(Card)(({ theme }) => ({
  height: 72,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
}));

export const FeaturedIn = () => {
  const { isDownSmLayout, isDownMdLayout } = useBreakpoints();
  return (
    <Box component="section" mt={{ xs: 6, sm: 12 }}>
      <Stack gap={6}>
        <Typography variant="h4" className="secondary">
          Featured in
        </Typography>
        <Box overflow="hidden" position="relative" height={60}>
          <Splide
            options={{
              gap: 50,
              pagination: false,
              omitEnd: true,
              type: 'loop',
              arrows: false,
              drag: false,
              focus: 'center',
              perPage: isDownSmLayout ? 3 : 6,
              height: 60,
              autoScroll: {
                speed: 1,
              },
              autoWidth: true,
            }}
            extensions={{ AutoScroll }}
          >
            {news.map(({ image, id, maxHeight }) => (
              <SplideSlide key={id}>
                <Stack justifyContent="center" height="100%">
                  <Box component="img" src={image} sx={{ height: 'fit-content', maxHeight: maxHeight ?? 'unset' }} />
                </Stack>
              </SplideSlide>
            ))}
          </Splide>
          <Box
            sx={{
              background: 'linear-gradient(90deg, #0F0A13 0.56%, rgba(15, 10, 19, 0) 52.97%, #0F0A13 99.44%)',
              position: 'absolute',
              width: '100%',
              height: 60,
              top: 0,
              zIndex: 2,
            }}
          />
        </Box>
      </Stack>
      <Grid container alignItems="end" columnSpacing={1} rowSpacing={{ xs: 1, md: 6 }} mt={{ xs: 5, md: 9.5 }}>
        {partners.map(({ id, subtitle, image, maxHeight }) => (
          <Grid item container key={id} xs={6} md={4} lg={2} rowGap={1}>
            {subtitle && !isDownMdLayout && <Typography className="secondary">{subtitle}</Typography>}
            <StyledCard>
              <Box
                component="img"
                src={image}
                sx={{ width: '100%', height: '100%', maxHeight, objectFit: 'contain' }}
              />
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
