import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Fire: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 128 128"
    sx={{
      width: '22px',
      height: '22px',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M35.56 40.73C34.99 46.81 34.59 57.57 38.18 62.15C38.18 62.15 36.49 50.33 51.64 35.5C57.74 29.53 59.15 21.41 57.02 15.32C55.81 11.87 53.6 9.02002 51.68 7.03002C50.56 5.86002 51.42 3.93002 53.05 4.00002C62.91 4.44002 78.89 7.18002 85.68 24.22C88.66 31.7 88.88 39.43 87.46 47.29C86.56 52.31 83.36 63.47 90.66 64.84C95.87 65.82 98.39 61.68 99.52 58.7C99.99 57.46 101.62 57.15 102.5 58.14C111.3 68.15 112.05 79.94 110.23 90.09C106.71 109.71 86.84 123.99 67.1 123.99C42.44 123.99 22.81 109.88 17.72 84.34C15.67 74.03 16.71 53.63 32.61 39.23C33.79 38.15 35.72 39.11 35.56 40.73Z"
      fill="url(#paint0_radial_2568_3688)"
    />
    <path
      d="M76.11 77.42C67.02 65.72 71.09 52.37 73.32 47.05C73.62 46.35 72.82 45.69 72.19 46.12C68.28 48.78 60.27 55.04 56.54 63.85C51.49 75.76 51.85 81.59 54.84 88.71C56.64 93 54.55 93.91 53.5 94.07C52.48 94.23 51.54 93.55 50.79 92.84C48.6324 90.7686 47.0949 88.1368 46.35 85.24C46.19 84.62 45.38 84.45 45.01 84.96C42.21 88.83 40.76 95.04 40.69 99.43C40.47 113 51.68 124 65.24 124C82.33 124 94.78 105.1 84.96 89.3C82.11 84.7 79.43 81.69 76.11 77.42Z"
      fill="url(#paint1_radial_2568_3688)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2568_3688"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(62.2158 124.301) rotate(-179.751) scale(70.5877 115.82)"
      >
        <stop offset="0.314" stopColor="#FF9800" />
        <stop offset="0.662" stopColor="#FF6D00" />
        <stop offset="0.972" stopColor="#F44336" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_2568_3688"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(66.18 54.0584) rotate(90.5787) scale(73.8564 55.5825)"
      >
        <stop offset="0.214" stopColor="#FFF176" />
        <stop offset="0.328" stopColor="#FFF27D" />
        <stop offset="0.487" stopColor="#FFF48F" />
        <stop offset="0.672" stopColor="#FFF7AD" />
        <stop offset="0.793" stopColor="#FFF9C4" />
        <stop offset="0.822" stopColor="#FFF8BD" stopOpacity="0.804" />
        <stop offset="0.863" stopColor="#FFF6AB" stopOpacity="0.529" />
        <stop offset="0.91" stopColor="#FFF38D" stopOpacity="0.209" />
        <stop offset="0.941" stopColor="#FFF176" stopOpacity="0" />
      </radialGradient>
    </defs>
  </SvgIcon>
);
