import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Polygons: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 183 72"
    sx={{
      width: '183px',
      height: '72px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path
      d="M79.5708 32.3959C80.8498 34.6284 80.8498 37.3716 79.5708 39.6041L63.5291 67.604C62.2372 69.859 59.8372 71.25 57.2384 71.25L25.2616 71.25C22.6628 71.25 20.2628 69.859 18.9709 67.6041L2.92921 39.6041C1.65018 37.3716 1.65018 34.6284 2.92921 32.3959L18.9709 4.39594C20.2628 2.14099 22.6628 0.749997 25.2616 0.749997L57.2384 0.749999C59.8372 0.749999 62.2372 2.14099 63.5291 4.39594L79.5708 32.3959Z"
      stroke="#B4EB56"
      strokeWidth="1.5"
    />
    <path
      d="M129.473 32.3959C130.752 34.6284 130.752 37.3716 129.473 39.6041L113.431 67.604C112.14 69.859 109.74 71.25 107.141 71.25L75.1639 71.25C72.5651 71.25 70.1651 69.859 68.8732 67.6041L52.8315 39.6041C51.5525 37.3716 51.5525 34.6284 52.8315 32.3959L68.8732 4.39594C70.1651 2.14099 72.5651 0.749997 75.1639 0.749997L107.141 0.749999C109.74 0.749999 112.14 2.14099 113.431 4.39594L129.473 32.3959Z"
      stroke="#B4EB56"
      strokeWidth="1.5"
    />
    <path
      d="M179.375 32.3959C180.655 34.6284 180.655 37.3716 179.375 39.6041L163.334 67.604C162.042 69.859 159.642 71.25 157.043 71.25L125.066 71.25C122.467 71.25 120.067 69.859 118.776 67.6041L102.734 39.6041C101.455 37.3716 101.455 34.6284 102.734 32.3959L118.776 4.39594C120.067 2.14099 122.467 0.749997 125.066 0.749997L157.043 0.749999C159.642 0.749999 162.042 2.14099 163.334 4.39594L179.375 32.3959Z"
      stroke="#B4EB56"
      strokeWidth="1.5"
    />
  </SvgIcon>
);
