import { useId } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, styled, TextField, Typography } from '@mui/material';
import { BORDER_RADIUS_M, COLOR_BG_BLACK_5, COLOR_RED } from 'shared';

import { FORM_FIELDS, schema } from '../constants';
import { Inputs } from '../types';

import { useModalState } from './provider';

const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root, & .MuiInputBase-input.Mui-focused': {
    height: 56,
    borderRadius: BORDER_RADIUS_M,
    background: `${COLOR_BG_BLACK_5} !important`,
  },
}));

export const FormTab = () => {
  const { onSubmit } = useModalState();
  const { handleSubmit, register, formState } = useForm<Inputs>({ resolver: yupResolver(schema), mode: 'onTouched' });
  const baseId = useId();

  return (
    <Stack component="form" spacing={2.5} onSubmit={handleSubmit(onSubmit)} marginTop={3}>
      {FORM_FIELDS.map(({ id, label, isRequired, placeholder }) => (
        <Stack key={id} spacing={0.5}>
          <Typography component="label" htmlFor={`${baseId}-${id}`}>
            {`${label}${isRequired ? '*' : ''}`}
          </Typography>
          <InputField
            id={`${baseId}-${id}`}
            placeholder={placeholder}
            error={!!formState.errors[id]?.message}
            inputProps={{ 'aria-invalid': !!formState.errors[id]?.message }}
            {...register(id)}
          />
          {!!formState.errors[id]?.message && (
            <Typography variant="subtitle1" color={COLOR_RED}>
              {formState.errors[id]?.message?.toString()}
            </Typography>
          )}
        </Stack>
      ))}
      <Button type="submit" disabled={!formState.isValid || formState.isSubmitting}>
        Submit
      </Button>
    </Stack>
  );
};
