import { Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { comparativeAnalysisTitles, mapTitleToIcon } from '../../model/constants';

export const LEFT_SPACE_DESKTOP = 4;
export const LEFT_SPACE_XS = LEFT_SPACE_DESKTOP - 1;

export const ComparisonTableHead = () => {
  return (
    <Grid container item xs={12} component="thead">
      <Grid item component="tr" xs={LEFT_SPACE_XS} md={LEFT_SPACE_DESKTOP} sx={{ visibility: 'hidden' }} />

      {comparativeAnalysisTitles.map((option) => {
        const Icon = mapTitleToIcon[option];
        return (
          <Grid
            item
            component="tr"
            key={option}
            xs={(12 - LEFT_SPACE_XS) / comparativeAnalysisTitles.length}
            md={(12 - LEFT_SPACE_DESKTOP) / comparativeAnalysisTitles.length}
          >
            <Stack direction="row" component="td" spacing={1} sx={{ alignItems: 'center' }}>
              <Icon sx={{ width: 24, height: 24 }} />
              <Typography variant="h6" className="semi-bold secondary">
                {option}
              </Typography>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};
