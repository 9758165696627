import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Stack } from '@mui/material';
import { useSmoothTopScroll } from 'shared';
import { FeedbackModal } from 'widgets';

import { WithLoader } from '../providers';

import { Footer } from './footer';
import { Header } from './header';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  useSmoothTopScroll(pathname);
  return (
    <Stack flexGrow={1}>
      <WithLoader>
        <FeedbackModal />
        <Header />
        <Container
          sx={{
            pt: 2,
            flex: 1,
          }}
        >
          {children}
        </Container>
        <Footer />
      </WithLoader>
    </Stack>
  );
};
