import React from 'react';
import { Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface PosModalPayload {
  data?: null;
  type: ModalType.POS;
  isOpen?: boolean;
}

export const PosModal = React.forwardRef<
  HTMLElement,
  Pick<PosModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="POS" icon={<Icons.Pos />}>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        Point of sale (POS), a critical integral of a point of purchase for any business merchant, refers to where a
        customer executes the payment for goods or services.
      </Typography>
      <br />
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        However, the cryptocurrency market has been unable to penetrate this mainstream of the business world
        effectively.{' '}
      </Typography>
      <br />
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        CratD2C Smart Chain makes this vast task achievable by simplifying the Easy-Point-Of-Purchase
        Mechanism.eCommerce Merchants worldwide can ultimately receive cryptocurrency as payment at the point of
        purchase, enabling them to have a Better Inventory, Simple Invoicing, Faster Payment, Better Customer
        Experience, and Improved brand Image.
      </Typography>
      <br />
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        In addition, CRAT Coin Holders and cryptocurrency users can work in any retail store and enjoy the simplicity of
        a secure and efficient payment system.
      </Typography>
    </ModalSidebar>
  );
});
