import { ReactNode } from 'react';
import { BoxProps, Typography, TypographyProps } from '@mui/material';
import { Box } from '@mui/system';
import { Section } from 'shared/ui/section';

import { H1 } from '../../h1';
import { PageParagraph } from '../../page-paragraph';

interface StakingRolesIntro {
  mainTitle: string;
  subTitle?: string | ReactNode;
  subTitleProps?: TypographyProps;
  paragraph?: string | ReactNode;
  paragraphSubtitle?: string | ReactNode;
  paragraphProps?: TypographyProps;
  paragraphSubtitleProps?: TypographyProps;
  highlightedTitleWords?: number[];
}

export const Intro = ({
  mainTitle,
  subTitle,
  subTitleProps,
  paragraph,
  paragraphSubtitle,
  paragraphProps,
  paragraphSubtitleProps,
  highlightedTitleWords = [0],
  children,
  ...wrapperProps
}: StakingRolesIntro & BoxProps) => {
  return (
    <Section {...wrapperProps}>
      <H1 text={mainTitle} highlightedWords={highlightedTitleWords} />
      {subTitle && (
        <Typography
          variant="h4"
          className="summary sm secondary regular"
          mt={{ xs: 1.25, md: 1.75 }}
          mb={{ xs: 2.5, md: 0 }}
          {...subTitleProps}
        >
          {subTitle}
        </Typography>
      )}

      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)' }}>{children}</Box>
      {!!paragraph && <PageParagraph pProps={paragraphProps}>{paragraph}</PageParagraph>}
      {!!paragraphSubtitle && (
        <PageParagraph
          pProps={{
            variant: 'body2',
            className: 'secondary',
            ...paragraphSubtitleProps,
            sx: {
              gridColumn: { xs: 'span 12', md: '7 / 13' },
              ...paragraphSubtitleProps?.sx,
            },
          }}
        >
          {paragraphSubtitle}
        </PageParagraph>
      )}
    </Section>
  );
};
