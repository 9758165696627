import { Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Intro, ListItem, Section } from 'shared';

import { BOTTOM_TEXT, intro, list } from './constants';

export const UseCases = () => {
  return (
    <>
      <Intro
        mainTitle="Use Cases"
        paragraph={intro.paragraph}
        paragraphProps={{ letterSpacing: '-0.4px !important' }}
        highlightedTitleWords={[0, 1]}
      />
      <Section mb={{ xs: 6, md: 8.25 }}>
        <Grid container rowSpacing={{ xs: 3, md: 0 }}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" className="secondary">
              Cases
            </Typography>
          </Grid>

          <Grid item container xs={12} md={8} rowSpacing={4.75}>
            <Grid item xs={12}>
              <Stack gap={6}>
                {list.map((item, i) => {
                  return (
                    <ListItem
                      key={item.title}
                      number={i + 1}
                      title={item.title}
                      description={item.description}
                      spacing={{ xs: 2 }}
                      childrenOpts={{
                        titleGridProps: { xs: 12, md: 3, lg: 2.9 },
                        descriptionGridProps: { xs: 12, md: 9 },
                      }}
                    />
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              maxWidth: 640,
              ml: { xs: 0, md: 1.5 },
              gridColumn: { xs: 'span 12', md: '7 / 13' },
            }}
          >
            {BOTTOM_TEXT}
          </Typography>
        </Box>
      </Section>
    </>
  );
};
