import React from 'react';
import { Box } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { FontWeights, Icons, ModalSidebar } from 'shared';

export interface StakingModalPayload {
  data?: null;
  type: ModalType.Staking;
  isOpen?: boolean;
}

export const StakingModal = React.forwardRef<
  HTMLElement,
  Pick<StakingModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar isOpen={isOpen} ref={ref} onClose={onClose} title="Staking" icon={<Icons.Staking />}>
      <Box component="p" fontWeight={FontWeights.SemiBold}>
        The staking mechanism within the CratD2C Decentralized Autonomous Smart Chain (DASC) is a fundamental and
        innovative feature that empowers participants to actively engage with the network, contribute to its security,
        and earn rewards in return. The staking process involves participants locking up a certain amount of CRAT Coins
        in the platform&apos;s staking pool with up to 20% APR, enabling them to play a role in the network&apos;s
        consensus and governance.
      </Box>
    </ModalSidebar>
  );
});
