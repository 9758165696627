import { Icons } from 'shared';

export enum ComparativeAnalysisTitle {
  CratD2C = 'CratD2C',
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Cardano = 'Cardano',
  Solana = 'Solana',
}

export const comparativeAnalysisTitles = [
  ComparativeAnalysisTitle.CratD2C,
  ComparativeAnalysisTitle.Bitcoin,
  ComparativeAnalysisTitle.Ethereum,
  ComparativeAnalysisTitle.Cardano,
  ComparativeAnalysisTitle.Solana,
];

export const mapTitleToIcon = {
  [ComparativeAnalysisTitle.CratD2C]: Icons.CratChain,
  [ComparativeAnalysisTitle.Bitcoin]: Icons.Bitcoin,
  [ComparativeAnalysisTitle.Ethereum]: Icons.Eth,
  [ComparativeAnalysisTitle.Cardano]: Icons.Cardano,
  [ComparativeAnalysisTitle.Solana]: Icons.Solana,
};

export const comparativeAnalysisData: [string, string, string, string, string, string][] = [
  [
    'Coin Distribution Mechanism',
    'Biennial 8-Layer Zig-Zag Emission',
    'Linear Emission',
    'Linear Emission',
    'Fixed Total Supply',
    'Varying Emission',
  ],
  ['Consensus Mechanism', 'DPoS', 'PoW', 'PoW/PoS', 'PoS', 'PoS'],
  ['Max Supply', '300M', '21M', 'No Max Supply', '45B', 'No Max Supply'],
  ['Scalability', 'High', 'Low', 'Moderate', 'High', 'High'],
  ['Transaction Speed', '0.5 - 3 seconds', 'Slow', 'Moderate', 'Moderate', 'Moderate'],
  ['Network Security', 'High', 'High', 'High', 'High', 'High'],
  ['Governance Model', 'DAO', 'Decentralized', 'Centralized', 'Centralized', 'Centralized'],
  [
    'Reward Structure',
    'Block Rewards, Tiered StakePool APR+,EcoIP Royalties',
    'Block Rewards',
    'Block Rewards',
    'Staking Rewards',
    'Staking Rewards',
  ],
  ['Community Involvement', 'Extensive', 'Moderate', 'Moderate', 'High', 'High'],
  [
    'Coin Use Cases',
    'Diverse dApps',
    'Limited Use Cases',
    'Diverse Use Cases',
    'Diverse Use Cases',
    'Diverse Use Cases',
  ],
  ['Privacy Features', 'Enhanced', 'Limited', 'Limited', 'Limited', 'Limited'],
  ['Long-term Sustainability', 'Strong', 'Strong', 'Strong', 'Strong', 'Strong'],
];
