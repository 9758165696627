import { alpha } from '@mui/material';

import {
  COLOR_ACCENT,
  COLOR_ACCENT_DARK,
  COLOR_BLACK,
  COLOR_GRAY_2,
  COLOR_GRAY_LIGHT,
  COLOR_SECONDARY,
  COLOR_STROKE,
  COLOR_WH,
} from '../colors.constants';

export const colorsButton = {
  colorButtonContainedBackground: COLOR_ACCENT,
  colorButtonContainedDisabledBackground: alpha(COLOR_ACCENT, 0.5),
  colorButtonContainedText: COLOR_BLACK,
  colorButtonContainedHoverBackground: COLOR_ACCENT_DARK,
  colorButtonContainedFocusBorder: COLOR_SECONDARY,

  colorButtonContainedSecondaryText: COLOR_WH,
  colorButtonContainedSecondaryBackground: COLOR_GRAY_2,
  colorButtonContainedSecondaryHoverBorder: COLOR_ACCENT,
  colorButtonContainedSecondaryFocusBorder: COLOR_STROKE,

  colorButtonOutlinedBackground: 'transparent',
  colorButtonOutlinedText: COLOR_GRAY_LIGHT,
  colorButtonOutlinedHoverBorder: COLOR_ACCENT,
  colorButtonOutlinedFocusBorder: COLOR_SECONDARY,
  colorButtonOutlinedBorder: COLOR_STROKE,
  colorButtonOutlinedDisabledColor: COLOR_SECONDARY,
  colorButtonOutlinedDisabledBorder: COLOR_STROKE,
  colorButtonOutlinedSuccessText: COLOR_ACCENT,
  colorButtonOutlinedSuccessBorder: COLOR_ACCENT,

  colorButtonTextBackground: 'transparent',
  colorButtonText: COLOR_GRAY_LIGHT,
  colorButtonTextHover: COLOR_ACCENT,
  colorButtonTextBorder: COLOR_STROKE,
};
