import { Box, Stack, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { userModel } from 'entities/user';
import { CratLogo, fromDecimals, LinearProgressWithLabel, smartRound, toDecimals, useShallowSelector } from 'shared';

import { getUserTierInfo } from '../../../../lib';
import { BonusTip } from '../bonus-tip';

export const InvestmentsInfo = () => {
  const { isAuthenticated } = useShallowSelector(userModel.selectors.selectIsAuthenticated);
  const { tier, totalSpend, cratBalance, bonusTokensReceived } = useShallowSelector(
    userModel.selectors.selectUserCrowdsaleInfo,
  );

  const spend = smartRound(+fromDecimals(totalSpend), 2);
  const balance = smartRound(+fromDecimals(cratBalance), 2);
  const potentialValue = smartRound(Number(balance) * 0.8, 2);

  const [currentTier, nextTier] = getUserTierInfo(Number(tier));

  const tierProgress = nextTier
    ? new BigNumber(totalSpend)
        .minus(toDecimals(currentTier.investedValue))
        .multipliedBy(100)
        .div(toDecimals(new BigNumber(nextTier.investedValue).minus(currentTier.investedValue).toFixed()))
        .toNumber()
    : 100;

  return (
    <>
      <Stack direction={{ sm: 'row' }} gap={2} justifyContent="space-between">
        <Box>
          <Typography variant="h6" className="secondary">
            Your total investment
          </Typography>
          <Typography variant="h4" className="no-resize" mt={1}>
            ${spend}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" className="secondary">
            Balance CratD2C-Pre
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1} mt={1}>
            <Box
              src={CratLogo}
              component="img"
              sx={{
                width: 30,
              }}
            />
            <Typography variant="h4" className="no-resize">
              {balance}
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Typography variant="h6" className="secondary">
            Potential Listing Value
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1} mt={1}>
            <Typography variant="h4" className="no-resize">
              ${potentialValue}
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Box pt={2}>
        <Stack direction="row" alignItems="center" spacing={1.25}>
          <Typography variant="h6">
            Your level is{' '}
            <Typography component="span" variant="inherit" className="accent">
              {currentTier.bonusPercent}% bonus
            </Typography>
          </Typography>
          {!!isAuthenticated && <BonusTip />}
        </Stack>
        <LinearProgressWithLabel
          label={nextTier ? `${nextTier.bonusPercent}%` : 'Max'}
          value={tierProgress}
          wrapperSx={{
            maxWidth: 340,
          }}
          sx={{
            my: 1.5,
          }}
        />
        {isAuthenticated && (
          <Typography variant="h6" className="secondary">
            Investment bonus earned:{' '}
            <Typography component="span" variant="inherit">
              {fromDecimals(bonusTokensReceived)} CratD2C-Pre
            </Typography>
          </Typography>
        )}
      </Box>
    </>
  );
};
