import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { team } from 'shared/constants';

import { TeamCard } from './team-card';

export const TeamSlider = () => {
  return (
    <Splide
      hasTrack={false}
      options={{
        gap: 20,
        pagination: false,
        type: 'slide',
        arrows: false,
        fixedWidth: 200,
      }}
    >
      <SplideTrack>
        {team.map((teamItem) => (
          <SplideSlide key={teamItem.name}>
            <TeamCard {...teamItem} align="left" />
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  );
};
