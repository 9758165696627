import { userModel } from 'entities/user';
import { createAppAsyncThunk, logger } from 'shared';

import { actionTypes } from '../action-types';
import { userApi } from '../api';

export const getLoginMessage = createAppAsyncThunk(
  actionTypes.GET_LOGIN_MESSAGE,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { message },
      } = await userApi.getLoginMessage();

      return message;
    } catch (error) {
      logger('get_login_message', error);
      dispatch(userModel.actions.disconnectWalletState());
      return rejectWithValue(error);
    }
  },
);
