import { CalculatorBlock } from 'widgets';

import { Benefits, Intro } from './ui';

export const EarnWithCrat = () => {
  return (
    <>
      <Intro />
      <Benefits />
      <CalculatorBlock
        bottomText="In simple terms, holding CRAT Coins can not only potentially make your initial acquisition grow but also give you extra rewards and a share of the platform's success. It's like having both a savings account and stocks in one investment."
        bottomTextProps={{ sx: { gridColumn: { xs: 'span 12', md: '7 / 13' }, letterSpacing: 0 } }}
      />
    </>
  );
};
