import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SuccessCheck: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 100 100"
    sx={{
      width: '100px',
      height: '100px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path d="M31 50.5L45 65L74 35.5" stroke="#B4EB56" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="1" y="1" width="98" height="98" rx="49" stroke="#B4EB56" strokeWidth="2" />
  </SvgIcon>
);
