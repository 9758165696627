import { Box, Typography } from '@mui/material';
import { rows } from 'pages/smart-chain/blockchain-features/ui/features/constants';
import { COLOR_SECONDARY, Section, SplittedRows } from 'shared';

export const Features = () => {
  return (
    <Section>
      <Typography variant="h4" className="accent" mb={4}>
        CratD2C Smart Chain <br /> Features
      </Typography>

      <SplittedRows data={rows} textColor={COLOR_SECONDARY} mb={4.5} />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          mt: { xs: 6, md: 12 },
        }}
      >
        <Typography
          variant="h4"
          className="summary"
          sx={{
            gridColumn: { xs: 'span 13', md: '5 / 13' },
          }}
        >
          The CratD2C Decentralized Autonomous Smart Chain brings these features together to create a versatile and
          transformative ecosystem that addresses the demands of the digital age. With its powerful capabilities,
          CratD2C is at the forefront of revolutionizing industries and paving the way for a decentralized and
          interconnected future.
        </Typography>
      </Box>
    </Section>
  );
};
