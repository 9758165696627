import { Box, Card, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT } from 'shared';

export const IpPortionSeedRounds = () => {
  return (
    <>
      <Typography
        variant="h1"
        className="no-resize"
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            fontSize: 30,
          },
        })}
      >
        <Box component="span" color={COLOR_ACCENT}>
          IP-Portion{' '}
        </Box>
        Seed-Rounds
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        mt={{ xs: 4, md: 8 }}
        mb={{ xs: 5, md: 10 }}
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Card sx={{ gridColumn: { xs: 'span 12', md: '5 / 13' }, p: { xs: 2, md: 3 } }}>
          <Typography variant="h4" className="secondary md">
            The CratD2C Decentralized Autonomous Smart Chain is gearing up to unveil its IP-Portion Seed-Rounds shortly.
            <br />
            <br />
            Keep an eye out for updates!
          </Typography>
        </Card>
      </Stack>
    </>
  );
};
