import { Intro, ScopeInfo } from './ui';

export const SmartChainScope = () => {
  return (
    <>
      <Intro />
      <ScopeInfo />
    </>
  );
};
