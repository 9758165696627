import { Button, Grid, Typography } from '@mui/material';
import { LeaderboardTable } from 'widgets/leaderboard-table';

export const Leaderboard = () => {
  return (
    <Grid container columnSpacing={3} mt={{ xs: 0, md: 9 }}>
      <Grid item xs={12} md={2} mb={{ xs: 3, md: 0 }}>
        <Typography variant="h4" className="secondary">
          Leaderboard
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} rowSpacing={3}>
        <Typography variant="h3" mb={3}>
          To be in the leaderboard: A minimum of 250 combined points is required
        </Typography>
        <Button size="small" LinkComponent="a" href="/#crowdsale" sx={{ mb: 5 }}>
          Complete personal purchase to activate leaderboard
        </Button>
        <LeaderboardTable />
      </Grid>
    </Grid>
  );
};
