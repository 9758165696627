import { Stack, Typography } from '@mui/material';

export const list = [
  {
    title: 'Decentralized Automated Process',
    description: (
      <Stack spacing={2}>
        <Typography variant="body2" className="font-secondary secondary" fontSize={14} whiteSpace="pre-line">
          CratD2C Luxury Lifestyle booking portal automates booking processes, such as booking reservations, payments,
          and cancellations. These self-executing contracts can ensure that transactions are transparent, secure,And
          irreversible, reducing the potential for disputes and fraud.
        </Typography>
        <Typography variant="body2" className="font-secondary secondary" fontSize={14} whiteSpace="pre-line">
          <Typography component="span" variant="inherit" fontSize="inherit" color="#DCD4E2" fontFamily="inherit">
            Tokenized Payment:
          </Typography>{' '}
          The CRAT Coin is the means of payment within the booking portal and also for rewards, loyalty programs or
          exclusive offers for users.
        </Typography>
      </Stack>
    ),
  },
  {
    title: 'Decentralized Identity (DID)',
    description: `The booking portal has a decentralized identity management system to protect users' personal information, ensuring privacy and security. This system can enable users to maintain control over their data and share it only with authorized parties when necessary.`,
  },
  {
    title: 'Decentralized storage',
    description:
      'It Utilizes decentralized storage solutions, such as IPFS, to store and distribute platform data. It enhances security, prevents data loss, and ensures better platform availability.',
  },
  {
    title: 'User interface (UI) and user experience (UX)',
    description:
      'Designed with a user-friendly interface that makes it easy for users to browse, book, and manage luxury experiences. The UI/UX is intuitive, visually appealing, and compatible with desktop and mobile devices.',
  },
  {
    title: 'Decentralized Escrow and Financing',
    description: `The platform establishes partnerships with luxury service providers (hotels, restaurants, bars, cruise yards, airlines, and event organizers) to offer a wide range of booking options and exclusive experiences. These partners can be onboarded and verified through the platform's decentralized systems, ensuring trust and reliability.`,
  },
  {
    title: 'Marketing and community building',
    description:
      'Develop a marketing strategy to attract users and luxury service providers to the platform. Leverage social media, influencer partnerships, and public relations to build awareness and create a community around the portal.',
  },
  {
    title: 'Governance',
    description: `Establish a governance model that allows users and other stakeholders to participate in the platform's decision-making process, achieved through coin-based voting, and a decentralized autonomous community (DAC), promoting a fair and democratic governance structure.`,
  },
];
