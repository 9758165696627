import { wagmiConfig } from 'app/config/wallet';
import { getContract } from 'viem';

import { CROWDSALE_ABI } from '../abi';

const address = {
  testnet: '0xC78BCc544117dc36d7368cc6F569A374c56a750A',
  mainnet: '0x5B71088F0CAb5092d97956557f7562f8De4E6E77',
} as const;

export const crowdsaleContract = getContract({
  abi: CROWDSALE_ABI,
  address: process.env.REACT_APP_NODE_ENV === 'production' ? address.mainnet : address.testnet,
  publicClient: wagmiConfig.publicClient,
});
