import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';

export const Faq = () => {
  return (
    <Grid container columnSpacing={3} mt={5}>
      <Grid item xs={12} md={2} mb={{ xs: 3, md: 0 }}>
        <Typography variant="h4" className="secondary">
          FAQ
        </Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <Divider />
        <Accordion>
          <AccordionSummary>
            What are the prerequisites to participate in the CratD2C $200K Pre-ICO Jumbo Giveaway?
          </AccordionSummary>
          <AccordionDetails>
            A minimum of $250 ICO personal purchase is required to qualify for the giveaway. Additionally, completing
            essential tasks like answering simple quiz just one question and referring others using your unique referral
            link which earns you points to enhance your chances of winning.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>
            How can I complete the minimum personal purchaser of $250 or higher required to qualify for the CratD2C
            Pre-ICO Jumbo Giveaway?
          </AccordionSummary>
          <AccordionDetails>
            To complete personal purchase of ICO to enable qualification, follow these steps:
            <Typography variant="body2" className="accent medium">
              <br />
              STEP-1 :
            </Typography>
            <br />
            Access Your Smart Wallet: Open your smart wallet app, like MetaMask or Trust Wallet.
            <Typography variant="body2" className="accent medium">
              <br />
              STEP-2 :
            </Typography>
            <br />
            Add ICO Contract Address: Find the option to add a custom coin. Enter the CratD2C-Pre Coin&apos;s contract
            address ( CONTRACT ADDRESS )
            <Typography variant="body2" className="accent medium">
              <br />
              STEP-3 :
            </Typography>
            <br />
            Connect Wallet: To integrate your pre-configured smart wallet, select the &quot;Connect Wallet&quot; button
            located on the CratD2C website ( https://cratd2csmartchain.io )
            <Typography variant="body2" className="accent medium">
              <br />
              STEP-4 :
            </Typography>
            <br />
            Once you have completed steps 1 through 3, you should then proceed by clicking on The &quot;BUY
            CratD2C&quot; button available on the website https://cratd2csmartchain.io, input your preferred amount
            (minimum $250) and make payment with USDT or USDC. This action is the final step in the process of acquiring
            CRAT Native Coins at ICO.
            <br />
            <br />
            Remember, this minimum purchase of $250 is essential to qualify for the CratD2C Pre-ICO Jumbo Giveaway. Upon
            completing the purchase, you&apos;ll be eligible to participate and enhance your chances to be among the 10
            Lucky Winners of the $200K Pre-ICO Package Giveaway.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>
            I&apos;ve bought the minimum ICO of $250. Can I increase my personal purchases to earn more points?
          </AccordionSummary>
          <AccordionDetails>
            Certainly! You can add up to a maximum limit of $50,000 for personal purchases to your initial purchase. The
            difference in points between your initial purchase and the new one will be topped up once the new purchase
            is completed. For instance, if you initially purchased $250 (earning 12 points) and decided to increase it
            to $1,000 (which earns 65 points), the additional 53 points will be added to your tally upon completing the
            new purchase.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>How can I track my progress in the giveaway? </AccordionSummary>
          <AccordionDetails>
            Your user dashboard provides comprehensive tracking. Connect your wallet to participate, view your ICO
            purchased amount (in $) along with the total coins acquired, monitor your referral count, and track your
            points earned through completed tasks to ascend the leaderboard.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>
            How are personal purchases rewarded in terms of points for the CratD2C Pre-ICO Jumbo Giveaway?
          </AccordionSummary>
          <AccordionDetails>
            Your personal purchase is rewarded with points as follows:
            <br />
            <br />
            $250 = 12 Points, $500 = 30 Points, $1,000 = 65 Points, $2,000 = 140 Points, $5,000 = 360 Points, $10,000 =
            750 Points, $20,000 = 1,600 Points, $50,000 = 4,000 Points
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>What tasks can I complete to earn points for the giveaway? </AccordionSummary>
          <AccordionDetails>
            You can earn points by completing a simple task (Answer Correctly Just 1 Quiz Question). Correctly answered
            quiz earns you 20 points. Each completed task adds to your points tally, enhancing your position on the
            leaderboard.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>How are referrals rewarded in this giveaway?</AccordionSummary>
          <AccordionDetails>
            Referring others through your unique link is rewarding. For every referral from 1st to 6th joining via your
            link, you earn 20 points each per referral. Referrals from the 7th onwards will earn you Jumbo points of 50
            points each per referral, giving you a boost in the leaderboard rankings.
            <br />
            <br />
            Additionally, you earn 10% in USDT automatically credited to your USDT Wallet upon the completion of your
            referral purchase
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>What does the winning reward entail?</AccordionSummary>
          <AccordionDetails>
            Each of the 10 lucky winners will receive a $20,000 Pre-ICO package. This package includes 113,333 CRAT
            Native Coins valued at $90,666.40 upon listing, contributing to the potential growth of your investment.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>How can I see my current standing in the giveaway?</AccordionSummary>
          <AccordionDetails>
            The user dashboard showcases your current position among the top winners. It reflects your status in the
            leaderboard, allowing you to gauge your winning odds and expected rewards.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>
            I am one of the 10 Lucky Winners in the &quot;WIN $200K CratD2C Pre-ICO Jumbo Giveaway.&quot; How will i
            receive my reward?
          </AccordionSummary>
          <AccordionDetails>
            The 10 lucky winners will have their rewards deposited into their wallet addresses following the
            confirmation of their winning status. This will occur after the launch of the CratD2C Mainnet and will
            adhere to the same distribution pattern established for ICO participants
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
