export const mapPriceToRound: Record<string, number> = {
  '0.2': 1,
  '0.22': 2,
  '0.23': 3,
  '0.24': 4,
  '0.25': 5,
  '0.26': 6,
  '0.27': 7,
  '0.28': 8,
  '0.29': 9,
  '0.30': 910,
};
