import { NavLink } from 'react-router-dom';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, TRANSITION_300 } from 'shared';
import { footerNavLinks } from 'widgets/layout/constants';

export const NavLinks = () => {
  return (
    <Box my={{ xs: 4, md: 3.5 }}>
      <Grid container columnSpacing={1} rowSpacing={2.75}>
        {footerNavLinks.map(({ links, id }) => (
          <Grid item xs={12} sm={4} md={2.4} key={id}>
            <Stack gap={1} sx={{ 'a:first-of-type': { color: COLOR_ACCENT, mb: 1.5 } }}>
              {links?.map((link) =>
                'isExternal' in link && link.isExternal ? (
                  <Typography
                    key={link.title}
                    component="a"
                    href={link.path ?? '/'}
                    target="_blank"
                    rel="noreferrer"
                    variant="subtitle1"
                    sx={{
                      maxWidth: 150,
                      transition: TRANSITION_300,
                      '&:hover': {
                        color: COLOR_ACCENT,
                      },
                    }}
                  >
                    {link.title}
                  </Typography>
                ) : (
                  <Typography
                    key={link.title}
                    component={NavLink}
                    to={link.path ?? '/'}
                    variant="subtitle1"
                    sx={{
                      maxWidth: 150,
                      transition: TRANSITION_300,
                      '&:hover': {
                        color: COLOR_ACCENT,
                      },
                    }}
                  >
                    {link.title}
                  </Typography>
                ),
              )}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
