export const termsAndConditions = [
  {
    title: 'Introduction',
    description:
      'Welcome to the CratD2C ICO. This document outlines the Terms and Conditions (T&C) governing your participation in our Pre-ICO and ICO. By participating in the CratD2C Pre-ICO and ICO, you agree to be bound by these terms.',
  },
  {
    title: 'Coin Swap',
    description:
      'Participants in the Pre-ICO will have the opportunity to swap their CRAT Tokens for CRAT Native Coins at a 3:1 ratio when our Blockchain Mainnet launches. Those who join during the ICO will be able to swap their CratD2C-Pre Tokens for CRAT Native Coins on a 1:1 ratio upon the launch of our Blockchain Mainnet.',
  },
  {
    title: 'Cliff Period',
    description:
      'The Cliff Period for CratD2C Decentralized Autonomous Smart Chain begins on the first day of CRAT Coin’s listing on a centralized exchange and lasts three months. Pre-ICO and ICO Rounds participants cannot trade their acquired coins during this period. This is to ensure market stability and a fair distribution of native coins.',
  },
  {
    title: 'Vesting Period',
    description:
      "Coins purchased in the pre-ICO and ICO phases are fully allocated to you immediately after completing the transaction. However, the conversion of these coins into the blockchain's native cryptocurrency, CRAT Coins, will be conducted at a rate of 25% per quarter following the completion of the specified cliff period.",
  },
  {
    title: 'Acknowledgment',
    description:
      'By participating in the CratD2C Pre-ICO and ICO, you acknowledge and agree to these terms and conditions. You also affirm that you are not a resident or citizen of any ICO-restricted territories.',
  },
  {
    title: 'Disclaimer',
    description:
      "Participants are responsible for adhering to their jurisdictions' Initial Coin Offerings (ICO) regulations. It is incumbent upon each participant to ensure compliance with local laws and regulations related to ICO participation.\n\nFurthermore, CratD2C retains the authority to review these terms at any given moment. It is the duty of each participant to stay informed of any modifications to these terms, as ongoing participation in the ICO implies acceptance of such changes.",
  },
];
