import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BiennialSupplyMechanism: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon viewBox="0 0 36 35" sx={{ width: '36px', height: '35px', fill: 'none', ...sx }} {...props}>
    <path
      d="M25.8378 14.5168L31.0636 17.42L25.8378 20.3232M25.8378 14.5168L17.9991 18.8716L10.1604 14.5168M25.8378 14.5168L31.0636 11.6135L17.9991 4.35547L4.93457 11.6135L10.1604 14.5168M10.1604 14.5168L4.93457 17.42L10.1604 20.3232M25.8378 20.3232L31.0636 23.2264L17.9991 30.4845L4.93457 23.2264L10.1604 20.3232M25.8378 20.3232L17.9991 24.6781L10.1604 20.3232"
      stroke="#B4EB56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
