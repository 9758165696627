import BigNumber from 'bignumber.js/bignumber';

import { formatNumber } from './numberFormatter';

export const toDecimals = (balance: string | number, decimals = 18, shouldForamateNumber = false): string => {
  if (balance === '') {
    return '0';
  }

  if (typeof balance === 'number') {
    balance.toString();
  }

  const displayValue = new BigNumber(balance).multipliedBy(new BigNumber(10).pow(decimals));

  if (shouldForamateNumber) {
    const formattedValue = formatNumber(+displayValue.toString());

    return formattedValue;
  }

  return displayValue.toString(10);
};

export const fromDecimals = (balance: string | number, decimals = 18): string => {
  if (balance === '') {
    return '0';
  }

  if (typeof balance === 'number') {
    balance.toString();
  }

  const displayValue = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals));

  return parseFloat(displayValue.toFixed(4)).toString();
};

export const formatForDisplay = (balance: string | number, displayDecimals: number) =>
  parseFloat(new BigNumber(balance).toFixed(displayDecimals, 1)).toString();

export const formatToken = (
  amount: number | string,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  locale = 'en-US',
): string =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(+amount);
