import { Typography } from '@mui/material';

export const intro = {
  paragraph: (
    <>
      CratD2C e-Market Portals, integral to the CratD2C Decentralized Autonomous Smart Chain (DASC), constitute a robust
      ecosystem housing three specialized portals: an{' '}
      <Typography variant="h4" className="summary accent" component="span">
        e-commerce Trading Portal, a Real Estate Trading Portal, A Luxury Lifestyle Booking Portal, and a universal
        payment gateway
      </Typography>{' '}
      (Crypto Debit Card, Vending Machine, POS Machine).
    </>
  ),
  subtitle: `Within this ecosystem, each portal harnesses the inherent benefits of blockchain technology to offer distinct value propositions tailored to their respective industries.
        
  Each of these portals operates with the blockchain's native coin as a means of settlement, ensuring transactions are not only swift but also transparent and secure, thanks to the inherent qualities of blockchain technology.
        
  These portals cater to diverse consumer needs and preferences. Operated exclusively with the blockchain's native coin, transactions within these portals boast unparalleled speed, transparency, and security. Leveraging blockchain technology ensures swift settlement while upholding the fundamental principles of transparency and security, hallmark features of the CratD2C ecosystem.`,
};
