import { Box, styled } from '@mui/material';
import { BoxProps } from '@mui/system/Box';
import { COLOR_ACCENT } from 'shared/config';

const StyledBox = styled(Box)`
  width: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, ${COLOR_ACCENT} 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 5%, ${COLOR_ACCENT} 60%);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 0.8s infinite linear;

  @keyframes s3 {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const Spinner = ({ ...props }: BoxProps) => {
  return <StyledBox {...props} />;
};
