import React, { PropsWithChildren, ReactNode } from 'react';
import { Box, IconButton, Slide, Stack, styled, SxProps, Theme, Typography } from '@mui/material';
import { COLOR_BG, COLOR_STROKE, COLOR_WH } from 'shared';

import { Icons } from '..';

interface ModalSidebarProps {
  onClose: VoidFunction;
  isOpen?: boolean;
  ref?: React.ForwardedRef<HTMLElement>;
  sx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  title?: ReactNode;
  icon?: ReactNode;
  transitionTime?: number;
}

const StyledWrapper = styled(Box)(({ theme }) => ({
  maxWidth: 427,
  backgroundColor: COLOR_WH,
  height: '100%',
  width: '100%',
  borderRadius: '20px',
  flexGrow: 1,
  marginLeft: 'auto',
  zIndex: 100,
  position: 'relative',
  paddingTop: 14,
  paddingBottom: 14,
  pointerEvents: 'auto',
}));

export const ModalSidebar: React.FC<PropsWithChildren<ModalSidebarProps>> = React.forwardRef(
  ({ children, sx, titleSx, title, icon, isOpen, transitionTime = 500, onClose }, ref) => {
    return (
      <StyledWrapper tabIndex={-1} sx={sx} ref={ref}>
        <Box
          sx={{
            padding: '14px 20px',
            height: '100%',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 22,
              top: 8,
              height: 56,
              width: 56,
              borderRadius: '50%',
              border: '2px solid',
              borderColor: COLOR_STROKE,
              background: 'transparent',
              '&:hover': {
                background: 'transparent',
              },
            }}
          >
            <Icons.Close />
          </IconButton>
          {!!icon && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: '50%',
                border: '1px solid',
                borderColor: COLOR_STROKE,
                background: COLOR_BG,
                width: 72,
                height: 72,
                mb: 3,
              }}
            >
              {icon}
            </Stack>
          )}
          <Typography variant="h4" fontSize={{ xs: 30, sm: 40 }} fontWeight={600} mb={3} className="black" sx={titleSx}>
            {title}
          </Typography>
          <Typography component="span" className="black font-secondary" variant="body2">
            {children}
          </Typography>
        </Box>
      </StyledWrapper>
    );
  },
);
