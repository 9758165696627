import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Advantages, Intro, Section } from 'shared';

import { BOTTOM_TEXT, intro, list } from './constants';

export const Ctvg = () => {
  return (
    <>
      <Intro
        mainTitle="CTVG: Build on CratD2C (Receive Grant)"
        paragraphSubtitle={intro.subtitle}
        paragraph={intro.paragraph}
        paragraphProps={{ letterSpacing: '-0.4px !important' }}
        sx={{ h1: { maxWidth: 1000 } }}
      />
      <Advantages list={list} headTitle="Advantages" />
      <Section>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            mt: { xs: 2, sm: 3.5 },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mt: 5,
              gridColumn: { xs: 'span 12', md: '5 / 13' },
            }}
          >
            {BOTTOM_TEXT}
          </Typography>
        </Box>
      </Section>
    </>
  );
};
