import React from 'react';
import { Box } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

export interface CtvgModalPayload {
  data?: null;
  type: ModalType.CTVG;
  isOpen?: boolean;
}

export const CtvgModal = React.forwardRef<
  HTMLElement,
  Pick<CtvgModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Community Trust Vesting Grant (CTVG)"
      icon={<Icons.PersonSettings />}
      titleSx={{
        maxWidth: 338,
      }}
    >
      <Box component="p">
        The Community Trust Vesting Grant (CTVG) within the CratD2C Decentralized Autonomous Smart Chain (DASC) embodies
        a strategic initiative to nurture and accelerate the growth of promising blockchain startups.
      </Box>
      <br />
      <Box component="p">
        This grant program serves as a powerful catalyst for these startups by providing them with substantial support
        in the form of grants to develop and build their architecture on the CratD2C Smart Chain.
      </Box>
    </ModalSidebar>
  );
});
