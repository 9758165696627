import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { ModalSidebar } from 'shared';

import { list } from './constants';

export interface DelegatorModalPayload {
  data?: null;
  type: ModalType.Delegator;
  isOpen?: boolean;
}

export const DelegatorModal = React.forwardRef<
  HTMLElement,
  Pick<DelegatorModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="Become a Delegator"
      titleSx={{
        maxWidth: 200,
        fontSize: { xs: 30, sm: 32 },
      }}
    >
      <Stack gap={2}>
        <Stack>
          <Typography className="black">Acquire & Stake</Typography>
          <Typography variant="h5" className="black bold no-resize">
            1000{' '}
            <Typography component="span" className="semi-bold black">
              CRAT Native Coins
            </Typography>
          </Typography>
        </Stack>
        {list.map(({ id, title, text }) => (
          <Stack key={id}>
            <Typography className="black">{title}</Typography>
            <Typography variant="h5" className="black bold no-resize">
              {text}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </ModalSidebar>
  );
});
