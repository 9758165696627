import { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { giveawayModel } from 'entities/giveaway';
import { userModel } from 'entities/user';
import { connectModalModel } from 'features';
import {
  BORDER_MAIN,
  BORDER_RADIUS_L,
  COLOR_RED,
  COLOR_SECONDARY,
  Icons,
  scrollToElement,
  useAppDispatch,
  useShallowSelector,
} from 'shared';
import { FontWeights } from 'shared/config/theme/Typography';

const BorderWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: BORDER_RADIUS_L,
  border: BORDER_MAIN,
  padding: theme.spacing(4),
  gap: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  color: COLOR_SECONDARY,
  fontWeight: FontWeights.Medium,

  [theme.breakpoints.down('sm')]: {
    fontSize: 15,
    fontWeight: FontWeights.Regular,
  },
}));

export const Quiz = () => {
  const dispatch = useAppDispatch();

  const { userAddress, isAuthenticated } = useShallowSelector(userModel.selectors.selectUserInfo);
  const { userQuizAnswer, isSuccessfullAnswer } = useShallowSelector(giveawayModel.selectors.getGiveaway);

  const [userAnswer, setUserAnswer] = useState(userQuizAnswer);

  const POINTS_TO_EARN = 20;

  const radioColor = !isSuccessfullAnswer && userQuizAnswer ? 'secondary' : 'primary';

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserAnswer(+(event.target as HTMLInputElement).value);
  };

  const handleClickButton = () => {
    if (!isAuthenticated) {
      scrollToElement('connect-button');

      setTimeout(() => {
        dispatch(connectModalModel.actions.openModal({ anchorId: 'crowdsale' }));
      }, 800);
    } else {
      dispatch(giveawayModel.thunks.answerQuiz({ answer: userAnswer }));
    }
  };

  useEffect(() => {
    if (userAddress) dispatch(giveawayModel.thunks.getUserQuizStats());
  }, [dispatch, userAddress]);

  useEffect(() => {
    setUserAnswer(userQuizAnswer);
  }, [userQuizAnswer]);

  return (
    <Grid container id="quiz">
      <Grid item xs={12} md={2} mb={{ xs: 3, md: 0 }}>
        <Typography variant="h4" className="secondary">
          02. Quiz
        </Typography>
      </Grid>
      <Grid item container xs={12} md={10} gap={3}>
        <Typography variant="h5" className="no-resize secondary">
          Complete the simple task to earn more points (Answer Correctly Just 1 Quiz Question)
        </Typography>
        <BorderWrapper>
          <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" gap={3}>
            <Typography variant="h4" maxWidth={502} className="md">
              What is the primary purpose of CratD2C&apos;s native coin within its ecosystem?
            </Typography>
            {!userQuizAnswer && (
              <Typography variant="h5" className="secondary no-resize ">
                <Typography variant="h5" component="span" className="accent no-resize">
                  +{POINTS_TO_EARN}
                </Typography>{' '}
                Points
              </Typography>
            )}
          </Stack>
          <FormControl disabled={!!userQuizAnswer}>
            <RadioGroup value={userAnswer} onChange={handleChange} color="secondary">
              <FormControlLabel value={1} control={<Radio color={radioColor} />} label={<Label>Governance</Label>} />
              <FormControlLabel value={2} control={<Radio color={radioColor} />} label={<Label>Staking</Label>} />
              <FormControlLabel
                value={3}
                control={<Radio color={radioColor} />}
                label={<Label>Making Payment for Purchases on Market Portals</Label>}
              />
              <FormControlLabel value={4} control={<Radio color={radioColor} />} label={<Label>Gas Fee</Label>} />
              <FormControlLabel
                value={5}
                control={<Radio color={radioColor} />}
                label={<Label>All of the above</Label>}
              />
            </RadioGroup>
          </FormControl>
          {!userQuizAnswer && (
            <Button disabled={isAuthenticated && !userAnswer} onClick={handleClickButton} sx={{ width: 'fit-content' }}>
              {!userAddress ? 'Connect wallet' : 'Send'}
            </Button>
          )}

          {userQuizAnswer && isAuthenticated && (
            <Stack direction="row" spacing={3} alignItems="center">
              <Button
                variant="outlined"
                color={isSuccessfullAnswer ? 'success' : 'primary'}
                endIcon={isSuccessfullAnswer ? <Icons.Check /> : <Icons.CloseCircle sx={{ color: COLOR_RED }} />}
                disabled
                sx={{ width: 'fit-content', px: 2, py: 1, '.MuiButton-endIcon': { ml: 2 } }}
              >
                You answered {isSuccessfullAnswer ? 'correctly' : 'incorrectly'}
              </Button>
              <Typography variant="h5" className="secondary">
                <Typography variant="h5" component="span" className={isSuccessfullAnswer ? 'accent' : 'primary'}>
                  +{isSuccessfullAnswer ? POINTS_TO_EARN : 0}
                </Typography>{' '}
                Points
              </Typography>
            </Stack>
          )}
        </BorderWrapper>
      </Grid>
    </Grid>
  );
};
