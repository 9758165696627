import { Box, Grid, Typography } from '@mui/material';
import { COLOR_SECONDARY, COLOR_STROKE } from 'shared';

type AmbassadorialListItemProps = {
  title: string;
  description: string | string[];
  orderNumber?: number;
};

export const AmbassadorialListItem = ({ title, orderNumber, description }: AmbassadorialListItemProps) => (
  <Grid container rowSpacing={2} sx={{ borderTop: `1px solid ${COLOR_STROKE}` }}>
    <Grid item xs={12} md={6} pr={3}>
      <Typography
        className="semi-bold"
        sx={{
          position: 'relative',
          paddingLeft: orderNumber ? 3 : 0,
          '&:before': orderNumber && {
            content: `"0${orderNumber}"`,
            position: 'absolute',
            left: 0,
            top: 0,
            fontSize: 'inherit',
            fontFamily: 'inherit',
            opacity: 0.2,
          },
        }}
      >
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      {typeof description === 'string' ? (
        <Typography variant="body2" className="font-secondary" color={COLOR_SECONDARY}>
          {description}
        </Typography>
      ) : (
        <Box
          component="ol"
          sx={{
            pl: { xs: 2, md: 0 },
            listStyleType: 'disc',
            'li + li': { mt: 2 },
          }}
        >
          {description.map((text) => (
            <Typography key={text} variant="body2" className="font-secondary" color={COLOR_SECONDARY} component="li">
              {text}
            </Typography>
          ))}
        </Box>
      )}
    </Grid>
  </Grid>
);
