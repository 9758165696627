import { Divider, Grid, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, team, TeamCard, TeamSlider, useBreakpoints } from 'shared';

import { mailContacts, socials, support, telegram } from './constants';

const InfoParagraph = () => (
  <Typography className="secondary" variant="body2">
    The CratD2C team is effectively led by a seasoned financial expert who boasts over 17 years of experience across
    various financial sectors. This includes a strong background in both the FX and Stock Markets. The founder ventured
    into the Blockchain Industry in 2015 and has since contributed to numerous cryptocurrency initiatives, including the
    Ethereum DAO in 2016, along with other significant Blockchain projects.
  </Typography>
);

export const TeamContacts = () => {
  const { isDownMdLayout } = useBreakpoints();
  return (
    <Grid container>
      {isDownMdLayout && <InfoParagraph />}
      <Grid item xs={12} md={6} mt={{ xs: 6, md: 0 }}>
        {isDownMdLayout ? (
          <TeamSlider />
        ) : (
          <Stack rowGap={3}>
            {team.map((teamItem) => (
              <TeamCard key={teamItem.id} {...teamItem} />
            ))}
          </Stack>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {!isDownMdLayout && <InfoParagraph />}
        <Typography className="accent" mt={6}>
          General Inquiries, Technical Support:
        </Typography>
        <Stack direction="row" spacing={1.5} mt={2}>
          <support.Icon />
          <a rel="noreferrer" href={support.url} target="_blank">
            <Typography variant="body2">{support.content}</Typography>
          </a>
        </Stack>
        <Typography className="accent" mb={2.75} mt={{ xs: 4.5, md: 12 }}>
          Social Media Handles:
        </Typography>
        <Divider />
        <Stack direction="row" rowGap={3} mt={3} mb={3.75} flexWrap="wrap">
          {telegram.map((tgItem) => (
            <Stack key={tgItem.content} flex={{ xs: '100%', sm: '50%' }} gap={2}>
              <Typography className="secondary">{tgItem.title}</Typography>
              <Stack direction="row" spacing={1.5}>
                <tgItem.Icon sx={{ color: COLOR_ACCENT }} />
                <a rel="noreferrer" href={tgItem.url} target="_blank">
                  <Typography variant="body2">{tgItem.content}</Typography>
                </a>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Divider />
        <Stack direction="row" rowGap={{ xs: 3, sm: 4 }} mt={3} mb={5} flexWrap="wrap">
          {socials.map((socialItem) => (
            <Stack key={socialItem.content} flex={{ xs: '100%', sm: '50%' }} gap={2}>
              <Typography className="secondary">{socialItem.title}</Typography>
              <Stack direction="row" spacing={1.5} alignItems="center">
                <socialItem.Icon sx={{ color: COLOR_ACCENT }} />
                <a rel="noreferrer" href={socialItem.url} target="_blank">
                  <Typography variant="body2">{socialItem.content}</Typography>
                </a>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Divider />
        <Stack rowGap={{ xs: 3, sm: 4 }} mt={3}>
          {mailContacts.map((mailItem) => (
            <Stack key={mailItem.content} gap={2}>
              <Typography className="accent">{mailItem.title}</Typography>
              <Stack direction="row" spacing={1.5} alignItems="center">
                <mailItem.Icon sx={{ color: COLOR_ACCENT }} />
                <a rel="noreferrer" href={mailItem.url} target="_blank">
                  <Typography variant="body2">{mailItem.content}</Typography>
                </a>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};
