import { alpha } from '@mui/material';

import { COLOR_BG_BLACK, COLOR_GRAY, COLOR_RED, COLOR_SECONDARY, COLOR_STROKE, COLOR_WH } from '../colors.constants';

export const colorsSelect = {
  colorsSelectBackground: COLOR_BG_BLACK,
  colorsSelectBorder: COLOR_STROKE,
  colorsSelectColor: COLOR_WH,
  colorsSelectPlaceholderColor: alpha(COLOR_GRAY, 0.3),
  colorsSelectColorError: COLOR_RED,
  colorsSelectColorDisabled: COLOR_GRAY,
  colorsFormLabelSelect: COLOR_SECONDARY,
};
