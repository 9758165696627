import { Br } from 'shared';

export const steps = [
  {
    title: 'A Paradigm Shift in Blockchain Technology',
    description:
      "Innovative Layer-1 Blockchain Architecture: The heart of CratD2C's innovation lies in its Layer-1 blockchain architecture. This state-of-the-art design is optimized for unmatched scalability and efficiency, boasting transaction finality times ranging from a mere 0.5 to 3 seconds. This extraordinary speed and responsiveness set CratD2C apart, making it an ideal platform for real-world blockchain applications.",
  },
  {
    title: 'Unmatched Transaction Capacity',
    description:
      "100,000 Transactions Per Second (TPS): CratD2C's SmartChain technology exhibits an astounding processing capacity of 100,000 TPS. This unprecedented capability positions CratD2C as a leader in the blockchain space, capable of accommodating the demands of large-scale, global applications.",
  },
  {
    title: 'The Power of DPoS Consensus',
    description:
      'Delegated Proof-of-Stake (DPoS) Mechanism: CratD2C leverages the DPoS consensus model to enhance scalability and network integrity. This mechanism divides responsibilities among validators and delegators, streamlining transaction management while incentivizing network participation with block transaction fees. This ensures a more secure network and promotes a more democratic and inclusive blockchain environment.',
  },
  {
    title: 'Revolutionary Staking Mechanism',
    description:
      "LiteBackers and TurboBackers: CratD2C introduces an innovative staking concept with LiteBackers and TurboBackers. These entities are crucial in maintaining network security and contributing to its growth. Stakeholders are rewarded with up to 20% APR on their stake portfolio, and the flexibility to re-stake coins biweekly or weekly, coupled with additional incentives, propels the network's decentralization efforts.",
  },
  {
    title: 'Innovative Supply Mechanism',
    description:
      "Biennial 8-Layer Zig-Zag Supply Mechanism: CratD2C unveils a pioneering supply mechanism that operates biennially until 2039. This novel approach is a testament to the project's commitment to sustainability, fairness, and community engagement. It ensures a controlled and balanced distribution of CRAT Coins, setting a new precedent in the blockchain industry.",
  },
  {
    title: (
      <>
        A Versatile Ecosystem of dApps <Br display={{ xs: 'none', sm: 'inline' }} /> & Use Cases
      </>
    ),
    description:
      "CratD2C is more than a blockchain; it's a hub for decentralized applications (dApps) and marketplaces. Its ecosystem includes three indigenous portals (e-commerce trading portal, real estate trading portal, and luxury lifestyle booking portal) and a universal payment gateway (crypto debit card). Each portal utilizes the blockchain's native coin for transaction settlements, leveraging blockchain technology's inherent transparency and security to revolutionize operations across various sectors.",
  },
  {
    title: 'Coin-IP Asset Value Linkage',
    description:
      "A unique aspect of CratD2C is its linkage of native coin value to the ecosystem's intellectual property assets. This symbiotic relationship offers tangible benefits to coin holders, strengthening their connection to the project's success.",
  },
  {
    title: (
      <>
        The Valuation and Protection <Br display={{ xs: 'none', sm: 'inline' }} /> of CratD2C&#39;s Intangible Assets
      </>
    ),
    description:
      "CratD2C's intangible assets are valued at a staggering $160,255,384, as assessed by the London Rate International Office, a specialist in intellectual property valuation. The project's IP rights are secured across 181 countries, providing robust protection and credibility.",
  },
  {
    title: 'Decentralized Applications (dApps)',
    description:
      "CratD2C isn't just a blockchain; it's a thriving ecosystem of decentralized applications. From coins to NFTs, from decentralized exchanges (DEx) to decentralized autonomous organizations (DAOs), CratD2C provides a versatile platform where developers can unleash their creativity.",
  },
  {
    title: 'Primary Ecosystem dApps',
    description:
      'Explore a range of primary ecosystem dApps, each designed to cater to real-world needs and industries.',
  },
];
