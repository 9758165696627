import { Box, Typography } from '@mui/material';
import { COLOR_GRAY_5 } from 'shared';

export const Pos = () => {
  return (
    <Box>
      <Typography variant="h5" letterSpacing="-0.021em">
        Point of sale (POS), a critical integral of a point of purchase for any business merchant, refers to where a
        customer executes the payment for goods or services. However, the cryptocurrency market has been unable to
        penetrate this mainstream of the business world effectively.
      </Typography>
      <Box
        sx={{
          mt: 4.5,
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
        }}
      >
        <Box
          sx={{
            gridColumn: { xs: 'span 12', md: '4 / 13' },
          }}
        >
          <Typography>
            <Typography variant="body2" color={COLOR_GRAY_5} className="font-secondary">
              CratD2C Smart Chain makes this vast task achievable by simplifying the Easy-Point-Of-Purchase
              Mechanism.eCommerce Merchants worldwide can ultimately receive cryptocurrency as payment at the point of
              purchase, enabling them to have a Better Inventory, Simple Invoicing, Faster Payment, Better Customer
              Experience, and Improved brand Image.
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
