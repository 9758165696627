import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Advantages, CommunityLg, CommunitySm, Intro, Section, useBreakpoints } from 'shared';

import { BOTTOM_TEXT, intro, list, ListTitle } from './constants';

export const EcoCommunity = () => {
  const { isDownMdLayout } = useBreakpoints();
  return (
    <>
      <Intro
        mainTitle="Eco - Community"
        highlightedTitleWords={[0]}
        paragraph={intro.paragraph}
        paragraphProps={{ letterSpacing: '-0.4px !important', mt: 2.5 }}
      />
      <Box
        component="img"
        src={isDownMdLayout ? CommunitySm : CommunityLg}
        sx={{ width: '100%', mb: { xs: 5, md: 9 } }}
      />
      <Advantages
        list={list}
        headTitle=""
        listTitle={<ListTitle />}
        listProps={{ rowSpacing: { xs: 6, md: 8 } }}
        sx={{ mb: 7.5 }}
      />
      <Section>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              gridColumn: { xs: 'span 12', md: '5 / 13' },
            }}
          >
            {BOTTOM_TEXT}
          </Typography>
        </Box>
      </Section>
    </>
  );
};
