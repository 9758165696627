import { Alexander, Alina, Arogundade, Dwain, Hasmik, Maryna } from 'shared/assets/images/team';

type TeamMember = {
  id: number;
  image: string;
  name: string;
  link: string;
  align: 'left' | 'right';
};

export const team: TeamMember[] = [
  {
    id: 1,
    image: Arogundade,
    name: 'Dr Arogundade Samsondeen',
    link: 'https://www.linkedin.com/in/drsammycratd2c/',
    align: 'right',
  },
  { id: 2, image: Alexander, name: 'Alexander Tkachev', link: 'https://www.linkedin.com/in/astkachev', align: 'left' },
  {
    id: 3,
    image: Alina,
    name: 'Alina Rosavytska',
    link: 'https://www.linkedin.com/in/alina-rosavytska-54a252a2/',
    align: 'left',
  },
  {
    id: 4,
    image: Hasmik,
    name: 'Hasmik Harutyunyan',
    link: 'https://www.linkedin.com/in/hasmik-h-263b55233/',
    align: 'right',
  },
  {
    id: 5,
    image: Maryna,
    name: 'Maryna Miakenka',
    link: 'https://www.linkedin.com/in/marina-myakenkaya/',
    align: 'left',
  },
  {
    id: 6,
    image: Dwain,
    name: 'Dwain Elias Albores',
    link: 'https://www.linkedin.com/in/dwain-albores-299305210/',
    align: 'left',
  },
];
