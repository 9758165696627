import { api } from 'shared';

import { GetMessageRes, LoginReq, ResWithToken } from './types';

const getLoginMessage = () => {
  return api<GetMessageRes>({
    url: '/accounts/wallet/get_message/',
    method: 'GET',
  });
};

const login = ({ data }: { data: LoginReq }) => {
  return api<ResWithToken>({
    url: '/accounts/wallet/connect/',
    method: 'POST',
    data,
  });
};

export const userApi = {
  getLoginMessage,
  login,
};

export * from './types';
