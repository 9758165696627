import { Box, Typography } from '@mui/material';
import { analysisData, AnalysisTable } from 'shared';

export const ComparativeAnalysis = () => {
  return (
    <Box
      mb={{ xs: 6, md: 12 }}
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
      }}
    >
      <Box
        mb={7}
        sx={{
          gridColumn: { xs: 'span 13', md: '5 / 13' },
        }}
      >
        <Typography variant="h4" className="summary">
          A comparative analysis table for Latency & TPS (Transactions Per Second) among CratD2C, Bitcoin, Ethereum,
          Cardano, EOS, and Solana:
        </Typography>
      </Box>

      <AnalysisTable data={analysisData} />
    </Box>
  );
};
