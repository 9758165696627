import { Popover, PopoverProps, Typography } from '@mui/material';
import { BORDER_RADIUS_M } from 'shared';

export const TipPopover = ({ open, anchorEl, onClose, PaperProps, children, ...props }: PopoverProps) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
        ...props.anchorOrigin,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        ...props.transformOrigin,
      }}
      PaperProps={{
        ...PaperProps,
        sx: {
          borderRadius: BORDER_RADIUS_M,
          padding: '8px 16px',
          maxWidth: 210,
          width: '100%',
          ...PaperProps?.sx,
        },
      }}
      {...props}
    >
      <Typography variant="subtitle2" className="black">
        {children}
      </Typography>
    </Popover>
  );
};
