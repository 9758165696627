import React from 'react';
import { Box, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { Icons, ModalSidebar } from 'shared';

import { list } from './constants';

export interface EcommerceTradingModalPayload {
  data?: null;
  type: ModalType.EcommerceTrading;
  isOpen?: boolean;
}

export const EcommerceTradingModal = React.forwardRef<
  HTMLElement,
  Pick<EcommerceTradingModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, isOpen }, ref) => {
  return (
    <ModalSidebar
      isOpen={isOpen}
      ref={ref}
      onClose={onClose}
      title="CratD2C eCommerce Trading Portal"
      icon={<Icons.Buy />}
    >
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit" fontWeight={700}>
        CratD2C decentralized e-commerce trading portal is an online marketplace that operates on a CratD2C
        Decentralized Autonomous Smart Chain, allowing buyers and sellers to interact directly without intermediaries.
        This structure promotes greater transparency, security, and reduced transaction costs. Here&apos;s an outline of
        the key components and features of the CratD2C decentralized e-commerce trading portal:
      </Typography>
      <br />
      <Box component="ol">
        {list.map((item, i) => {
          return (
            <React.Fragment key={item.id}>
              <Typography
                component="li"
                variant="inherit"
                color="inherit"
                fontFamily="inherit"
                sx={{
                  position: 'relative',
                  paddingLeft: '24px',
                  '&:before': {
                    content: `"${i + 1}."`,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    fontSize: 'inherit',
                    fontFamily: 'inherit',
                  },
                }}
              >
                {item.text}
              </Typography>
              <br />
            </React.Fragment>
          );
        })}
      </Box>
      <Typography variant="inherit" component="p" color="inherit" fontFamily="inherit">
        CratD2C&apos;s decentralized e-commerce trading portal leverages blockchain technology and smart contracts to
        create a secure, transparent, and efficient online marketplace. By eliminating intermediaries and empowering
        users, this structure has the potential to revolutionize the way we conduct trade and commerce online.
      </Typography>
    </ModalSidebar>
  );
});
