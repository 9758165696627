import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { Backdrop } from '@mui/material';
import { COLOR_BG, Icons, IS_PRODUCTION } from 'shared';

const LoaderContext = createContext(undefined);

export const LoaderProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loading = setTimeout(() => setIsLoading(false), 5000);
    return () => clearTimeout(loading);
  }, []);

  return (
    <LoaderContext.Provider value={undefined}>
      <Backdrop open={isLoading} sx={{ zIndex: 2147483640, background: COLOR_BG, transition: 'all 0.5s ease-out' }}>
        <Icons.CratLoader sx={{ width: { xs: 175, md: 325 } }} />
      </Backdrop>

      {children}
    </LoaderContext.Provider>
  );
};

export const WithLoader = ({ children }: PropsWithChildren) => {
  if (!IS_PRODUCTION) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <LoaderProvider>{children}</LoaderProvider>;
};
