import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Eth: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 36 36"
    sx={{
      width: '36px',
      height: '36px',
      ...sx,
    }}
    {...props}
  >
    <circle cx="18" cy="18" r="18" fill="#EDF0F4" />
    <path d="M17.9518 7.19922L17.8086 7.6856V21.7981L17.9518 21.941L24.5025 18.0688L17.9518 7.19922Z" fill="#343434" />
    <path d="M17.9513 7.19922L11.4004 18.0688L17.9513 21.941V15.0911V7.19922Z" fill="#8C8C8C" />
    <path
      d="M17.9518 23.1807L17.8711 23.2792V28.3062L17.9518 28.5418L24.5065 19.3105L17.9518 23.1807Z"
      fill="#3C3C3B"
    />
    <path d="M17.9513 28.5418V23.1807L11.4004 19.3105L17.9513 28.5418Z" fill="#8C8C8C" />
    <path d="M17.9512 21.9416L24.5019 18.0694L17.9512 15.0918V21.9416Z" fill="#141414" />
    <path d="M11.4004 18.0694L17.9513 21.9416V15.0918L11.4004 18.0694Z" fill="#393939" />
  </SvgIcon>
);
