import { Box, Grid } from '@mui/material';
import { PageParagraph, Section } from 'shared';

import { comparativeAnalysisData } from '../../model/constants';

import { ComparisonTableHead } from './comparison-table-head';
import { ComparisonTableRow } from './comparison-table-row';

export const ComparisonTable = () => {
  return (
    <Section>
      <Box
        mb={{ xs: 8, md: 18 }}
        sx={{
          overflowX: 'scroll',
        }}
      >
        <Grid
          container
          component="table"
          aria-label="Comparison table"
          sx={(theme) => ({ width: '100%', [theme.breakpoints.down(950)]: { width: '250%' } })}
        >
          <ComparisonTableHead />
          <Grid item container component="tbody" xs={12}>
            {comparativeAnalysisData.map(([title, ...values]) => (
              <ComparisonTableRow key={title} title={title} values={values} sx={{ mt: 2 }} />
            ))}
          </Grid>
        </Grid>
      </Box>

      <PageParagraph
        sx={{ mt: 0 }}
        pProps={{
          variant: 'body2',
          className: 'secondary',
        }}
      >
        In this comparative analysis, CratD2C Smart Chain showcases several advantages over existing blockchain
        projects. Its innovative 8-Layer Zig-Zag Supply Mechanism ensures fair and balanced coin distribution, while the
        DPoS consensus mechanism ensures high scalability, security, and fast transaction speeds. With a high level of
        community involvement and a decentralized governance model, CratD2C stands out as a project that prioritizes
        inclusivity and innovation.
        <br />
        <br />
        Furthermore, CratD2C&apos;s extensive coin use cases, enhanced privacy features, and strong focus on long-term
        sustainability make it a competitive player in the blockchain space. While other blockchain projects have their
        own strengths and weaknesses with limitations in scalability, governance, coin distribution, and use cases,
        CratD2C presents a comprehensive ecosystem that combines technological advancements with community engagement,
        positioning itself as a compelling player in the blockchain industry.
      </PageParagraph>
    </Section>
  );
};
