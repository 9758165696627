import { ErrorTab } from './error-tab';
import { FormTab } from './form-tab';
import { useModalState } from './provider';
import { SuccessTab } from './success-tab';

export const TABS = {
  form: FormTab,
  success: SuccessTab,
  error: ErrorTab,
};

export const DisplayedTab = () => {
  const { tab } = useModalState();

  const Tab = TABS[tab];

  return <Tab />;
};
