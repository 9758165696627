import { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { BORDER_ACCENT, COLOR_BG } from 'shared';

type InfoCircleProps = {
  placement: string;
  size?: 'lg' | 'sm';
  reaignDownMd?: boolean;
} & PropsWithChildren;

export const InfoCircle = ({ placement, size = 'lg', children, reaignDownMd = false }: InfoCircleProps) => {
  const theme = useTheme();

  const SIZE = {
    lg: { xs: 156, md: 170 },
    sm: { xs: 80, md: 100 },
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      position="absolute"
      className={placement}
      sx={[
        {
          borderRadius: '50%',
          border: BORDER_ACCENT,
          width: SIZE[size],
          height: SIZE[size],
          bgcolor: COLOR_BG,
          '&.top-left': {
            left: 12,
            top: 16,
          },
          '&.top-right': {
            right: 12,
            top: 16,
          },
          '&.bottom-left': {
            left: 12,
            bottom: 16,
          },
          '&.bottom-right': {
            right: 12,
            bottom: 16,
          },
        },
        reaignDownMd && {
          [theme.breakpoints.down('md')]: {
            '&.top-left, &.top-right, &.bottom-left, &.bottom-right': {
              left: 9,
              top: '50%',
              translate: '0 -50%',
              right: 'unset',
            },
          },
        },
      ]}
    >
      {children}
    </Stack>
  );
};
