export const BOTTOM_TEXT =
  "It's important for VCs and angel investors to conduct thorough due diligence before getting involved in any blockchain project. This includes assessing the project's team, technology, market potential, and regulatory compliance. Additionally, they should align their investment strategy with their risk tolerance and long-term objectives.";

export const intro = {
  paragraph:
    'Venture capitalists (VCs) and Angel investors play a crucial role in the growth and development of blockchain projects like CratD2C. Here are some opportunities for VCs and Angel Investors in the CratD2C ecosystem:',
};

export const list = [
  {
    title: 'Early-Stage Investment',
    description:
      'VCs and angel investors have the opportunity to invest in CratD2C during its early stages, which can potentially yield high returns if the project succeeds.',
  },
  {
    title: 'Strategic Partnerships',
    description:
      'VCs and angel investors can form strategic partnerships with CratD2C, providing not only financial support but also valuable industry connections, advice, and mentorship.',
  },
  {
    title: 'Coin Acquisition',
    description:
      'Investors can acquire CRAT Coins during coin sales or on secondary markets. These coins may appreciate in value as the ecosystem grows.',
  },
  {
    title: 'Participation in Governance',
    description:
      "CratD2C operates on a decentralized governance model. Investors who hold a significant number of coins can participate in decision-making processes, helping shape the project's future.",
  },
  {
    title: 'Innovation Funding',
    description:
      'They can fund research and development efforts within the CratD2C ecosystem, encouraging innovation and the creation of new solutions.',
  },
  {
    title: 'Ecosystem Development',
    description:
      'VCs and angel investors can contribute to the development of the CratD2C ecosystem by supporting dApps, projects, or initiatives built on top of the CratD2C blockchain.',
  },
  {
    title: 'Global Expansion',
    description:
      'VCs and angel investors can assist CratD2C in expanding its reach into different markets and regions by providing the necessary capital and expertise.',
  },
  {
    title: 'IP Protection',
    description:
      'CratD2C has an intellectual property (IP) component. Investors can help protect and commercialize this IP, potentially generating additional revenue for the ecosystem.',
  },
  {
    title: 'Staking and Validation',
    description:
      'Some investors may choose to become Validators or Delegators within the CratD2C ecosystem, contributing to its security and earning rewards in the process.',
  },
  {
    title: 'Community Building',
    description:
      'They can assist in building and nurturing a strong and engaged community around CratD2C, which is vital for long-term success.',
  },
];
