import { Modules, Routes } from './types';

export const appRoutes: Routes = {
  home: {
    id: 0,
    dirName: 'home',
    root: {
      title: 'Home',
      path: '/',
    },
  },
  marketPortals: {
    id: 1,
    dirName: 'market-portals',
    root: {
      title: 'Market Portals',
      path: '/market-portals',
    },
  },
  intellectualProperty: {
    id: 2,
    dirName: 'intellectual-property',
    root: {
      title: 'Intellectual Property',
      path: '/intellectual-property',
      module: Modules.intellectualProperty,
    },
    children: {
      ipCertification: {
        id: 3,
        dirName: 'ip-certification',
        root: {
          title: 'Ip Certification',
          path: '/intellectual-property/ip-certification',
          module: Modules.intellectualProperty,
        },
      },
      ecosystemValuation: {
        id: 4,
        dirName: 'ecosystem-valuation',
        root: {
          title: 'Ecosystem Valuation',
          path: '/intellectual-property/ecosystem-valuation',
        },
      },
      ipPortionsIssuance: {
        id: 5,
        dirName: 'ip-portions-issuance',
        root: {
          title: 'IP-Portions Issuance',
          path: '/intellectual-property/ip-portions-issuance',
        },
      },
      ipPortionRoyalties: {
        id: 6,
        dirName: 'ip-portion-royalties',
        root: {
          title: 'IP-Portion Royalties',
          path: '/intellectual-property/ip-portion-royalties',
        },
      },
      ipPortionSeedRounds: {
        id: 7,
        dirName: 'ip-portion-seed-rounds',
        root: {
          title: 'IP-Portion Seed-Rounds',
          path: '/intellectual-property/ip-portion-seed-rounds',
        },
      },
    },
  },
  giveaway: {
    id: 8,
    dirName: 'giveaway',
    root: {
      title: 'Giveaway',
      path: '/giveaway',
    },
  },
  useCases: {
    id: 9,
    dirName: 'use-cases',
    root: {
      title: 'Use Cases',
      path: '/use-cases',
    },
  },
  termsAndConditions: {
    id: 10,
    dirName: 'terms-and-conditions',
    root: {
      title: 'Terms And Conditions',
      path: '/terms-and-conditions',
    },
  },
  tokenomics: {
    id: 11,
    dirName: 'tokenomics',
    root: {
      title: 'Tokenomics',
      path: '/tokenomics',
      module: Modules.tokenomics,
    },
    children: {
      distribution: {
        id: 12,
        dirName: 'distribution',
        root: {
          title: 'Distribution',
          path: '/tokenomics/distribution',
          module: Modules.tokenomics,
        },
      },
      biennialLayer: {
        id: 13,
        dirName: 'biennial-layer',
        root: {
          title: 'Biennial Layer',
          path: '/tokenomics/biennial-layer',
          module: Modules.tokenomics,
        },
      },
      earning: {
        id: 14,
        dirName: 'earn-with-crat',
        root: {
          title: 'Earning With Crat',
          path: '/tokenomics/earning-with-crat',
          module: Modules.tokenomics,
        },
      },
    },
  },
  smartchain: {
    id: 15,
    dirName: 'smart-chain',
    root: {
      title: 'Smart Chain',
      path: '/smartchain',
      module: Modules.smartchain,
    },
    children: {
      blockchainFeatures: {
        id: 16,
        dirName: 'blockchain-features',
        root: {
          title: 'Blockchain Features',
          path: '/smartchain/blockchain-features',
          module: Modules.smartchain,
        },
      },
      scope: {
        id: 17,
        dirName: 'smart-chain-scope',
        root: {
          title: 'Smart Chain Scope',
          path: '/smartchain/scope',
          module: Modules.smartchain,
        },
      },
      latency: {
        id: 18,
        dirName: 'latency',
        root: {
          title: 'Latency & TPS',
          path: '/smartchain/latency',
          module: Modules.smartchain,
        },
      },
      staking: {
        id: 19,
        dirName: 'staking',
        root: {
          title: 'Staking',
          path: '/smartchain/staking',
          module: Modules.smartchain,
        },
      },
      validator: {
        id: 20,
        dirName: 'validator',
        root: {
          title: 'Validator',
          path: '/smartchain/validator',
          module: Modules.smartchain,
        },
      },
      delegator: {
        id: 21,
        dirName: 'delegator',
        root: {
          title: 'Delegator',
          path: '/smartchain/delegator',
          module: Modules.smartchain,
        },
      },
      litebacker: {
        id: 22,
        dirName: 'litebacker',
        root: {
          title: 'LiteBacker',
          path: '/smartchain/litebacker',
          module: Modules.smartchain,
        },
      },
      turbobacker: {
        id: 23,
        dirName: 'turbobacker',
        root: {
          title: 'TurboBacker',
          path: '/smartchain/turbobacker',
          module: Modules.smartchain,
        },
      },
      ctvg: {
        id: 24,
        dirName: 'ctvg',
        root: {
          title: 'CTVG',
          path: '/smartchain/ctvg',
          module: Modules.smartchain,
        },
      },
      comparativeAnalysis: {
        id: 25,
        dirName: 'comparative-analysis',
        root: {
          title: 'Comparative Analysis',
          path: '/smartchain/comparative-analysis',
          module: Modules.smartchain,
        },
      },
      wallet: {
        id: 26,
        dirName: 'wallet',
        root: {
          title: 'Wallet',
          path: '/smartchain/wallet',
          module: Modules.smartchain,
        },
      },
      explorer: {
        id: 27,
        dirName: 'explorer',
        root: {
          title: 'Explorer',
          path: '/smartchain/explorer',
          module: Modules.smartchain,
        },
      },
    },
  },
  connect: {
    id: 28,
    dirName: 'connect',
    root: {
      title: 'Connect',
      path: '/connect',
    },
    children: {
      projectTeam: {
        id: 29,
        dirName: 'project-team',
        root: {
          title: 'Project Team',
          path: '/connect/project-team',
        },
      },
      ambassadorialSystem: {
        id: 30,
        dirName: 'ambassadorial-system',
        root: {
          title: 'Ambassadorial System',
          path: '/connect/ambassadorial-system',
        },
      },
      ecoCommunity: {
        id: 31,
        dirName: 'eco-community',
        root: {
          title: 'Eco Community',
          path: '/connect/eco-community',
        },
      },
      vcAngel: {
        id: 32,
        dirName: 'vc-angel',
        root: {
          title: 'Vc / Angel',
          path: '/connect/vc-angel',
        },
      },
      upcomingEvents: {
        id: 33,
        dirName: 'upcoming-events',
        root: {
          title: 'Upcoming Events',
          path: '/connect/upcoming-events',
        },
      },
      pressReleases: {
        id: 34,
        dirName: 'press-releases',
        root: {
          title: 'Press Releases',
          path: '/connect/press-releases',
        },
      },
      career: {
        id: 35,
        dirName: 'career',
        root: {
          title: 'Career',
          path: '/connect/career',
        },
      },
      bugBounty: {
        id: 36,
        dirName: 'bug-bounty',
        root: {
          title: 'Bug Bounty',
          path: '/connect/bug-bounty',
        },
      },
    },
  },
  privacyPolicy: {
    id: 37,
    dirName: 'privacy-policy',
    root: {
      title: 'Privacy Policy',
      path: '/privacy-policy',
    },
  },
  resources: {
    id: 38,
    dirName: 'resources',
    root: {
      title: 'Resources',
      path: '/resources',
    },
  },
};
