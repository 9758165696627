import { Backdrop, Fade } from '@mui/material';
import MuiModal from '@mui/material/Modal';

import { ModalCard, ModalWrapper } from './styled';
import { DisplayedTab, Header, ModalStateProvider, useModalState } from './ui';

const TRANSITION_DURATION = 700;

const MODAL_SLOTS = { backdrop: Backdrop };

const MODAL_SX = { display: 'flex', flexDirection: 'column' };

export const Modal = () => {
  const { isOpen, onClose } = useModalState();

  return (
    <MuiModal
      open={isOpen}
      closeAfterTransition
      slots={MODAL_SLOTS}
      sx={MODAL_SX}
      onClose={onClose}
      disableRestoreFocus
      slotProps={{
        backdrop: {
          in: isOpen,
          TransitionComponent: Fade,
          timeout: TRANSITION_DURATION,
        },
      }}
    >
      <Fade in={isOpen} timeout={TRANSITION_DURATION}>
        <ModalWrapper>
          <ModalCard>
            <Header />
            <DisplayedTab />
          </ModalCard>
        </ModalWrapper>
      </Fade>
    </MuiModal>
  );
};

export const FeedbackModal = () => {
  return (
    <ModalStateProvider>
      <Modal />
    </ModalStateProvider>
  );
};
