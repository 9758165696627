import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CancelCircleRed: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 15 15"
    sx={{
      width: '15px',
      height: '15px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="#F44949" />
    <path
      d="M4.75 10.25L10.25 4.75M4.75 4.75L10.25 10.25"
      stroke="#0F0A13"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
