import { TransactionExecutionError, UserRejectedRequestError } from 'viem';

import { getToastMessage } from './getToastMessage';

interface ToastErrorParams {
  error: unknown;
  defaultMessage?: string;
}

export const toastError = ({ error, defaultMessage }: ToastErrorParams) => {
  let message = defaultMessage || 'Unknown error';

  if (error instanceof UserRejectedRequestError || error instanceof TransactionExecutionError) {
    message = `Fail: ${error.shortMessage}`;
  }

  return getToastMessage('error', message);
};
