import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IconButton, InputAdornment, inputBaseClasses, Stack, TextField, Typography } from '@mui/material';
import { ModalType } from 'entities/modal/model';
import { COLOR_GRAY_3, COLOR_GRAY_4, getToastMessage, Icons, Modal } from 'shared';

export interface SendSuccessModalPayload {
  data: {
    transactionLink: string;
  };
  type: ModalType.SendSuccess;
  isOpen?: boolean;
}

export const SendSuccessModal = React.forwardRef<
  HTMLElement,
  Pick<SendSuccessModalPayload, 'data' | 'isOpen'> & { onClose: VoidFunction }
>(({ onClose, data }, ref) => {
  const { transactionLink } = data;

  const handleCopyLink = () => {
    getToastMessage('success', 'Link coppied');
  };

  return (
    <Modal ref={ref} showClose onClose={onClose}>
      <Stack alignItems="center">
        <Typography variant="h5" component="span" color="inherit" className="no-resize">
          STEP 2/2{' '}
          <Typography variant="inherit" component="span" className="accent">
            SEND
          </Typography>
        </Typography>
        <Icons.SuccessCheck
          sx={{
            mt: 4,
            mb: 3,
          }}
        />
        <Typography
          variant="h5"
          className="center accent"
          component="span"
          sx={{
            maxWidth: 332,
          }}
        >
          Sent
        </Typography>
        <Stack
          sx={{
            mt: 2,
            maxWidth: 250,
            width: '100%',
            color: COLOR_GRAY_4,
          }}
        >
          <Typography variant="body2" color="inherit" className="center">
            It takes some time for transaction to get confirmed.
          </Typography>
        </Stack>
        <TextField
          value={transactionLink}
          InputProps={{
            readOnly: true,
            inputProps: { className: 'secondary' },
            endAdornment: (
              <InputAdornment position="end">
                <CopyToClipboard onCopy={handleCopyLink} text={transactionLink}>
                  <IconButton className="transparent">
                    <Icons.Copy
                      sx={{
                        color: COLOR_GRAY_3,
                      }}
                    />
                  </IconButton>
                </CopyToClipboard>
              </InputAdornment>
            ),
          }}
          sx={{
            [`&>.${inputBaseClasses.root}`]: {
              mt: 3,
              height: 56,
              borderRadius: 0,
              border: 'none',
              borderBottom: '1px solid #C4C4C4',
              backgroundColor: '#252525',
              color: COLOR_GRAY_3,
              fontSize: 16,
              fontWeight: 400,
              pl: '22px',
              '&:hover': {
                backgroundColor: '#252525',
              },
            },
          }}
        />
      </Stack>
    </Modal>
  );
});
