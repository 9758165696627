import { Box, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import { crowdsaleModel } from 'entities/crowdsale';
import { COLOR_ACCENT, formatToken, fromDecimals, getBorder, useShallowSelector } from 'shared';

export const CrowdsaleProgress = () => {
  // const cratTotalAmount = useShallowSelector(crowdsaleModel.selectors.getProp('cratTotalCount'));
  // const raisedAmount = useShallowSelector(crowdsaleModel.selectors.getProp('cratAvailableAmount'));
  const { usdTotalRaised: usdTotalRaisedInDec, usdTotalCount } = useShallowSelector(
    crowdsaleModel.selectors.getCrowdsale,
  );
  // const boughtTokens = cratTotalAmount - Number(fromDecimals(raisedAmount));
  // const progress = (boughtTokens * 100) / cratTotalAmount;
  const usdTotalRaised = Number(fromDecimals(usdTotalRaisedInDec));
  const usdProgress = (usdTotalRaised * 100) / usdTotalCount;
  const usdAvailable = usdTotalCount - usdTotalRaised;

  // const roundedProgress =
  //   progress !== 0 && progress < 0.01 ? 0.01 : Math.round((progress + Number.EPSILON) * 100) / 100;

  const usdRoundedProgress =
    usdProgress !== 0 && usdProgress < 0.01 ? 0.01 : Math.round((usdProgress + Number.EPSILON) * 100) / 100;

  const pbData = [usdTotalRaised, usdAvailable].map((value, index) => ({
    id: index,
    percentageValue: value,
  }));

  return (
    <Stack mt={4} mb={{ xs: 4, md: 8 }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <LinearProgress
          variant="determinate"
          value={usdRoundedProgress}
          sx={{
            height: 42,
            border: getBorder('5px'),
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            left: 0,
            top: 0,
            height: '100%',
            position: 'absolute',
            width: '100%',
          }}
        >
          <Typography variant="h5" className="white">
            {usdRoundedProgress}%
          </Typography>
        </Stack>
        <Stack direction="row" width="100%">
          {pbData.map(({ id, percentageValue }, index) => (
            <Box
              key={id}
              sx={{
                flexGrow: percentageValue,
                position: 'relative',
              }}
            >
              {index === 0 && (
                <Tooltip
                  title={`$ ${formatToken(usdTotalRaised, 0)}`}
                  arrow
                  open
                  PopperProps={{
                    disablePortal: true,
                  }}
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    '&.MuiTooltip-tooltip': {
                      background: COLOR_ACCENT,
                      whiteSpace: 'nowrap',
                      wordWrap: 'unset',
                    },
                  }}
                >
                  <Box />
                </Tooltip>
              )}
            </Box>
          ))}
        </Stack>
      </Box>
      <Stack direction="row" width="100%" mt={0.5} justifyContent="end">
        <Typography
          variant="button"
          sx={{
            fontSize: { xs: 15, md: 20 },
          }}
        >
          ${formatToken(usdTotalCount, 0)}
        </Typography>
      </Stack>
    </Stack>
  );
};
