export const list = [
  {
    title: 'Participation and Staking',
    description:
      "Participants who wish to engage in staking start by acquiring CRAT Coins through various means, including purchases or incentives. Once they possess the required amount of coins, they have the option to stake them within the platform's staking pool.",
  },
  {
    title: 'Validator and Delegator Pools',
    description:
      'The staking pool is divided into Validator Pools and Delegator Pools. Validators are responsible for confirming transactions and maintaining network security, while Delegators contribute to the consensus process by delegating their stake to Validators.',
  },
  {
    title: 'Validator Staking',
    description:
      "To become a Validator, participants must stake a minimum amount of CRAT Coins, usually a substantial sum such as 100,000 coins. This stake demonstrates their commitment to the network's security. Validators are chosen based on their stake and reputation within the community.",
  },
  {
    title: 'Delegator Staking',
    description:
      "On the other hand, Delegators participate by selecting a Validator to delegate their stake to. By doing so, they indirectly contribute to the network's consensus. Delegators are not required to hold a significant amount of coins like Validators, making staking accessible to a wider range of participants.",
  },
  {
    title: 'LiteBackers and TurboBackers',
    description:
      'CratD2C presents a groundbreaking staking strategy with its LiteBackers and TurboBackers program, designed to secure and expand the network. Investors are rewarded with returns of up to 20% APR on their stakes, offering the flexibility to re-invest their earnings biweekly or weekly. Enhanced with additional bonuses, this approach plays a critical role in accelerating the decentralization of the network.',
  },
  {
    title: 'Reward Distribution',
    description:
      "Validators, Delegators, and Backers can earn rewards for their participation. The rewards are distributed in the form of CRAT Coins and are typically determined by factors such as the staked amount, network activity, and the consensus player's performance. Rewards incentivise participants to engage with the network and contribute to its security and stability.",
  },
  {
    title: 'Annual Percentage Rate (APR)',
    description:
      "The APR represents the annualized rate at which participants earn rewards based on their staked amount. The CratD2C DASC offers competitive APR rates to encourage active participation and engagement. The APR can vary based on the participant's role (LiteBacker, TurboBacker, Validator, or Delegator) and the staked amount.",
  },
  {
    title: 'Re-Staking Options',
    description:
      'Participants have the flexibility to re-stake their earned rewards periodically. This means that the rewards they earn can be added to their initial stake, compounding over time. Re-staking enhances the potential for higher rewards in subsequent periods.',
  },
  {
    title: 'Bonus CratD2C IP Portions',
    description:
      "For LiteBackers and TurboBackers, there's an additional opportunity for engagement through Bonus CratD2C IP portions. These portions hold value as they make the holders eligible for biannual royalty returns, offering an added incentive for active participation and long-term commitment to the CratD2C ecosystem.",
  },
  {
    title: 'EcoIP royalties',
    description:
      "This is a unique feature. When you hold CRAT Coins in the stake pool for a period of 6months, you get a share of the income generated by the platform's intellectual property. It's like owning a piece of a company and getting a share of its profits.",
  },
  {
    title: 'Enhanced Governance and Decentralization',
    description:
      "Participants also gain governance rights within the ecosystem by staking. They have the ability to participate in voting on proposals, decisions, and upgrades, contributing to the platform's decentralized governance structure.",
  },
  {
    title: 'Long-Term Incentives',
    description:
      "The staking mechanism encourages long-term engagement, commitment, and support for the CratD2C DASC. It aligns participants' interests with the network's success and growth, fostering a vibrant and active community.",
  },
];
