import { Box } from '@mui/material';
import { COLOR_PRIMARY } from 'shared';

export const intro = {
  subtitle: (
    <>
      IP-Portions Issuance in CratD2C Decentralized Autonomous Smart Chain:{' '}
      <Box component="span" color={COLOR_PRIMARY}>
        Innovating Blockchain Ownership
      </Box>
    </>
  ),
  paragraphSubtitle:
    'The CratD2C Decentralized Autonomous Smart Chain is pioneering a novel approach to intellectual property (IP) within the blockchain industry through the issuance of IP-Portions. This innovative concept is designed to democratize ownership and incentivize contributions within the CratD2C ecosystem, creating a more inclusive and equitable platform for developers, creators, and investors.',
  bottomParagraph:
    'As the platform grows and attracts diverse contributors, the IP-Portions system will undoubtedly evolve, potentially setting new standards for intellectual property in the digital age.',
};

export const list = [
  {
    title: 'What are IP-Portions?',
    description:
      "IP-Portions represent fractional ownership shares in the intellectual property developed on the CratD2C platform. This includes software, algorithms, digital assets, and other forms of IP created within the ecosystem. By issuing IP-Portions, CratD2C allows contributors to have a tangible stake in the success and utilization of their innovations, fostering a sense of ownership and investment in the platform's growth and development.",
  },
  {
    title: 'How IP-Portions Work',
    subitems: [
      {
        subTitle: 'Creation and Distribution',
        subDescription:
          'When a new piece of IP is developed on the CratD2C platform, a predetermined number of IP-Portions are created and distributed among the contributors according to their level of involvement and the value of their contributions. This process is transparent and governed by smart contracts to ensure fairness and integrity.',
      },
      {
        subTitle: 'Ownership and Rights',
        subDescription:
          "Holders of IP-Portions are granted certain rights, such as a share of the revenues generated by the IP, voting rights on decisions regarding the IP's usage, and the ability to trade or sell their portions on the open market. This creates a dynamic ecosystem where IP is protected and serves as a source of ongoing value for its creators.",
      },
      {
        subTitle: 'Tokenization',
        subDescription:
          'IP-Portions are tokenized, leveraging the security and transparency of blockchain technology. This tokenization facilitates the easy transfer, trade, and management of IP rights, making it accessible to a wider audience and opening up new opportunities for investment and collaboration.',
      },
    ],
  },
  {
    title: 'Benefits of IP-Portions',
    subitems: [
      {
        subTitle: 'Incentivization',
        subDescription:
          'CratD2C incentivizes innovation and active participation in the ecosystem by directly rewarding creators and contributors with ownership stakes.',
      },
      {
        subTitle: 'Transparency and Security',
        subDescription:
          'Using blockchain technology ensures that the issuance and management of IP-Portions are secure and transparent, reducing the risk of disputes and fostering trust among participants.',
      },
      {
        subTitle: 'Liquidity and Access',
        subDescription:
          'The tokenization of IP rights increases liquidity, allowing individuals and small entities to invest in and benefit from IP assets that would otherwise be out of reach.',
      },
    ],
  },
  {
    title: 'The Future of IP-Portions',
    description:
      "The CratD2C Decentralized Autonomous Smart Chain's approach to IP-Portions is set to revolutionize how intellectual property is managed and monetized in the blockchain space. By creating a more inclusive, transparent, and rewarding ecosystem, CratD2C is advancing its platform and contributing to the broader evolution of blockchain technology and IP rights management.",
  },
];
