export const steps = [
  {
    title: 'Systemic Supply Mechanism',
    description:
      'Is Designed to regulate the number of coins in circulation. By closely monitoring the relationship between supply and demand, this system strives to prevent situations where excessive supply could lead to inflation (a drop in coin value) or scarcity could lead to deflation (a sharp increase in coin value). The systemic supply mechanism is algorithmically controlled and adjusted based on predefined rules and conditions.',
  },
  {
    title: (
      <>
        8-Layer ZigZag Supply
        <br />
        Chain
      </>
    ),
    description:
      'A multi-level approach to managing coin supply. Each "layer" represent a different condition or set of rules for when and how to increase or decrease coin supply. For example, the "zigzag" mechanism means that the Smart-Chain doesn\'t just linearly increase or decrease supply but fluctuates in its ecosystem, to better respond to volatile market conditions.',
  },
  {
    title: 'Biennial Zigzag Proportion',
    description:
      "The supply of the native coin in the CratD2C's Decentralized Autonomous Smartchain is adjusted in a variable or fluctuating manner, potentially in response to market conditions, to maintain a balance between supply and demand, and to ensure the stability of the coin’s value, A unique approach to coin supply management in the blockchain space, reflecting the flexibility and adaptability of any dApp to navigate the inherent volatility of cryptocurrency markets.",
  },
  {
    title: 'Elastic Culture',
    description:
      'The adaptive nature of the CratD2C tokenomics model is an elastic band stretch or contract based on the force applied to it. The coin supply in this model increases or decreases in response to market demand. This adaptability is crucial in the rapidly changing and unpredictable world of cryptocurrencies.',
  },
];
