export const BOTTOM_TEXT =
  "It's important to note that career opportunities within the CratD2C ecosystem may evolve over time as the project grows and new use cases emerge. If you're interested in pursuing a career with CratD2C, it's advisable to stay updated on their official website and announcements for job openings and opportunities to get involved in the community.";

export const intro = {
  paragraph:
    'A career with CratD2C can be an exciting and rewarding opportunity for individuals who are passionate about blockchain technology, decentralization, and the transformative potential of the CratD2C Decentralized Autonomous Smart Chain (D.A.S.C). Here are some potential career paths and roles within the CratD2C ecosystem:',
};

export const list = [
  {
    title: 'Blockchain Developer',
    description:
      "Blockchain developers are responsible for building and maintaining the core infrastructure of the CratD2C blockchain. They work on protocol upgrades, smart contract development, and optimizing the network's performance. Proficiency in blockchain programming languages like Solidity and Rust is essential.",
  },
  {
    title: 'Validator',
    description:
      'Validators are essential for maintaining the security and consensus of the CratD2C blockchain. They participate in block production and validation, ensuring the integrity of the network. Validators are typically required to stake a minimum number of CRAT Coins.',
  },
  {
    title: 'Delegator',
    description:
      "Delegators contribute to the network's consensus mechanism by staking their CRAT Coins with Validators. They earn rewards based on the amount they stake and the performance of the Validator they delegate to.",
  },
  {
    title: 'Backer',
    description:
      'Backers are individuals who stake CRAT Coins for enhanced APR and potential royalties. There are different tiers of Backers, such as LiteBackers and TurboBackers, each with varying staking requirements and rewards.',
  },
  {
    title: 'Ambassador',
    description:
      "Ambassadors play a crucial role in promoting the CratD2C ecosystem. They help attract new users and investors, organize events, and educate the community about the platform's features and benefits. Ambassadors may receive rewards based on their performance and contributions.",
  },
  {
    title: 'Marketing and Community Management',
    description:
      'Marketing professionals and community managers are responsible for creating and executing marketing campaigns, managing social media channels, and engaging with the CratD2C community. They help build brand awareness and drive adoption.',
  },
  {
    title: 'Business Development',
    description:
      'Business development representatives identify and establish partnerships with other blockchain projects, businesses, and organizations. They explore collaboration opportunities that can expand the use cases of CratD2C.',
  },
  {
    title: 'Content Creators',
    description:
      'Content creators produce educational materials, blog posts, videos, tutorials, and other content to help users and investors understand the CratD2C ecosystem better. They play a vital role in spreading knowledge about the platform.',
  },
  {
    title: 'Advisors',
    description:
      'Advisors provide strategic guidance and expertise in various domains, including blockchain technology, finance, and legal matters. They help steer the project in the right direction.',
  },
  {
    title: 'Technical Support',
    description:
      'Technical support professionals assist users with any technical issues they encounter while using the CratD2C platform. They ensure a smooth user experience.',
  },
  {
    title: 'Research and Development',
    description:
      'Research and development teams focus on exploring new technologies and innovations that can enhance the CratD2C ecosystem. They may work on new features, security enhancements, and scalability solutions.',
  },
  {
    title: 'Legal and Compliance',
    description:
      "Legal and compliance experts ensure that CratD2C operates within the boundaries of relevant regulations and laws. They help navigate legal challenges and ensure the project's long-term sustainability.",
  },
];
