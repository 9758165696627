import { Icons, links, mail } from 'shared';

export const support = {
  url: mail.support,
  Icon: Icons.Mail,
  content: 'support@cratd2csmartchain.io',
};

export const telegram = [
  {
    Icon: Icons.Telegram,
    url: links.tgRoom,
    title: (
      <>
        Telegram Chat Room <Icons.Fire sx={{ width: 18, height: 18 }} />
      </>
    ),
    content: 't.me/CratD2CSmartChain',
  },
  {
    Icon: Icons.Telegram,
    url: links.tgChannel,
    title: 'Telegram Channel',
    content: 't.me/cratd2cOfficial',
  },
];

export const socials = [
  {
    Icon: Icons.Twitter,
    url: links.twitter,
    title: 'Twitter',
    content: 'twitter.com/CratD2C',
  },
  {
    Icon: Icons.Facebook,
    url: links.facebook,
    title: 'Facebook',
    content: 'Facebook.com/cratd2c',
  },
  {
    Icon: Icons.Linkedin,
    url: links.linkedin,
    title: 'LinkedIn',
    content: 'cratd2csmartchain',
  },
  {
    Icon: Icons.Medium,
    url: links.medium,
    title: 'Medium',
    content: 'Medium.com/@cratd2c',
  },

  {
    Icon: Icons.Tiktok,
    url: links.tiktok,
    title: 'TikTok',
    content: '@cratd2csmartchain',
  },

  {
    Icon: Icons.Discord,
    url: links.discord,
    title: 'Discord',
    content: 'discord.gg/eyjrSFXUbW',
  },

  {
    Icon: Icons.Youtube,
    url: links.youtube,
    title: 'YouTube channel',
    content: 'youtu.be/SJxt-9-dZvk',
  },
];

export const mailContacts = [
  {
    Icon: Icons.Mail,
    url: mail.marketing,
    title: 'Marketing, Ambassadorial Role, and Business Development:',
    content: 'marketing@cratd2csmartchain.io',
  },
  {
    Icon: Icons.Mail,
    url: mail.vc,
    title: 'VC, Angel Investor, Institutional Partnership:',
    content: 'partnerships@cratd2csmartchain.io',
  },
  {
    Icon: Icons.Mail,
    url: mail.info,
    title: 'Media and Press Inquiries:',
    content: 'info@cratd2csmartchain.io',
  },
  {
    Icon: Icons.Mail,
    url: mail.founder,
    title: `Founder's Direct Contact:`,
    content: 'founder@cratd2csmartchain.io',
  },
];
