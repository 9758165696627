import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Close: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    viewBox="0 0 14 14"
    sx={{
      width: '14px',
      height: '14px',
      fill: 'none',
      ...sx,
    }}
    {...props}
  >
    <path d="M1 13L13 1M1 1L13 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
