export const links = {
  tgRoom: 'https://t.me/CratD2CSmartChain',
  tgChannel: 'https://t.me/cratd2cOfficial',
  discord: 'https://discord.gg/eyjrSFXUbW',
  linkedin: 'https://www.linkedin.com/company/cratd2csmartchain/',
  twitter: 'https://twitter.com/CratD2C',
  youtube: 'https://www.youtube.com/@CratD2CSmartChain',
  tiktok: 'https://www.tiktok.com/@cratd2csmartchain',
  medium: 'https://medium.com/@cratd2c',
  facebook: 'https://www.facebook.com/cratd2c',
};

export const mail = {
  marketing: 'mailto:marketing@cratd2csmartchain.io',
  vc: 'mailto:partnerships@cratd2csmartchain.io',
  info: 'mailto:info@cratd2csmartchain.io',
  support: 'mailto:support@cratd2csmartchain.io',
  founder: 'mailto:founder@cratd2csmartchain.io',
};
