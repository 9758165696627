import { CSSProperties, PropsWithChildren } from 'react';
import { Grid, SxProps } from '@mui/material';

import { TableHead, TableHeadProps } from './table-head';

interface TableProps<T> extends PropsWithChildren {
  headProps: TableHeadProps<T>;
  sx?: SxProps;
}

export const Table = <T extends string | number>({ headProps, sx, children }: TableProps<T>) => {
  return (
    <Grid
      container
      component="table"
      aria-label="Table"
      sx={(theme) => ({ width: '100%', [theme.breakpoints.down(750)]: { width: '190%' }, ...(sx as CSSProperties) })}
    >
      <TableHead {...headProps} />
      <Grid component="tbody" item container xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
