export const BOTTOM_TEXT =
  "The CratD2C Wallet is more than just a digital wallet; it's a gateway to the full spectrum of services and opportunities within the CratD2C Decentralized Autonomous Smart Chain ecosystem. Its user-centric design, robust security features, and comprehensive functionality make it a valuable tool for both seasoned cryptocurrency enthusiasts and newcomers to the blockchain space.";

export const intro = {
  paragraph:
    "The CratD2C Wallet is a fundamental component of the CratD2C ecosystem, designed to provide users with a secure, user-friendly, and versatile platform for managing their CRAT Coins and engaging with the broader blockchain world with solidity functionality to work with other web-3 wallets. Here's a comprehensive description of how the CratD2C Wallet will function and the functionalities it will offer through its interface:",
};

export const list = [
  {
    title: 'Secure Storage',
    description: `The CratD2C Wallet will provide users with a secure digital vault for storing their CRAT Coins. These coins are protected by advanced cryptographic techniques, ensuring the safety of the user's assets.`,
  },
  {
    title: 'User-Friendly Interface',
    description:
      "The wallet's interface is designed to be intuitive and user-friendly, making it accessible to both experienced cryptocurrency enthusiasts and newcomers to the blockchain space.\n\nUsers can choose between a web-based wallet, a mobile app, or even a hardware wallet, catering to their preferences for accessibility.",
  },
  {
    title: 'Multi-Currency Support',
    description:
      'In addition to CRAT Coins, the wallet supports various other cryptocurrencies, enabling users to manage multiple assets within a single interface.',
  },
  {
    title: 'Transaction Management',
    description:
      "Users can send, receive, and view the transaction history of their CRAT Coins and other supported cryptocurrencies.\n\nThey can initiate transactions by specifying the recipient's wallet address and the amount they want to send.",
  },
  {
    title: 'Staking and Delegating',
    description:
      'The wallet seamlessly integrates with the CratD2C staking mechanism, allowing users to easily stake their CRAT Coins or delegate them to Validators or Delegators.\n\nUsers can monitor their staking rewards and manage their staked assets directly from the wallet interface.',
  },
  {
    title: 'CratD2C Ecosystem Access',
    description:
      'Through the wallet, users have direct access to the various portals and services within the CratD2C ecosystem, including the eCommerce Trading Portal, Real Estate Trading Portal, Luxury Lifestyle Booking Portal, and Universal Payment Gateway.',
  },
  {
    title: 'IP-Portion Royalty Tracking',
    description:
      'For users holding CRAT Coins, the wallet provides a dedicated section to track and manage their IP-Portion Royalty Payouts, reflecting their ownership of intellectual property portions.',
  },
  {
    title: 'Security Features',
    description:
      'To protect user funds and data, the wallet implements robust security measures, including encryption, two-factor authentication (2FA), and recovery phrases.\n\nUsers have full control over their private keys, ensuring the highest level of security.',
  },
  {
    title: 'Real-Time Updates',
    description:
      'The wallet updates CRAT Coin prices, market trends, and news, informing users about their investments.',
  },
  {
    title: 'Cross-Platform Compatibility',
    description:
      'Whether using the web-based version, mobile app, or hardware wallet, users will experience a consistent and synchronized interface across platforms.',
  },
  {
    title: 'Educational Resources',
    description:
      'The wallet offers educational resources and guides to help users understand blockchain technology, cryptocurrency management, and how to make the most of the CratD2C ecosystem.',
  },
  {
    title: 'Multilingual Support',
    description:
      'The wallet is available in multiple languages to cater to a global user base, enhancing accessibility.',
  },
];
