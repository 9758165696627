/* eslint-disable @typescript-eslint/no-unused-vars */

import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_PRIMARY, COLOR_SECONDARY } from '../colors';
import { BORDER_MAIN } from '../common';
import { FontWeights } from '../Typography';

import { CustomExpandIcon } from './AccordionExpandIcon';

export const getMuiAccordion = (theme: Theme): ComponentsOverrides['MuiAccordion'] => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    width: '100%',
    '&::before': {
      display: 'none',
    },
    borderBottom: BORDER_MAIN,
    padding: theme.spacing(2.5, 0),
  },
});

export const getMuiAccordionDetails = (theme: Theme): ComponentsOverrides['MuiAccordionDetails'] => ({
  root: {
    padding: theme.spacing(1.25, 0, 0),
    color: COLOR_SECONDARY,
    fontSize: 14,
    lineHeight: '155%',
    letterSpacing: '-0.4px',
  },
});
export const getMuiAccordionSummary = (theme: Theme): ComponentsOverrides['MuiAccordionSummary'] => ({
  root: {
    padding: 0,
  },
  content: {
    color: COLOR_SECONDARY,
    fontSize: 20,
    lineHeight: '150%',
    letterSpacing: '-0.4px',
    fontWeight: FontWeights.Medium,
    margin: 0,
    marginRight: theme.spacing(1.5),
    '&.Mui-expanded': {
      color: COLOR_PRIMARY,
    },
  },
});

export const getMuiAccordionPropsDefaultProps = (): ComponentsProps['MuiAccordion'] => ({
  disableGutters: true,
  elevation: 0,
  square: true,
});

export const getMuiAccordionSummaryPropsDefaultProps = (): ComponentsProps['MuiAccordionSummary'] => ({
  expandIcon: <CustomExpandIcon />,
});
