import { createConfig } from 'wagmi';

import { publicClient, webSocketPublicClient } from './chains';
import { connectors } from './connectors';

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});
