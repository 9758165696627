import { PropsWithChildren } from 'react';
import { alpha, Box, BoxProps, Typography } from '@mui/material';
import { BORDER_RADIUS_S, COLOR_WH } from 'shared';

export const Note: React.FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        borderRadius: BORDER_RADIUS_S,
        backgroundColor: alpha(COLOR_WH, 0.06),
        padding: '16px 16px 24px',
        ...props.sx,
      }}
    >
      <Typography variant="body1" fontWeight={600} pb={1.5}>
        Note
      </Typography>
      <Typography variant="body2" className="secondary font-secondary">
        {children}
      </Typography>
    </Box>
  );
};
