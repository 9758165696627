import { Card, Divider, Stack, Typography } from '@mui/material';
import { crowdsaleModel } from 'entities/crowdsale';
import { fromDecimals, useBreakpoints, useShallowSelector } from 'shared';

export const Price = () => {
  const { isDownSmLayout } = useBreakpoints();
  const price = useShallowSelector(crowdsaleModel.selectors.getProp('cratPrice'));
  const formattedPrice = (+fromDecimals(price)).toFixed(2);

  return (
    <Card
      sx={{
        py: '20px',
        mt: 5,
      }}
    >
      <Stack direction={{ sm: 'row' }} gap={{ xs: 1.5, md: 0 }}>
        <Stack alignItems="center" justifyContent="center" flexGrow={1}>
          <Typography variant="h4">${formattedPrice}</Typography>
          <Typography variant="h6" className="secondary center">
            Current Price
          </Typography>
        </Stack>
        <Divider
          orientation={isDownSmLayout ? 'horizontal' : 'vertical'}
          sx={{
            height: 'auto',
          }}
        />
        <Stack alignItems="center" justifyContent="center" flexGrow={1}>
          <Typography variant="h4">$0.30</Typography>
          <Typography variant="h6" className="secondary center">
            Final Round Price
          </Typography>
        </Stack>
        <Divider
          orientation={isDownSmLayout ? 'horizontal' : 'vertical'}
          sx={{
            height: 'auto',
          }}
        />
        <Stack alignItems="center" justifyContent="center" flexGrow={1}>
          <Typography variant="h4">$0.80</Typography>
          <Typography variant="h6" className="secondary center">
            Listing Price
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
