import { useEffect } from 'react';
import { Button, Divider, Grid, Stack, styled, Typography } from '@mui/material';
import { giveawayModel } from 'entities/giveaway';
import { userModel } from 'entities/user';
import {
  BORDER_MAIN,
  BORDER_RADIUS_L,
  BORDER_RADIUS_S,
  COLOR_BG_BLACK_4,
  formatForDisplay,
  useAppDispatch,
  useBreakpoints,
  useShallowSelector,
} from 'shared';

import { POINTS_CARDS_DATA } from './constants';

const BorderWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: BORDER_RADIUS_L,
  border: BORDER_MAIN,
  padding: theme.spacing(2, 4),
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-around',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const BgWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: BORDER_RADIUS_S,
  background: COLOR_BG_BLACK_4,
  padding: theme.spacing(1.5),
  width: '100%',
  alignItems: 'center',
  pap: theme.spacing(0.75),
}));

export const Purchase = () => {
  const { isDownSmLayout } = useBreakpoints();
  const dispatch = useAppDispatch();
  const { address: userAddress } = useShallowSelector(userModel.selectors.selectUserWeb3Info);
  const { purchaseAmount, tokensBought, purchasePointsEarned } = useShallowSelector(
    giveawayModel.selectors.getGiveaway,
  );

  useEffect(() => {
    if (userAddress) dispatch(giveawayModel.thunks.getPurchasesInfo());
  }, [dispatch, userAddress]);

  return (
    <Grid container>
      <Grid item xs={12} md={2} mb={{ xs: 3, md: 0 }}>
        <Typography variant="h4" className="secondary">
          01. Purchase
        </Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid item container xs={12} rowSpacing={{ xs: 1.25, md: 2 }} columnSpacing={1.25}>
          {POINTS_CARDS_DATA.map(({ id, cost, points }) => (
            <Grid item xs={6} sm={4} md={3} key={id}>
              <BgWrapper>
                <Typography variant="h4">${cost.toLocaleString('en-US')}</Typography>
                <Typography className="secondary">
                  <Typography component="span" className="accent">
                    {points.toLocaleString('en-US')}
                  </Typography>{' '}
                  Points
                </Typography>
              </BgWrapper>
            </Grid>
          ))}
        </Grid>
        {!!userAddress && (
          <BorderWrapper mt={{ xs: 4, sm: 2 }} gap={{ xs: 2, sm: 0 }}>
            <Stack alignItems="center">
              <Typography className="secondary">CratD2C-Pre tokens bought:</Typography>
              <Typography variant="h5" className="accent no-resize">
                {/* eslint-disable-next-line no-new-wrappers */}
                {new Number(formatForDisplay(tokensBought, 2)).toLocaleString()}
              </Typography>
            </Stack>
            <Divider orientation={isDownSmLayout ? 'horizontal' : 'vertical'} flexItem />
            <Stack alignItems="center">
              <Typography className="secondary">Invested:</Typography>
              <Typography variant="h5" className="accent no-resize">
                ${purchaseAmount}
              </Typography>
            </Stack>
            <Divider orientation={isDownSmLayout ? 'horizontal' : 'vertical'} flexItem />
            <Stack alignItems="center">
              <Typography className="secondary">Points earned:</Typography>
              <Typography variant="h5" className="accent no-resize">
                +{purchasePointsEarned}{' '}
                <Typography variant="h5" component="span" className="secondary no-resize">
                  Points
                </Typography>
              </Typography>
            </Stack>
          </BorderWrapper>
        )}

        <Button size="small" LinkComponent="a" href="/#crowdsale" sx={{ mt: 4, width: { xs: 'unstet', sm: '509px' } }}>
          Complete ico personal purchase to activate leaderboard
        </Button>
      </Grid>
    </Grid>
  );
};
