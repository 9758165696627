/* eslint-disable consistent-return */
import { FC, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Fade, Modal as MuiModal, Slide, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SlideProps } from '@mui/material/Slide';
import { modalModel } from 'entities/modal';

import { IModal, IModalPayload, modalSelector, ModalTransition, ModalType } from '../../model';
import {
  BiennialSupplyMechanismModal,
  CratNewModal,
  CratWidgetModal,
  CtvgModal,
  DecentralizedCommunityModal,
  DelegatorModal,
  EcommerceTradingModal,
  EstateTradingModal,
  IntellectualPropertyModal,
  LiteBackerModal,
  LuxuryModal,
  PaymentApiModal,
  PaymentGatewayModal,
  PosModal,
  StakingModal,
  TrustLedgerModal,
  TurboBackerModal,
  ValidatorModal,
  VendingMachineModal,
} from '../drawers';
import {
  ApproveModal,
  ApproveRejectModal,
  SendModal,
  SendRejectModal,
  SendSuccessModal,
  SendTimeoutModal,
} from '../windows';

const { closeModal } = modalModel.actions;

const modalMapping: { [key in keyof typeof ModalType]: FunctionComponent<IModal> } = {
  [ModalType.POS]: PosModal,
  [ModalType.IntellectualProperty]: IntellectualPropertyModal,
  [ModalType.DecentralizedCommunity]: DecentralizedCommunityModal,
  [ModalType.CTVG]: CtvgModal,
  [ModalType.EcommerceTrading]: EcommerceTradingModal,
  [ModalType.EstateTrading]: EstateTradingModal,
  [ModalType.Luxury]: LuxuryModal,
  [ModalType.PaymentGateway]: PaymentGatewayModal,
  [ModalType.VendingMachine]: VendingMachineModal,
  [ModalType.PaymentApi]: PaymentApiModal,
  [ModalType.CratWidget]: CratWidgetModal,
  [ModalType.CratNew]: CratNewModal,
  [ModalType.TrustLedger]: TrustLedgerModal,
  [ModalType.BiennialSupplyMechanism]: BiennialSupplyMechanismModal,
  [ModalType.Staking]: StakingModal,
  [ModalType.Delegator]: DelegatorModal,
  [ModalType.Validator]: ValidatorModal,
  [ModalType.LiteBacker]: LiteBackerModal,
  [ModalType.TurboBacker]: TurboBackerModal,
  [ModalType.Approve]: ApproveModal,
  [ModalType.ApproveReject]: ApproveRejectModal,
  [ModalType.Send]: SendModal,
  [ModalType.SendReject]: SendRejectModal,
  [ModalType.SendSuccess]: SendSuccessModal,
  [ModalType.SendTimeout]: SendTimeoutModal,
};

const slideProps: Partial<SlideProps> = { direction: 'left' };

export const Modal: FC<{ type?: ModalType; data?: IModalPayload['data']; transition?: ModalTransition | null }> = ({
  type,
  data,
  transition = 'slide',
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [isOpen, setIsOpen] = useState(Boolean(type));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const transitionTime = 700;

  const onClose = () => {
    setIsOpen(false);
  };

  // lock page scroll when Modal is open on iOS Safari
  useEffect(() => {
    const wrapper = document.getElementById('root') || document.body;
    if (isSmallScreen) {
      if (isOpen) {
        setScrollTop(window.scrollY);
        wrapper.style.position = 'fixed';
        wrapper.scroll(0, scrollTop);
      } else {
        wrapper.style.position = '';
        window.scrollTo(0, scrollTop);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => e.key === 'Escape' && onClose();
    if (type) {
      window.addEventListener('keyup', handleEscape);
    }
    return () => window.removeEventListener('keyup', handleEscape);
  }, [dispatch, type]);

  useEffect(() => {
    if (isOpen) return;

    const timeout = setTimeout(() => {
      dispatch(closeModal());
    }, transitionTime);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, transitionTime, dispatch]);

  useEffect(() => {
    setIsOpen(Boolean(type));
  }, [type]);

  const ModalComponent = type ? modalMapping[type] : null;

  const TransitionComponent = transition === 'fade' ? Fade : Slide;
  const transitionProps = transition === 'fade' ? {} : slideProps;

  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          in: isOpen,
          TransitionComponent: Fade,
          timeout: transitionTime,
        },
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      disableRestoreFocus
    >
      <TransitionComponent in={isOpen} timeout={transitionTime} {...transitionProps}>
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
        >
          {ModalComponent ? <ModalComponent isOpen={isOpen} onClose={onClose} data={data} /> : <div />}
        </Stack>
      </TransitionComponent>
    </MuiModal>
  );
};

export const ModalContainer = () => {
  const { modal, transition } = useSelector(modalSelector);

  return <Modal type={modal?.type} transition={transition} data={modal?.data} />;
};
