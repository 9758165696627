import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useWagmiConnectContext } from 'app/providers';
import { COLOR_GRAY_LIGHT_4, Icons, logger } from 'shared';

interface LogoutMenuProps {
  handleClose: VoidFunction;
}

export const LogoutMenu = ({ handleClose }: LogoutMenuProps) => {
  const { disconnect } = useWagmiConnectContext();

  const disconnectWallet = () => {
    try {
      disconnect();
    } catch (error) {
      logger('disconnect', error);
    }
    handleClose();
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          height: 40,
          width: 40,
          borderRadius: '50%',
          border: '2px solid',
          borderColor: '#4A4C89',
          background: 'transparent',
          '&:hover': {
            background: 'transparent',
          },
        }}
      >
        <Icons.Close />
      </IconButton>

      <Stack
        sx={{
          width: 110,
          height: 110,
          borderRadius: '50%',
          backgroundColor: COLOR_GRAY_LIGHT_4,
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Icons.Logout />
      </Stack>
      <Typography pt={4.75} pb={2.5} variant="h5" className="black center" fontWeight={600}>
        Log out
      </Typography>
      <Typography variant="body2" px={1.75} pb={4} className="black center" fontWeight={500}>
        Do you really want to disconnect your wallet and log out ?
      </Typography>
      <Stack
        gap={2}
        sx={{
          width: '100%',
        }}
      >
        <Button onClick={disconnectWallet}>Exit</Button>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: COLOR_GRAY_LIGHT_4,
            '&:hover': {
              backgroundColor: COLOR_GRAY_LIGHT_4,
            },
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
